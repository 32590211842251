import {lazy} from "react"

export const Routes = [
    {
        path: "/scraping-jobs/lists",
        component: lazy(() => import("../views/scraping-jobs-list")),
        meta: {
            action: "call",
            resource: "view.main.scraping.tab.list"
        }
    },
    {
        path: "/auto-scraping-jobs/lists",
        component: lazy(() => import("../views/custom-auto-scrape")),
        meta: {
            action: "call",
            resource: "view.main.scraping.tab.list"
        }
    },
    {
        path: "/playlist/scraping/:scrapingId",
        component: lazy(() => import("../views/scraped-playlist-list")),
        meta: {
            action: "call",
            resource: "scraping.show"
        }
    },
    {
        path: "/auto-scraping-jobs/view/:autoScrapeId",
        component: lazy(() => import("../views/custom-auto-scrape-view")),
        meta: {
            action: "call",
            resource: "scraping.show"
        }
    },
    {
        path: "/fan/finder/search",
        component: lazy(() => import("../views/fanfinder/fan-search")),
        meta: {
            action: "call",
            resource: "view.main.fan.finder.tab.list"
        }
    },
    {
        path: "/fan/finder/details/:searchArtist",
        component: lazy(() => import("../views/fanfinder/fan-details")),
        meta: {
            action: "call",
            resource: "fan.finder.show"
        }
    },
    // {
    //     path: "/crm/list",
    //     component: lazy(() => import("../views/crm-list")),
    //     meta: {
    //         action: "call",
    //         resource: "SCRAPING_VIEW_CRM_LIST"
    //     }
    // },
    // {
    //     path: "/artist/profile/:artistId",
    //     component: lazy(() => import("../views/artist-profile")),
    //     meta: {
    //         action: "call",
    //         resource: "SCRAPING_VIEW_ARTIST_PROFILE"
    //     }
    // },
    {
        path: "/logs",
        component: lazy(() => import("../views/log")),
        meta: {
            action: "call",
            resource: "view.main.logs.tab.list"
        }
    }
]
