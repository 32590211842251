// **  Initial State
const initialState = {
  loading: false
}

export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
