import React, { Fragment, useEffect, useState } from 'react'
import classnames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { ArrowLeft } from 'react-feather'
import { Label, FormGroup, Row, Col, Button, Form, Input, Container } from 'reactstrap'
import _ from "lodash"
import Flatpickr from "react-flatpickr"
import AsyncSelect from "react-select/async"
import moment from "moment/moment"
import { useSelector } from "react-redux"
import SelectCardAppComponent from '../../../../../components/SelectCardAppComponent'
import { getUserData } from '@authModule'
import {Link, useHistory} from "react-router-dom"
import { ButtonSpinner, ErrorMessages } from '@src/components'
import { trans } from '@utils'

import {
    _addCampaign,
    _editCampaign,
    _getAllTiers
} from "../../../redux/actions"
import { CoinIcon, InputMainLabelComponent } from '../../../../../components/CurrencyComponent'

const CampaignDetails = ({ stepper, formState, successCallback, _close }) => {
    const { register, errors, handleSubmit, trigger, control, setValue, setError } = useForm()
    const { formData, setFormData, campaignType } = formState
    const loading = useSelector(state => state.app.loading)
    const [tiers, setTiers] = useState([])
    const [selectedTierId, setSelectedTierId] = useState(null)
    const [pitch, setPitch] = useState(formData.pitch)
    const history = useHistory()
    const [valErrors, setValErrors] = useState({})
    const formatAmount = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    const onSubmit = (data) => {
        trigger()
        if (pitch.length > 140) {
            setValErrors({ pitch: 'Pitch too long' })
            return
        }
        if (_.isEmpty(errors)) {
            setFormData(prevState => {
                if (data.start_date === _.get(formData, 'start_date')) {
                    data.start_date = _.reverse(data.start_date.split('/')).join('-')
                } else if (moment(data.start_date[0]).format('Y-MM-DD') !== 'Invalid date') {
                    data.start_date = moment(data.start_date[0]).format('Y-MM-DD')
                } else {
                    data.start_date = ''
                }

                if (process.env.REACT_APP_AUTH_MODULE === 'artist') {
                    data.user_id = getUserData().id
                }
                if (formData.id) {
                    _editCampaign(
                        {
                            ...prevState,
                            ...data,
                            start_date: data.start_date,
                            tier_id: data.tier_id,
                            campaign_type_id: campaignType
                        },
                        () => {
                            
                            if (formData?.typeAuth === 'artist') {
                                history.push('/custom/campaigns/list')
                            } else {
                                successCallback()
                            _close()
                            }
                            
                        },
                        (err) => {
                        }
                    )
                } else {
                    console.log('PREVSTATE', prevState)
                    console.log('DATA', data)
                    _addCampaign(
                        {
                            ...prevState,
                            ...data,
                            start_date: data.start_date,
                            tier_id: data.tier_id,
                            campaign_type_id: campaignType,
                            tiktok_img: formData.tiktok_img
                        },
                        () => {
                            
                            if (formData?.typeAuth === 'artist') {
                                history.push('/custom/campaigns/list')
                            } else {
                                successCallback()
                            _close()
                            }
                        },
                        (err) => {
                        }
                    )
                }
                return {
                    ...prevState,
                    ...data,
                    start_date: data.start_date,
                    tier_id: data.tier_id.value,
                    campaign_type_id: campaignType

                }
            })
        }
    }
    const handleButtonClick = (tierId) => {
        setSelectedTierId(tierId)
      }
    const minDate = new Date()
    // add a day
    minDate.setDate(minDate.getDate())
    const today = new Date()
today.setHours(0, 0, 0, 0)

    useEffect(() => {
        register('tier_id', { required: "Tier is required" })
        if (formData.tier_id > 0) {
            setValue('tier_id', formData.tier_id)
        }
    }, [])

    const [firstInit, setFirstInit] = useState(true)
    useEffect(() => {
        if (!firstInit) {
            setValue('tier_id', null)
        } else {
            setFirstInit(false)
        }
        _getAllTiers('', campaignType, (data) => {
            setTiers(data)
        })
    }, [campaignType])
    // console.log(formData)
    return (
        <Fragment>
            <div className='content-header'>
                <h5 className='mb-0 inter-bold-font'>Campaign Details {formData.typeAuth}</h5>
                {/*<small>Enter Your Social Links.</small>*/}
            </div>
            <Form enctype="multipart/form-data" method="post" onSubmit={handleSubmit(onSubmit)}>
                {/* <Container> */}
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            {/* <Label className='form-label' for={`start_date`}>
                                Select campaign start date <small className={'text-danger'}>*</small>
                            </Label> */}
                            <InputMainLabelComponent title='Select campaign start date' isRequired={true} forId='start_date'  />
                            <Controller
                                id={'start_date'}
                                as={Flatpickr}
                                control={control}
                                name={'start_date'}
                                // style={{ backgroundColor: 'transparent' }}
                                className={classnames('form-control', { 'is-invalid': errors[`start_date`] })}
                                defaultValue={formData?.start_date ?? ''}
                                options={{
                                    dateFormat: 'Y-m-d',
                                    minDate: today
                                    // disable: [
                                    //     function (date) {
                                    //         return (date.getDay() === 0 || date.getDay() === 6)
                                    //     }
                                    // ]
                                }}
                                rules={{ required: true }}
                            />
                            <ErrorMessages errors={errors} name={'start_date'} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
  <Col xs={12}>
    <FormGroup>
      {/* <Label className='form-label' for={`tier_id`}>
        Select your campaign tier <small className={'text-danger'}>*</small>
      </Label> */}
      <InputMainLabelComponent title=' Select your campaign tier' isRequired={true} forId='tier_id'  />

      <Row>
        {_.map(tiers, (x, index) => {
          return (
            <Col xs={12} md={process.env.REACT_APP_AUTH_MODULE === 'artist' ? 4 : 6} xl={process.env.REACT_APP_AUTH_MODULE === 'artist' ? 4 : 6} key={index}>
             <SelectCardAppComponent
             key={`${index}-tiers`}
              title= {x.name}  
              desc={`${formatAmount(x.price)} Liberty Coins`}
              desc1={`Description: ${x.description}`}
              isSelected={selectedTierId === x.id}
              handleMouseDown={() => {
                handleButtonClick(x.id)
                  setFormData((prevState) => ({
                    ...prevState,
                    tier_id: x.id
                  }))
                  setValue('tier_id', x.id)
              }}
               />
            </Col>
          )
        })}

        {/* {_.map(tiers, (x, index) => {
          return (
            <Col xs={12} md={6} xl={3} key={index}>
              <Button
                block
                outline
                onClick={() => {
                    handleButtonClick(x.id)
                  setFormData((prevState) => ({
                    ...prevState,
                    tier_id: x.id
                  }))
                  setValue('tier_id', x.id)
                }}
                  style={{
                    backgroundColor: selectedTierId === x.id ? 'rgba(29,8,215,0.12)' : 'transparent',
                  height: 100,
                  overflow: 'hidden',
                  marginBottom: '10px' // Added overflow property
                }}
              >
                <Label className={'d-inline-block'}>
                  <span className={'w-100 d-flex justify-content-center text-center overflow-hidden'}>
                    {x.name}
                  </span>
                  <br />
                  <span className={'w-100 d-flex justify-content-center text-center overflow-hidden'}>
                    <span className={'text-primary'}>Coins : </span> <CoinIcon width={18}/> {x.price}
                  </span>
                  <br />
                  <span className={'w-100 d-flex justify-content-center text-center overflow-hidden'}>
                    {x.description}
                  </span>
                </Label>
              </Button>
            </Col>
          )
        })} */}
      </Row>
      <ErrorMessages errors={errors} name={'tier_id'} />
    </FormGroup>
  </Col>
</Row>

                <Row>
                    <Col>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={'mb-1'}>
                        <FormGroup>
                            {/*<Controller*/}
                            {/*    control={control}*/}
                            {/*    as={Input}*/}
                            {/*    type="textarea"*/}
                            {/*    name={`pitch`}*/}
                            {/*    id={`pitch`}*/}
                            {/*    defaultValue={formData.pitch}*/}
                            {/*    rules={{ required: true }}*/}
                            {/*    className={classnames({ 'is-invalid': errors[`pitch`] })}*/}
                            {/*/>*/}
                            <div className='form-label-group mb-0 mt-1'>
                                <Input
                                    type='textarea'
                                    name='pitch'
                                    id='pitch'
                                    innerRef={register({ max: 140 })}
                                    rules={{ required: true }}
                                    rows='3'
                                    value={pitch}
                                    placeholder={'Write a brief description about your song here....'}
                                    onChange={e => {
                                        setValue('pitch', e.target.value)
                                        setPitch(e.target.value)
                                    }}
                                />
                                <Label>Pitch</Label>
                            </div>
                            <span
                                className={classnames('textarea-counter-value float-right', {
                                    'bg-danger': pitch?.length > 140
                                })}
                            >
                                {`${pitch?.length ?? 0}/140`}
                            </span>
                            <ErrorMessages valErrors={valErrors} errors={errors} name={'pitch'} />
                        </FormGroup>
                    </Col>
                </Row>
                {/* </Container> */}
                <div className='d-flex justify-content-between'>
                    <Button.Ripple color='primary' className='btn-prev btn-small-width-system' outline onClick={() => stepper.previous()}>
                        <ArrowLeft size={14} className='align-middle mr-sm-25 mr-0'></ArrowLeft>
                        <span className='align-middle d-sm-inline-block d-none'>Previous</span>
                    </Button.Ripple>
                    <Button.Ripple type='submit' color='primary' className='btn-submit btn-small-width-system' disabled={loading}>
                        {loading ? <ButtonSpinner /> : null}
                        <span>Submit</span>
                    </Button.Ripple>
                </div>
            </Form>
        </Fragment>
    )
}

export default CampaignDetails
