import React from "react"
import {
    Bell,
    Briefcase,
    Shield,
    Home,
    Lock,
    User,
    Users,
    Play,
    Music,
    Codesandbox, CheckCircle, Settings
} from "react-feather"
import { Icon } from '@iconify/react'

import {trans} from '@utils'

export const Navigation = [
    {
        header: trans('user.nav.scraping_tool'),
        action: 'call',
		resource: 'general'
    },
    {
        id: 'scrapingJobs',
        title: 'Playlist Artist Finder',
        //title: trans('scraping.nav.scrapingJobs'),
        icon: <Icon icon="hugeicons:play-list-favourite-02" width={20} height={20}/>,
        navLink: '/scraping-jobs/lists',
        action: 'call',
        resource: 'view.main.scraping.tab.list'
    },
    {
        id: 'autoscrapingJobs',
        title: 'Custom Artist Finder',
        icon: <Icon icon="mingcute:user-search-line" width={20} height={20}/>,
        //navLink: '/auto-scraping-jobs/lists',
        navLink: '',
        action: 'call',
        resource: 'view.main.scraping.tab.list'
    },
    {
        id: 'fanfinder',
        title: 'Fan Finder',
        icon: <Icon icon="ph:users-three" width={20} height={20}/>,
        navLink: '/fan/finder/search',
        action: 'call',
        resource: 'view.main.fan.finder.tab.list'
    }
    
    // {
    //     id: 'crm',
    //     title: trans('user.nav.artists_crm'),
    //     icon: <Users/>,
    //     navLink: '/crm/list',
    //     action: 'call',
    //     resource: 'SCRAPING_VIEW_CRM_LIST'
    // }
    // {
    //     id: 'chat',
    //     title: 'Admins',
    //     icon: <Shield />,
    //     navLink: '/apps/demo'
    // },
    // {
    //     id: 'chat',
    //     title: 'Role & Permissions',
    //     icon: <Shield />,
    //     navLink: '/apps/demo'
    // },

]
