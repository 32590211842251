// ** Third Party Components
import PropTypes from 'prop-types'
import { Card, CardBody } from 'reactstrap'

const StatsVertical1 = ({ icon, color, stats, statTitle, className, dollar, ...rest }) => {

  const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return (
    <div className='text-center'>
    <Card className='center' style={{ width: '100%', height: '150px', display: 'inline-block' }}>
      <CardBody className={className}>
        <div className={`avatar p-50 m-0 mb-1 ${color ? `bg-light-${color}` : 'bg-light-primary'}`}>
          <div className='avatar-content'>{icon}</div>
        </div>
        <h2 className='font-weight-bolder'>${formatNumber(stats)}</h2>
        <p className='card-text line-ellipsis' style={{ marginTop: '2px' }}>{statTitle}</p>
      </CardBody>
    </Card>
  </div>
  )
}

export default StatsVertical1

// ** PropTypes
StatsVertical1.propTypes = {
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  stats: PropTypes.string.isRequired,
  statTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  dollar: PropTypes.string
}
