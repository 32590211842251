import { Fragment, useState } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Controller, useForm } from "react-hook-form"
import _ from "lodash"
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from "reactstrap"

import { trans } from '@utils'

import { ErrorMessages } from '@src/components'

import classnames from "classnames"
import PhoneInput from "react-phone-input-2"
import Select from 'react-select'
import { Icon } from "@iconify/react"

const PersonalDetails = ({ stepper, type, formState, valErrors }) => {
    const { errors, handleSubmit, control, trigger, getValues, setValue, register, watch } = useForm()
    const { formData, setFormData } = formState
    const [phone, setPhone] = useState(formData?.phone_number)

    const options = [
        { value: 'All European Countries', label: 'All European Countries' },
        { value: 'US', label: 'US' },
        { value: 'India', label: 'India' }
    ]

    const onSubmit = (data) => {
        trigger()
        if (_.isEmpty(errors)) {
            setFormData(prevState => ({
                ...prevState,
                ...data,
                phone_number: phone,
                location_timezone: data.location_timezone.value,
                track_url: data.track_url,
                facebook_link: data.facebook_link,
                instagram_link: data.instagram_link,
                twitter_link: data.twitter_link,
                tiktok_link: data.tiktok_link
            }))
            stepper.next()
        }
    }
    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                    <Label className='form-label' for='phone_number' >
                    </Label>
                    {/*<Controller*/}
                    {/*    as={Input}*/}
                    {/*    control={control}*/}
                    {/*    //country={"us"}*/}
                    {/*    autoFocus*/}
                    {/*    id='phone_number'*/}
                    {/*    name='phone_number'*/}
                    {/*    type='text'*/}
                    {/*    rules={{*/}
                    {/*        required: trans('user.validation.required')*/}
                    {/*    }}*/}
                    {/*    defaultValue={formData.phone_number ?? ''}*/}
                    {/*    className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name')})}*/}
                    {/*/>*/}
                    {/*<Controller*/}
                    {/*    name="phone-input"*/}
                    {/*    control={control}*/}
                    {/*    id='phone_number'*/}
                    {/*    name='phone_number'*/}
                    {/*    rules={{*/}
                    {/*        validate: (value) => isValidPhoneNumber(value)*/}
                    {/*    }}*/}
                    {/*    render={({ field: { onChange, value } }) => (*/}
                    {/*        <PhoneInput*/}
                    {/*            value={value}*/}
                    {/*            onChange={onChange}*/}
                    {/*            defaultCountry="TH"*/}
                    {/*            id="phone-input"*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*/>*/}
                    <PhoneInput
                        country={"us"}
                        autoFocus
                        id='phone_number'
                        name='phone_number'
                        rules={{
                            required: trans('user.validation.required')
                        }}
                        invalid={errors.phone_number && true}
                        onChange={(phone) => {
                            phone = `+${phone}`
                            setPhone(phone)
                        }}
                        value={formData?.phone_number ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={valErrors} errors={errors} name={'phone_number'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='location_timezone'>
                        Location and Time Zone <small className={'text-danger'}>*</small>
                    </Label>

                    {/*<Select*/}
                    {/*    options={options}*/}
                    {/*    id='location_timezone'*/}
                    {/*    name='location_timezone'*/}
                    {/*/>*/}
                    <Controller
                        as={Select}
                        control={control}
                        id='location_timezone'
                        name='location_timezone'
                        isClearable={false}
                        isMulti={false}
                        invalid={errors.location_timezone && true}
                        classNamePrefix='select'
                        defaultOptions
                        cacheOptions
                        value={formData?.location_timezone ?? ''}
                        options={options}
                        rules={{
                            required: trans('user.validation.required')
                        }}
                        defaultValue={formData?.location_timezone ? { label: formData.location_timezone, value: formData.location_timezone } : ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={valErrors} errors={errors} name={'location_timezone'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='track_url'>
                        Artist / Track (URL, if not released URI from Spotify for artists) <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='text'
                        id='track_url'
                        name='track_url'
                        invalid={errors.track_url && true}
                        rules={{
                            required: trans('user.validation.required')
                        }} defaultValue={formData?.track_url ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={valErrors} errors={errors} name={'track_url'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='facebook_link'>
                        Facebook (if applicable)
                    </Label>
                    <InputGroup className='input-group-merge mb-2'>
                        <InputGroupAddon addonType='prepend'>
                            <InputGroupText><Icon icon="ri:facebook-fill" /></InputGroupText>
                        </InputGroupAddon>
                        <Controller
                            as={Input}
                            control={control}
                            autoFocus
                            type='url'
                            id='facebook_link'
                            name='facebook_link'
                            rules={{
                                required: false
                            }}
                            defaultValue={formData?.facebook_link ?? ''}
                            className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                        />
                    </InputGroup>

                    <ErrorMessages valErrors={valErrors} errors={errors} name={'facebook_link'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='instagram_link'>
                        Instagram (if applicable)
                    </Label>
                    <InputGroup className='input-group-merge mb-2'>
                        <InputGroupAddon addonType='prepend'>
                            <InputGroupText><Icon icon="ri:instagram-line" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                            as={Input}
                            control={control}
                            autoFocus
                            type='url'
                            id='instagram_link'
                            name='instagram_link'
                            rules={{
                                required: false
                            }}
                            defaultValue={formData?.instagram_link ?? ''}
                            className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                        />
                    </InputGroup>
                    <ErrorMessages valErrors={valErrors} errors={errors} name={'instagram_link'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='twitter_link'>
                        Twitter (if applicable)
                    </Label>
                    <InputGroup className='input-group-merge mb-2'>
                        <InputGroupAddon addonType='prepend'>
                            <InputGroupText><Icon icon="mdi:twitter" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                            as={Input}
                            control={control}
                            autoFocus
                            type='url'
                            id='twitter_link'
                            name='twitter_link'
                            rules={{
                                required: false
                            }}
                            defaultValue={formData?.twitter_link ?? ''}
                            className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                        />
                    </InputGroup>
                    <ErrorMessages valErrors={valErrors} errors={errors} name={'twitter_link'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='tiktok_link'>
                        TikTok (if applicable)
                    </Label>
                    <InputGroup className='input-group-merge mb-2'>
                        <InputGroupAddon addonType='prepend'>
                            <InputGroupText><Icon icon="ic:outline-tiktok" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                            as={Input}
                            control={control}
                            autoFocus
                            type='url'
                            id='tiktok_link'
                            name='tiktok_link'
                            rules={{
                                required: false
                            }}
                            defaultValue={formData?.tiktok_link ?? ''}
                            className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                        />
                    </InputGroup>
                    <ErrorMessages valErrors={valErrors} errors={errors} name={'tiktok_link'} />
                </FormGroup>

                <div className='d-flex justify-content-between mt-2'>
                    <Button.Ripple color='secondary' outline disabled>
                        <ArrowLeft size={14} className='rotate-rtl align-middle me-sm-50 me-0' />
                        <div className='align-middle d-sm-inline-block d-none'>Previous</div>
                    </Button.Ripple>
                    <Button.Ripple color='primary' type='submit'>
                        <div className='align-middle d-sm-inline-block d-none'>Next</div>
                        <ArrowRight size={14} className='rotate-rtl align-middle ms-sm-50 ms-0' />
                    </Button.Ripple>
                </div>
            </Form>
        </Fragment>
    )
}

export default PersonalDetails