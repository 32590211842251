import React, { useState } from 'react'
import classnames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Cleave from 'cleave.js/react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import AsyncSelect from "react-select/async"
import 'cleave.js/dist/addons/cleave-phone.us'
import {
  FormGroup,
  Media,
  CardHeader,
  CardTitle,
  Card,
  Badge,
  CardBody,
  Label,
  Button,
  Form,
  CardText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Col, CustomInput, Container, Row
} from 'reactstrap'
import { getUserData } from '../utility/Utils'
import _ from "lodash"
import { Icon } from '@iconify/react'
import { trans } from '@utils'
import { ButtonSpinner, ErrorMessages } from '@src/components'
import { useTrans } from '@hooks/useTrans'
import { _getAllRolesWithQ } from '@modules/rolespermissions'

import {
  _declineExpressCampaign,
  _getAllReasonsWithQ
} from "../redux/actions"

import Styles from '../assets/scss/style.scss'
import { CheckSquare, CreditCard, Activity } from 'react-feather'
import formatAmountComponent from '../../../components/AmountFormatComponent'

const TransactionWalletViewModal = (props) => {

  //  const loading = useSelector(state => state.app.loading)
  const { errors, handleSubmit, control, getValues, setValue, register } = useForm()
  const [open, setOpen] = useState(true)


  const history = useHistory()
  const _close = () => {
    setOpen(false)
    props.onClose()


  }


  return (
    <Modal
      isOpen={open}
      toggle={_close}
      unmountOnClose={true}
      backdrop={true}
      className='modal-sm modal-dialog-centered'
    // contentClassName='p-0'
    // modalClassName=''
    >
      <Form className='flex-grow-1 d-flex flex-column'>
        <ModalHeader toggle={_close} className='mb-0'>

        </ModalHeader>
        <ModalBody className='flex-grow-1 pb-sm-0 pb-3 modal-body' >
          {/* <p>{props.data.id}</p> */}
          <Row style={{ padding: '3px' }}>
            <Col xs={12} xl={12}>
              <h4 className='inter-bold-font text-center mb-1'><b>Transaction Details</b></h4>
              <div className='d-flex justify-content-between align-content-start'>
                <div className='justify-content-start'>
                  <h5 className='inter-bold-font'>Amount:</h5>
                  <p className='inter-light-font' style={{ fontSize: '13px' }}>{formatAmountComponent(props.data?.coins ?? 0.00)} Liberty Coins</p>
                  <h5 className='inter-bold-font'>Type:</h5>
                  <p className='inter-light-font' style={{ fontSize: '13px' }}>{props.data?.desc?.desc}</p>
                </div>
                <div className='justify-content-start'>
                  <h5 className='inter-bold-font'>Transaction:</h5>
                  <p className='inter-light-font' style={{ fontSize: '13px' }}>#{props.data?.transactions_id}</p>
                  <h5 className='inter-bold-font'>Date:</h5>
                  <p className='inter-light-font' style={{ fontSize: '13px' }}>{new Date(props.data?.transaction_date).toLocaleDateString()}<br />{new Date(props.data?.transaction_date).toLocaleTimeString()}</p>

                </div>

              </div>
              <div className='d-flex justify-content-between align-content-start'>
                <div className='justify-content-start'>
                  <h5 className='inter-bold-font'>Description:</h5>
                  {props.data?.desc_id === 1 ? <p className='inter-light-font' style={{ fontSize: '13px' }}>Purchase Liberty Coins using {props.data?.invoice?.payment?.id === 1 ? <Icon icon={'cib:cc-stripe'} color='white' width={40} /> : <Icon icon={'fa:cc-paypal'} color='white' width={40} />}</p> : null}
                  {props.data?.desc_id === 2 ? <p className='inter-light-font' style={{ fontSize: '13px' }}>Purchase {props.data?.tiktok_campaign ? 'TikTok' : props.data?.spotify_campaign ? 'Spotify' : props.data?.instagram_campaign ? 'Instagram' : 'Custom'} Campaign</p> : null}
                  {props.data?.desc_id === 3 ? <p className='inter-light-font' style={{ fontSize: '13px' }}>{props.data?.transaction_details?.refund_type && props.data?.transaction_details?.refund_type === 'complete_refund' ? "Campaign has ended, and due to the influencer's low performance, a partial refund of Liberty Coins has been issued to wallet." : 'Campaign has expired or was declined by the influencer/Curator. Your Liberty Coins have been refunded to wallet.'}</p> : null}
                  {props.data?.desc_id === 4 ? <p className='inter-light-font' style={{ fontSize: '13px' }}>Payout Request</p> : null}
                  {props.data?.desc_id === 5 ? <p className='inter-light-font' style={{ fontSize: '13px' }}>Deducted from Balance</p> : null}
                  {props.data?.desc_id === 6 ? <p className='inter-light-font' style={{ fontSize: '13px' }}>Withdraw Credits</p> : null}

                  {/* <p  className='inter-light-font' style={{ fontSize: '13px' }}>{props.data?.desc?.desc }</p>
            <p  className='inter-light-font' style={{ fontSize: '13px' }}>{props.data?.desc?.desc }</p>
            <p  className='inter-light-font' style={{ fontSize: '13px' }}>{props.data?.desc?.desc }</p>
            <p  className='inter-light-font' style={{ fontSize: '13px' }}>{props.data?.desc?.desc }</p>
            <p  className='inter-light-font' style={{ fontSize: '13px' }}>{props.data?.desc?.desc }</p> */}
                </div>


              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          {props.data?.desc_id === 1 ? <Button.Ripple type='submit' className='flex-grow-1' color='primary'
            onClick={e => {
              history.push(`/invoices/${props.data?.id}`)
            }}>
            {/* {loading ? <ButtonSpinner/> : null} */}
            <span>View Invoice</span>
          </Button.Ripple> : null}
          {props.data?.desc_id === 6 ? <Button.Ripple type='submit' className='flex-grow-1' color='primary'
            onClick={e => {
              history.push(`/transfers/${props.data?.transfer?.wise_quote_id}`)
            }}>


            {/* {loading ? <ButtonSpinner/> : null} */}
            <span>View Transfer</span>
          </Button.Ripple> : null}
          {props.data?.desc_id === 2 ? (
            <Button.Ripple
              type='submit'
              className='flex-grow-1'
              color='primary'
              onClick={e => {
                if (props.data?.tiktok_campaign) {
                  history.push(`/express-tiktok-campaigns/${props.data?.tiktok_campaign?.id}`)
                } else if (props.data?.spotify_campaign) {
                  history.push(`/express/campaigns/${props.data?.spotify_campaign?.id}`)
                } else if (props.data?.instagram_campaign) {
                  history.push(`/express-instagram-campaigns/${props.data?.instagram_campaign?.id}`)
                } else {
                  history.push(`/campaigns/${props.data?.custom_campaign?.id}`)
                }
              }}
            >
              <span>View Campaign</span>
            </Button.Ripple>
          ) : null}
          {props.data?.transaction_details && props.data?.desc_id === 3 ? (
            <Button.Ripple
              type='submit'
              className='flex-grow-1'
              color='primary'
              onClick={e => {
                if (props.data?.transaction_details?.campaign_type === 'tiktok') {
                  history.push(`/express-tiktok-campaigns/${props.data?.transaction_details?.campaign_id}`)
                } else if (props.data?.transaction_details?.campaign_type === 'spotify') {
                  history.push(`/express-campaigns/${props.data?.transaction_details?.campaign_id}`)
                } else if (props.data?.transaction_details?.campaign_type === 'instagram') {
                  history.push(`/express-instagram-campaigns//${props.data?.transaction_details?.campaign_id}`)
                } else {
                  if (getUserData().user_type_id === 1) {
                    history.push(`/campaigns/custom/${props.data?.transaction_details?.campaign_id}`)
                  } else if (getUserData().user_type_id === 2) {
                    history.push(`/campaigns/${props.data?.transaction_details?.campaign_id}`)
                  }

                }
              }}
            >
              <span>View Campaign</span>
            </Button.Ripple>
          ) : null}
          <Button.Ripple type='button' outline className='flex-grow-1' color='secondary' onClick={_close}>
            <span>{trans('gen.actions.cancel')}</span>
          </Button.Ripple>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default TransactionWalletViewModal