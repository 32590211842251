import React from "react"

export default function numberFormatIntegerComponent(number) {
    // Parse the number to ensure it's numeric and an integer
    const parsedNumber = parseInt(number, 10)
    console.log('parsedNumber:', parsedNumber) // Log the parsed number
    
    // Check if parsing was successful and it's a valid integer
    if (!isNaN(parsedNumber) && isFinite(parsedNumber)) {
        // Use the parsedNumber for comparisons and formatting
        if (parsedNumber >= 1000000000) {
            return `${Math.floor(parsedNumber / 1000000000)}B`
        }
        if (parsedNumber >= 1000000) {
            return `${Math.floor(parsedNumber / 1000000)}M`
        }
        if (parsedNumber >= 1000) {
            return `${(parsedNumber / 1000).toFixed(1)}K`
        }
        return parsedNumber.toString() // If it's less than 1000, return it as a string
    } 

    // Return an empty string if the number is not valid
    return ''
}
