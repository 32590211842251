import React, {useEffect} from 'react'
import { getUserData } from '@authModule'
import { trans, _confirm, _url } from '@utils'
import Avatar from '@components/avatar'
import { Button, Col } from 'reactstrap'
import formatSocialsNumber from '../../../components/SocialFormatter'
import { Icon } from "@iconify/react"
import formatAmountComponent from '../../../components/AmountFormatComponent'
import {CurrencySymbol, CoinIcon} from '../../../components/CurrencyComponent'

import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { borderColor } from '@mui/system'


const WalletUserBalance = (props) => {
    const loggedUser = getUserData()
    const history = useHistory()
    console.log(props.userBalance?.balance)
   
    useEffect(() => {
        
    
      }, [loggedUser])

    return (
        <div style={{
            borderRadius: '15px',
            minHeight: '150px',
            padding: '20px', // Add padding for content spacing
             // Light gray background color
            maxWidth: '100%', // Set max width to control card width
            // boxShadow: '0 4px 24px 0 rgba(143, 143, 143, 0.1)', 
            // Updated boxShadow property
            //display: 'flex', // Ensure the container is a flexbox
        justifyContent: 'flex-start', // Align child elements to the left
        alignItems: 'center'
        }} className='create-card-background-color'>
         
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}> {/* Adjusted margin-left for spacing */}
                <h2 className="inter-bold-font mb-2"  style={{ 
                    fontSize: '20px', 
                    // fontWeight: 'bold', 
                    margin: 0 // Remove margin to align with the image
                }}>Wallet Balance</h2>
                {/* <p className="inter-light-font"  style={{ 
                    fontSize: '12px', 
                    color: '#555', 
                    margin: 0, // Remove margin for tight spacing
                    marginTop: '3px' // Add a bit of space between name and coins
                }}><Icon icon="noto:coin" width={20} /> </p> */}
                <h5 className="inter-bold-font"  style={{ 
                    fontSize: '17px', 
                    // color: '#555', 
                    margin: 0, // Remove margin for tight spacing
                    marginLeft: '-5px' // Add a bit of space between name and coins
                }}>
                <CoinIcon width={25} /> {formatAmountComponent(props.userBalance?.balance ?? 0)} Liberty Coins  <br/>{props.approx ? null :  <span className="inter-light-font text-secondary">
                 ~  <CurrencySymbol width={18} />{formatAmountComponent(parseFloat(props.userBalance?.total  ?? '0.00').toFixed(2))}</span>}
                {/* Last Updated at: {new Date(props.userBalance?.last_time_updated).toLocaleDateString()} */}
                </h5>
            </div>
           
                        {props.enabled === 'disabled' ? null : <Button.Ripple type='submit' block className='btn' color='primary' style={{ marginTop: '30px', backgroundColor: '#5ecbc8', borderColor: '#5ecbc8' }}
                        onClick={(e) => {
                            history.push('/wallet/transaction/list')
                        } }>
                         
                          <span className={'mr-50 inter-bold-font'} style={{ fontSize: '15px' }}>Deposit</span>

                        </Button.Ripple>}
                     
        </div>
    )
}

export default WalletUserBalance
