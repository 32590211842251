// SmallCard.js
import React from 'react'
import '../assets/scss/style.scss' // Import styles if using a separate CSS file
import { Icon } from "@iconify/react"
import { CoinIcon } from './CurrencyComponent'

const StatsAppCardCampaignComponent = ({ title, value, icon, symbol, isDisabled, coin = false }) => {
  return (
    <div className="small-card-tiktok-new  inter-light-font create-card-background-color-inside">
      <p className="card-value-tiktok">{coin ? <CoinIcon width={15} /> : isDisabled ? <Icon icon="jam:alert" color='#ff6b6b' width={18} /> : <Icon icon={icon} width={15} />  }</p>
      <p className={isDisabled ? 'card-title-tiktok-disable' : 'card-title-tiktok'}>{title}</p>
      <h6 className={isDisabled ? 'card-value-tiktok-disabled' : 'card-value-tiktok'} >{value} {symbol ? symbol : ''}</h6>
    </div>
  )
}

export default StatsAppCardCampaignComponent
