import React from "react"
import { Bell, Briefcase, Shield, File, Music, Link, Search, Home, Lock, Archive, User, Users, ShoppingCart, Activity, FileText, FilePlus, Zap, Star, DollarSign } from "react-feather"
import { trans } from '@utils'
import { Icon } from "@iconify/react"
import { CoinIcon } from "../../../components/CurrencyComponent"
export { getUserData } from '../utility/Utils'


//if true is artist menu else curator menu
// const loggedUser = getUserData()
const isArtist = true
const userType = localStorage.getItem('user_type_id')
const artistNavigation = [
    {
        id: 'dashboard',
        // title: trans('user.nav.dashboard'),
        title: `Dashboard`,
        icon: <Icon icon="hugeicons:dashboard-square-02" width={20} height={20}/>,
        navLink: '/dashboard',
        action: 'call',
        resource: 'ARTIST_ALL_PERMISSION'
    },
    {
		header: 'Campaign Management',
		action: 'call',
		resource: 'general'
	},
    {
        id: 'add-campaign',
        // title: trans('user.nav.campaigns'),
        title: 'New Campaign',
       icon: <Icon icon="prime:folder-plus" width={20} height={20}/>,
        navLink: '/select/platform',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    },


    // {
    //     id: 'applied-campaigns',
    //     // title: trans('user.nav.myCampaigns'),
    //     title: 'Custom Campaigns',
    //     icon: <Archive size={20}/>,
    //     navLink: '/my-campaigns',
    //     action: 'call',
    //     resource: 'CURATOR_PERMISSION'
    // },
    {
        id: 'express-campaign',
        // title: trans('user.nav.campaigns'),
        title: 'Spotify Campaigns',
       icon: <Icon icon="iconoir:spotify" width={20} height={20}/>,
        navLink: '/express/campaigns/list',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    },
    {
        id: 'tiktok-express-campaign',
        // title: trans('user.nav.campaigns'),
        title: 'TikTok Campaigns',
       icon: <Icon icon="prime:tiktok" width={25} height={25}/>,
        navLink: '/tiktok/express/campaigns/list',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    },
    {
        id: 'instagram-express-campaign',
        // title: trans('user.nav.campaigns'),
        title: 'Instagram Campaigns',
       icon: <Icon icon="ri:instagram-line" width={25} height={25}/>,
        navLink: '/instagram/express/campaigns/list',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    },

    {
        id: 'campaigns',
        // title: trans('user.nav.campaigns'),
        title: 'Custom Campaigns',
        icon: <Icon icon="hugeicons:marketing" width={20} height={20}/>,
        navLink: '/custom/campaigns/list',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    },

    {
        id: 'find-campaigns',
        // title: trans('user.nav.campaigns'),
        title: 'Discover Campaigns',
        icon: <Icon icon="hugeicons:search-square" width={20} height={20}/>,
        navLink: '/campaigns/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    {
        id: 'my-playlists',
        // title: trans('user.nav.dashboard'),
        title: 'My Playlists',
        icon: <Icon icon="ph:playlist-duotone" width={20} height={20}/>,
        navLink: '/express/my/playlists/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    {
        id: 'express-campaign',
        // title: trans('user.nav.campaigns'),
        title: 'Spotify Campaigns',
       icon: <Icon icon="iconoir:spotify" width={20} height={20}/>,
        navLink: '/express/campaigns/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    {
        id: 'custom-campaign',
        // title: trans('user.nav.campaigns'),
        title: 'Custom Campaigns',
        icon: <Icon icon="hugeicons:marketing" width={20} height={20}/>,
        navLink: '/my-custom/campaigns/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    // {
    //     id: 'find-campaigns',
    //     // title: trans('user.nav.campaigns'),
    //     title: 'Discover Campaigns',
    //     icon: <Icon icon="hugeicons:search-square" width={20} height={20}/>,
    //     navLink: '/campaigns/list',
    //     action: 'call',
    //     resource: 'INFLUENCER_PERMISSION'
    // },
    {
        id: 'tiktok-inf-express-campaign',
        // title: trans('user.nav.campaigns'),
        title: 'TikTok Campaigns',
        icon: <Icon icon="prime:tiktok" width={25} height={25}/>,
        navLink: '/tiktok/express/campaigns/list',
        action: 'call',
        resource: 'INFLUENCER_PERMISSION'
    },
    {
        id: 'instagram-inf-express-campaign',
        // title: trans('user.nav.campaigns'),
        title: 'Instagram Campaigns',
        icon: <Icon icon="ri:instagram-line" width={25} height={25}/>,
        navLink: '/instagram/express/campaigns/list',
        action: 'call',
        resource: 'INFLUENCER_PERMISSION'
    },
    // {
    //     id: 'influ-custom-campaigns',
    //     // title: trans('user.nav.campaigns'),
    //     title: 'Custom Campaigns',
    //     icon: <Icon icon="hugeicons:marketing" width={20} height={20}/>,
    //     navLink: '/my-custom/campaigns/list',
    //     action: 'call',
    //     resource: 'INFLUENCER_PERMISSION'
    // },
    // {
    //     id: ' My-Campaigns-tiktok',
    //     title: 'My Campaigns',
    //     icon: <Briefcase />,
    //     children: [
    //         {
    //             id: 'tiktok-inf-express-campaign',
    //             // title: trans('user.nav.campaigns'),
    //             title: 'TikTok Express Campaigns',
    //             icon: <Zap size={20} />,
    //             navLink: '/tiktok/express/campaigns/list',
    //             action: 'call',
    //             resource: 'INFLUENCER_PERMISSION'
    //         },
    //         {
    //             id: 'influ-custom-campaigns',
    //             // title: trans('user.nav.campaigns'),
    //             title: 'Custom Campaigns',
    //             icon: <Briefcase size={20} />,
    //             navLink: '/my-custom/campaigns/list',
    //             action: 'call',
    //             resource: 'INFLUENCER_PERMISSION'
    //         }
    //     ]
    // },


    // {
    //     id: 'Campaigns',
    //     title: 'Campaigns',
    //     icon: <Briefcase />,
    //     children: [
           

    //     ]
    // },
    {
		header: 'Wallet',
		action: 'call',
		resource: 'general'
	},
    {
        id: 'liberty-coins',
        // title: trans('user.nav.campaigns'),
        title: 'Wallet Balance',
       icon: <Icon icon="hugeicons:wallet-03" width={20} height={20}/>,
        navLink: '/wallet/user/transaction',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    },
    {
        id: 'payouts',
        // title: trans('user.nav.payouts'),
        title: 'Wallet Balance',
        icon: <Icon icon="hugeicons:wallet-03" width={20} height={20}/>,
        navLink: '/payouts/list',
        action: 'call',
        resource: 'CURATOR_PERMISSION'
    },
    {
        id: 'payouts',
        // title: trans('user.nav.payouts'),
        title: 'Wallet Balance',
        icon: <Icon icon="hugeicons:wallet-03" width={20} height={20}/>,
        navLink: '/payouts/list',
        action: 'call',
        resource: 'INFLUENCER_PERMISSION'
    },
    {
		header: 'Tools',
		action: 'call',
		 resource: 'ARTIST_PERMISSION'
	},
    {
        id: 'fan-finder-for-artist',
        // title: trans('user.nav.campaigns'),
        title: 'Fan Finder',
        icon: <Icon icon="ph:users-three" width={20} height={20}/>,
        navLink: '/fan-finder/artist',
        action: 'call',
        resource: 'ARTIST_PERMISSION'
    }
    
    // {
    //     id: 'spotify-for-artist',
    //     // title: trans('user.nav.campaigns'),
    //     title: 'Connections',
    //     icon: <Link size={20} />,
    //     navLink: '/fan-finder/connection',
    //     action: 'call',
    //     resource: 'ARTIST_PERMISSION'
    // },
    // {
    //     id: ' My-Campaigns',
    //     title: 'My Campaigns',
    //     icon: <Briefcase />,
    //     children: [
    //         {
    //             id: 'express-campaign',
    //             // title: trans('user.nav.campaigns'),
    //             title: 'Express Campaigns',
    //             icon: <Zap size={20} />,
    //             navLink: '/express/campaigns/list',
    //             action: 'call',
    //             resource: 'CURATOR_PERMISSION'
    //         },
    //         {
    //             id: 'custom-campaign',
    //             // title: trans('user.nav.campaigns'),
    //             title: 'Custom Campaigns',
    //             icon: <Briefcase size={20} />,
    //             navLink: '/my-custom/campaigns/list',
    //             action: 'call',
    //             resource: 'CURATOR_PERMISSION'
    //         }
    //     ]
    // },

   
    // {
    //     id: 'my-connections',
    //     // title: trans('user.nav.dashboard'),
    //     title: 'Connections',
    //     icon: <Link size={20} />,
    //     navLink: '/connection/list',
    //     action: 'call',
    //     resource: 'CURATOR_PERMISSION'
    // }
    // {
    //     id: 'tiktok-for-influencer',
    //     // title: trans('user.nav.campaigns'),
    //     title: 'Connections',
    //     icon: <Link size={20} />,
    //     navLink: '/tiktok/connection/list',
    //     action: 'call',
    //     resource: 'INFLUENCER_PERMISSION'
    // }
    // {
    //     id: 'invoices',
    //     // title: trans('user.nav.invoices'),
    //     title: 'Invoices',
    //     icon: <FileText size={20} />,
    //     navLink: '/invoices/list',
    //     action: 'call',
    //     resource: 'ARTIST_PERMISSION'
    // },
    // Spotify Check Changes
  
    // {
    //     id: 'forms',
    //     title: 'Forms',
    //     icon: <File size={20}/>,
    //     navLink: '/forms',
    //     action: 'call',
    //     resource: 'ARTIST_PERMISSION'
    // },
    // {
    //     id: 'tickets',
    //     title: 'Support Tickets',
    //     icon: <File size={20} />,
    //     navLink: '/tickets',
    //     action: 'call',
    //     resource: 'ARTIST_ALL_PERMISSION'
    // },

    // {
    //     id: 'Account',
    //     title: 'Account',
    //     icon: <User />,
    //     children: [
    //         {
    //             id: 'profile',
    //             // title: trans('user.nav.account'),
    //             title: 'Profile',
    //             icon: <User size={20} />,
    //             navLink: '/account',
    //             action: 'call',
    //             resource: 'ARTIST_ALL_PERMISSION'
    //         },
    //         {
    //             id: 'security',
    //             // title: trans('user.nav.security'),
    //             title: 'Security',
    //             icon: <Lock size={20} />,
    //             navLink: '/security',
    //             action: 'call',
    //             resource: 'EMAIL_OAUTH_TYPE'
    //         }
    //     ]
    // }
]


// console.log(`user type ${userType}`)
//   // Select the appropriate navigation based on the user type
const navigation = artistNavigation

export { navigation as Navigation }
