import { Fragment } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Controller, useForm } from "react-hook-form"
import _ from "lodash"
import AsyncSelect from "react-select/async"
import { Button, Form, FormGroup, FormFeedback, Input, Label } from "reactstrap"
import moment from 'moment'

import { trans } from '@utils'
import { ErrorMessages } from '@src/components'
import FileUploaderRestrictions from './FileUpload'
import classnames from "classnames"
import { _getAllGenresWithQ } from "../../redux/actions"
import Flatpickr from "react-flatpickr"
import { toast } from "react-toastify"


const SongDetails = (props) => {
    const { errors, handleSubmit, control, trigger, data, setData, getValues, setValue, register, watch } = useForm({
        title: "",
        file: null
    })
    const { formData, setFormData } = props.formState
    //console.log(formData)
    const [track, setTrack] = props.trackState
    const [trackUrl, setTrackUrl] = props.trackUrlState
    const [image, setImage] = props.imageState
    const [imageUrl, setImageUrl] = props.imageUrlState

    const onSubmit = (data) => {
        console.log(imageUrl)
        console.log(trackUrl)
        trigger()
        if (!props.isEditAction) {
            if (image && track) {
                if (_.isEmpty(errors)) {
                    setFormData(prevState => {
                        if (typeof data.release_date === 'string') {
                            data.release_date = data.release_date
                          } else if (moment(data.release_date[0]).format('Y-MM-DD') !== 'Invalid date') {
                            data.release_date = moment(data.release_date[0]).format('Y-MM-DD')
                          } else {
                            data.release_date = ''
                          }
                          return {
                        ...prevState,
                        ...data,
                        type: data.type,
                        release_date: data.release_date,
                        genre_id: data.genre_id.value,
                        track_mood: data.track_mood,
                        track_bio: data.track_bio
                        //track_file: trackUrl,
                        //cover_image: imageUrl
                          }
                    })
                    props.stepper.next()
                }
            } else {
                toast.error("Please upload track file and cover image")
            }
        } else {
            if (imageUrl && trackUrl) {
                if (_.isEmpty(errors)) {
                    setFormData(prevState => {
                        if (typeof data.release_date === 'string') {
                            data.release_date = data.release_date
                          } else if (moment(data.release_date[0]).format('Y-MM-DD') !== 'Invalid date') {
                            data.release_date = moment(data.release_date[0]).format('Y-MM-DD')
                          } else {
                            data.release_date = ''
                          }
                          return {
                        ...prevState,
                        ...data,
                        type: data.type,
                        release_date: data.release_date,
                        genre_id: data.genre_id.value,
                        track_mood: data.track_mood,
                        track_bio: data.track_bio
                        //track_file: trackUrl,
                        //cover_image: imageUrl
                    }
                    })
                    props.stepper.next()
                }
            } else {
                toast.error("Please upload track file and cover image")
            }
        }


    }
    const minDate = new Date()
    // add a day
    minDate.setDate(minDate.getDate() - 1)
    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <Label className='form-label' for='type'>
                        Single / Album / EP <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='text'
                        id='type'
                        name='type'
                        invalid={errors.type && true}
                        rules={{
                            required: trans('user.validation.required')
                        }} defaultValue={formData?.type ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'type'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='release_date'>
                        Release Date <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        key={"release_date"}
                        id={'release_date'}
                        as={Flatpickr}
                        control={control}
                        enable
                        invalid={errors.release_date && true}
                        style={{
                            width: "100%",
                            boxSizing: "border-box",
                            height: "38px",
                            borderColor: 'grey',
                            border: '1px solid rgba(189, 195, 199)',
                            borderRadius: '5px',
                            padding: '10px'
                        }}
                        name='release_date'
                        rules={{
                            required: trans('user.validation.required')
                        }}
                        // defaultValue={new Date(formData?.release_date).toLocaleDateString() ?? ''}
                        defaultValue={formData?.release_date ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                        options={{
                            dateFormat: 'Y-m-d'
                        }}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'release_date'} />
                </FormGroup>
                <FormGroup encType="multipart/form-data" onChange={e => onTrackChange(e)}>
                    <Label className='form-label' for='track_file'>
                        Track File Upload (Wav or Mp3) <small className={'text-danger'}>*</small>
                    </Label>

                    <Controller
                        control={control}
                        autoFocus
                        id='track_file'
                        name='track_file'
                        invalid={errors.track_file && true}
                        render={() => (
                            <FileUploaderRestrictions isEditAction={props.isEditAction} value={formData?.track_file} allowedFiles={'audio'} setState={setTrack} />
                        )}
                        rules={{
                            required: false
                        }}
                        defaultValue={formData?.track_file ?? trackUrl}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    {errors.track_file && <FormFeedback>Please upload a track file</FormFeedback>}                           </FormGroup>
                <FormGroup encType="multipart/form-data">
                    <Label className='form-label' for='cover_image'>
                        {/* Cover Artwork Image Upload (High-Res) {props.isEditAction === false ? (<small className={'text-danger'}>*</small>) : ""} */}
                        Cover Artwork Image Upload (High-Res) <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        control={control}
                        autoFocus
                        id='cover_image'
                        name='cover_image'
                        invalid={errors.cover_image && true}
                        render={() => (
                            <FileUploaderRestrictions isEditAction={props.isEditAction} value={formData?.cover_image} allowedFiles={'image'} setState={setImage} />
                        )}
                        //onChange={e => onImageChange(e)}
                        rules={{
                            required: true
                        }}
                        defaultValue={formData?.cover_image ?? imageUrl.substring(8)}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}

                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'cover_image'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='genre_id'>
                        Track Genre <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={AsyncSelect}
                        control={control}
                        name={'genre_id'}
                        id='genre_id'
                        isClearable={false}
                        isMulti={false}
                        classNamePrefix='select'
                        invalid={errors.genre_id && true}
                        defaultOptions
                        cacheOptions
                        loadOptions={_getAllGenresWithQ}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                        defaultValue={formData?.genre_id ? { label: formData.genre_id.name, value: formData.genre_id.id } : ''}
                        rules={{
                            required: true
                        }}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'genre_id'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='track_mood'>
                        Track Mood / Feel <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='text'
                        id='track_mood'
                        invalid={errors.track_mood && true}
                        name='track_mood'
                        rules={{
                            required: trans('user.validation.required')
                        }} defaultValue={formData?.track_mood ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'track_mood'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='track_bio'>
                        Track Bio <small className={'text-danger'}>*</small>
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='textarea'
                        id='track_bio'
                        invalid={errors.track_bio && true}
                        name='track_bio'
                        rules={{
                            required: trans('user.validation.required')
                        }} defaultValue={formData?.track_bio ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'track_bio'} />
                </FormGroup>

                <div className='d-flex justify-content-between mt-2'>
                    <Button color='primary' onClick={() => props.stepper.previous()}>
                        <ArrowLeft size={14} className='rotate-rtl align-middle me-sm-50 me-0' />
                        <div className='align-middle d-sm-inline-block d-none'>Previous</div>
                    </Button>
                    <Button.Ripple color='primary' type='submit'>
                        <div className='align-middle d-sm-inline-block d-none'>Next</div>
                        <ArrowRight size={14} className='rotate-rtl align-middle ms-sm-50 ms-0' />
                    </Button.Ripple>
                </div>
            </Form>
        </Fragment>
    )
}

export default SongDetails