import React, {useState} from 'react'
import { Icon } from '@iconify/react'
import { Tooltip, UncontrolledTooltip, Label, Alert } from 'reactstrap'
import themeConfig from '@configs/themeConfig'
import { ExternalLink } from 'react-feather'
import Styles from '../assets/scss/style.scss'
// import { CircleFlag } from "react-circle-flags"
import { CircleFlag } from 'react-circle-flags'
import CoinImage from '../assets/images/liberty_coin.png'


const getAlertConfig = (color) => {
  switch (color) {
    case 'success':
      return { name: 'icon-park-outline:check-one', iconColor: '#5ecbc8', background: '#d9fffe', iconBackground: '#8ef9f6' } // Green
      case 'primary':
        return { name: 'ic:outline-info', iconColor: '#5ecbc8', background: '#d9fffe', iconBackground: '#8ef9f6'  } // Green
    case 'warning':
      return { name: 'ion:warning-outline', iconColor: '#FFA800', background: '#fff3dc', iconBackground: '#f6dba8'  } // Yellow
    case 'danger':
      return { name: 'carbon:close-outline', iconColor: '#ED544E', background: '#fdbdbb', iconBackground: '#ff8a85'  } // Red
    default:
      return { name: ic_warning, iconColor: '#007bff', background: '#cee4fd', iconBackground: '#89c2ff'  } // Default to a neutral color
  }
}
export const CurrencySymbol = ({  width = 14 }) => {
  return (
      <span style={{ fontSize: `${width}px`}}>£</span>
  )
}
export const CoinIcon = ({ width = 30 }) => {
    return (
        <span><img  src={CoinImage} width={width} style={{ maxWidth: '40px' }} /></span>
    )
  }

  export const capitalizeFirstLetter = (text) => {
    if (!text) return ''
    return text.charAt(0).toUpperCase() + text.slice(1)
  } 

  export const CountryFlag = ({ countryCode }) => (
   <span style={{ marginBottom: '5px' }}>
     <CircleFlag countryCode={countryCode.toLowerCase()} height="30" width={30} />
   </span>
  )

  export const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text
    }
    return `${text.slice(0, maxLength)}...`
  }  

  export const InputMainLabelComponent = ({forId, title, isRequired, id, tooltip, tooltipComponent}) => {
    return  <Label className='form-label inter-light-font' id={id} for={forId} style={{ 'font-size': '13px', 'margin-bottom': '10px' }}>
    {title}{isRequired ? <span className='text-danger'>*</span> : null } {tooltip ? tooltipComponent : null}
  </Label>
  }

  export const MainAlertComponent = ({ title, desc, type, button, alert, id, color, isButtonSameLevel = false }) => {
    const { name, background, iconColor, iconBackground } = getAlertConfig(type)
    return (
      <>
      {themeConfig.layout.skin === 'dark' ? (
        <Alert
          color={color || background}
          id={id}
          style={{
            background,
            padding: '10px'
          }}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
            <div className='icon-container-custom d-flex align-items-center justify-content-center ml-1' style={{ background: iconBackground }}>
              <Icon icon={name} width={20} color={iconColor} /> {/* Adjust icon size as needed */}
            </div>
            <div className='text-container-custom ml-1'>
              {title ? <h5 className='inter-bold-font'  style={{ color: 'black', fontSize: '13px'}}>{title}</h5> : null}
              {desc ? <p className='inter-light-font'  style={{ color: '#737373', fontSize: '13px'}}>{desc} {isButtonSameLevel === true ? <span>{button}</span> : null} </p>  : null}
            </div>
            </div>
           {isButtonSameLevel === false ? <div className='align-items-center justify-content-end'>
            {button}
            </div> : null} 
            
          </div>
        </Alert>
      ) : alert}
    </>
    )
  }

  export const ViewOnSpotify = ({text, link}) => {
    return   <a href={link} target="_blank" className='text-small' rel="noopener noreferrer" blank="_blank" style={{ color: 'gray' }}>{text} <ExternalLink color='gray' size={15} /></a>
  }

  export const setLocalStorageValue = (name, value) => {
   return localStorage.setItem(name, value)
  }
  export const getLocalStorageValue = (name) => {
    return localStorage.getItem(name)
  }
