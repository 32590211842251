// ** Reactstrap Imports
import { Card, CardHeader, Progress } from 'reactstrap'

// ** Custom Components

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'

const UserProjectsList = ({user}) => {
  return (
      <></>
  )
}

export default UserProjectsList
