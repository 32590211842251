import React from "react"
import { Briefcase, FileText, Home, Shield, Users, Settings, Music, CheckCircle } from "react-feather"
import { Icon } from '@iconify/react'
import { trans } from '@utils'
import { Message } from "@material-ui/icons"

export const Navigation = [
	{
		id: 'dashboard',
		title: "Dashboard",
		icon: <Icon icon="hugeicons:dashboard-square-02" width={20} height={20}/>,
		//icon: <Home size={20} />,
		navLink: '/dashboard',
		action: 'call',
		resource: 'general'
	},
	{
		header: 'Influencer Platform',
		action: 'call',
		resource: 'general'
	},
	{
		id: '5',
		title: 'Users',
		icon: <Icon icon="solar:users-group-rounded-linear" width={20} height={20}/>,
		//icon: <Users />,
		navLink: '/users/list',
		action: 'call',
		resource: 'view.main.users.tab.list'
	},

	{
		id: 'campaigns',
		title: 'Campaigns',
		icon: <Icon icon="hugeicons:marketing" width={20} height={20}/>,
		//icon: <Briefcase size={20} />,
		children: [
			{
				id: '1',
				title: 'Custom Campaigns',
				icon: <Icon icon="hugeicons:marketing" width={20} height={20}/>,
				navLink: '/campaigns/list',
				action: 'call',
				resource: 'view.main.custom.campaigns.tab.list'
			},

			{
				id: '2',
				title: 'Spotify Campaigns',
				icon: <Icon icon="iconoir:spotify" width={20} height={20}/>,
				navLink: '/express/campaigns/list',
				action: 'call',
				resource: 'view.main.express.campaigns.tab.list'
			},
			{
				id: '3',
				title: 'TikTok Campaigns',
				icon: <Icon icon="prime:tiktok" width={25} height={25}/>,
				navLink: '/express/tiktok/campaigns/list',
				action: 'call',
				resource: 'view.main.tiktok.express.campaign.tab.list'
			},
			{
				id: '3',
				title: 'Instagram Campaigns',
				icon: <Icon icon="iconoir:instagram" width={25} height={25}/>,
				navLink: '/express/instagram/campaigns/list',
				action: 'call',
				resource: 'view.main.instagram.express.campaign.tab.list'
			}

		]
	},
	{
		id: '2',
		title: 'Express Playlists',
		icon: <Icon icon="solar:playlist-minimalistic-3-linear" width={20} height={20}/>,
		navLink: '/express/playlists/list',
		action: 'call',
		resource: 'view.main.express.playlist.tab.list'
	},
	// {
	// 	id: '7',
	// 	title: 'Invoices',
	// 	icon: <FileText />,
	// 	navLink: '/invoices/list',
	// 	action: 'call',
	// 	resource: 'view.main.invoices.tab.list'
	// },
	// {
	// 	id: '8',
	// 	title: 'Payouts',
	// 	icon: <FileText />,
	// 	navLink: '/payouts/list',
	// 	action: 'call',
	// 	resource: 'view.main.payouts.tab.list'
	// },

	{
		id: 'reports',
		title: 'Reports',
		icon: <Icon icon="hugeicons:analytics-down" width={20} height={20}/>,
		//icon: <Briefcase size={20} />,
		children: [
			
			{
				id: '9',
				title: 'Reports',
				icon: <Icon icon="hugeicons:analytics-down" width={20} height={20}/>,
				navLink: '/reports',
				action: 'call',
				resource: 'view.main.reports.tab.list'
			}
		]
	},
	{
		header: 'Wallet',
		action: 'call',
		resource: 'general'
	},
	{
		id: 'Wallet-data',
		title: 'Wallet',
		icon: <Icon icon="hugeicons:wallet-03" width={20} height={20}/>,
		//icon: <Briefcase size={20} />,
		children: [
			{
				id: 'Main Wallet',
				title: 'Main Wallet',
				icon: <Icon icon="hugeicons:wallet-01" width={20} height={20}/>,
				navLink: '/wallet/main/list',
				action: 'call',
				resource: 'view.main.wallet.tab.list'
			},
			{
				id: '20',
				title: 'Payout Requests',
				icon: <Icon icon="hugeicons:mail-upload-02" width={20} height={20}/>,
				navLink: '/requests/list',
				action: 'call',
				resource: 'view.main.transfers.tab.list'
			},
			// {
			// 	id: '1',
			// 	title: 'Deposits',
			// 	icon: <Icon icon="ph:hand-withdraw" width={20} height={20}/>,
			// 	navLink: '/wallet/deposit/list',
			// 	action: 'call',
			// 	resource: 'view.main.custom.campaigns.tab.list'
			// },
		
			// {
			// 	id: '2',
			// 	title: 'Withdrawal',
			// 	icon: <Icon icon="ph:hand-deposit" width={20} height={20}/>,
			// 	navLink: '/wallet/withdrawals/list',
			// 	action: 'call',
			// 	resource: 'view.main.custom.campaigns.tab.list'
			// },
			// {
			// 	id: '3',
			// 	title: 'Coin Transactions',
			// 	icon: <Icon icon="hugeicons:wallet-02" width={20} height={20}/>,
			// 	navLink: '/wallet/transaction/list',
			// 	action: 'call',
			// 	resource: 'view.main.custom.campaigns.tab.list'
			// },
			{
                id: 'packages-details',
                title: 'Wallet Packages',
                icon: <Icon icon="lets-icons:boxes-light" width={20} height={20}/>,
                navLink: '/wallet/packages/list',
                action: 'call',
                resource: 'view.main.wallet.packages.tab.list'
            }

		]
	}
	

// 	{
// 		id: 'wallets',
// 		title: 'Wallets',
// 		icon: <Briefcase size={20} />,
// 		children: [

// 			{
// 				id: '1',
// 				title: 'Deposits History',
// 				icon: <FileText />,
// 				navLink: '/wallet/deposit/list',
// 				action: 'call',
// 				resource: 'view.main.custom.campaigns.tab.list'
// 			},

// 			{
// 				id: '2',
// 				title: 'Withdrawal History',
// 				icon: <FileText />,
// 				navLink: '/wallet/withdrawals/list',
// 				action: 'call',
// 				resource: 'view.main.custom.campaigns.tab.list'
// 			},
// 			{
// 				id: '3',
// 				title: 'Transactions History',
// 				icon: <FileText />,
// 				navLink: '/wallet/transaction/list',
// 				action: 'call',
// 				resource: 'view.main.custom.campaigns.tab.list'
// 			}

// 		]
// 	}
]
