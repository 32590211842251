// ** React Imports
import { useState, Fragment } from 'react'

// ** Reactstrap Imports
import { Card, CardBody, Button, ListGroup, ListGroupItem } from 'reactstrap'

// ** Third Party Imports
//import toast from 'react-hot-toast'
import { useDropzone } from 'react-dropzone'
import { X, DownloadCloud, File } from 'react-feather'
import { _url } from '@utils'

const FileUploaderRestrictions = ({ value, allowedFiles, setState, isEditAction }) => {
    // ** State
    const [files, setFiles] = useState([])
    const [defaultValue, setDefaultValue] = useState(value.substring(8))
    let extensions
    // if (defaultValue) {
    //     setFiles([defaultValue])
    // }
    if (allowedFiles === "image") {
        extensions = { "image/*": ['.png', '.jpg', '.jpeg', '.gif'] }
    } else if (allowedFiles === "audio") {
        extensions = { "audio/*": ['.mp3', '.wav'] }
    }
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: extensions,
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length) {
                toast.error('You can only upload allowed Files!.')
            } else {
                setFiles([...acceptedFiles])
                setState(acceptedFiles[0])
                // if (allowedFiles === "image") {
                //     console.log(acceptedFiles)
                //     acceptedFiles.map((file, index) => {
                //         console.log(file)
                //         setImage(file)
                //     })
                // } else if (allowedFiles === "audio") {
                //     acceptedFiles.map((file, index) => {
                //         setImage(file)
                //     })
                // }

            }
        }
    })

    const handleRemoveFile = file => {
        const uploadedFiles = files
        const filtered = uploadedFiles.filter(i => i.name !== file.name)
        setFiles([...filtered])
    }

    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        } else {
            return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        }
    }
    const handleRemoveAllFiles = () => {
        setFiles([])
        setDefaultValue([])
    }


    const fileList = files.map((file, index) => (
        <ListGroupItem key={`${file.name}-${index}`} className='d-flex align-items-center justify-content-between'>
            <div className='file-details d-flex align-items-center'>
                <div className='file-preview me-1'>
                    {/* {defaultValue ? (<img className='rounded' src={_url(defaultValue)} height='28' width='28' />) : "no"} */}
                    {allowedFiles === "image" ? (
                        <img className='rounded' src={URL.createObjectURL(file)} height='28' width='28' />
                    ) : (
                        <File />
                    )}

                </div>
                <div>
                    <p className='file-name mb-0 ml-1'>{file.name}</p>
                    <p className='file-size mb-0 ml-1'>{renderFileSize(file.size)}</p>
                </div>
            </div>
            <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveAllFiles()}>
                <X size={14} />
            </Button>
        </ListGroupItem>
    ))
    const defaultList = (
        <ListGroupItem className='d-flex align-items-center justify-content-between'>
            <div className='file-details d-flex align-items-center'>
                <div className='file-preview me-1'>
                    {allowedFiles === "image" ? (
                        <img className='rounded' alt={`${defaultValue}`} src={_url(defaultValue)} height='28' width='28' />
                    ) : (
                        <File />
                    )}
                </div>
                <div>
                    <p className='file-name mb-0 ml-1'>{defaultValue}</p>
                </div>

            </div>
            <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveAllFiles()}>
                <X size={14} />
            </Button>
        </ListGroupItem>
    )


    return (
        <Card>
            <CardBody>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className='d-flex align-items-center justify-content-center flex-column'>
                        <DownloadCloud size={64} />
                        <h5>Drop Files here or click to upload</h5>
                        <p className='text-secondary'>
                            Drop files here or click{' '}
                            <a href='/' onClick={e => e.preventDefault()}>
                                browse
                            </a>{' '}
                            thorough your machine
                        </p>
                    </div>
                </div>
                {files.length ? (
                    <Fragment>
                        <ListGroup className='my-2'>{fileList}</ListGroup>
                    </Fragment>
                ) : defaultValue.length ? (
                    <Fragment>
                        <ListGroup className='my-2'>{defaultList}</ListGroup>
                    </Fragment>
                ) : null}
            </CardBody>
        </Card>
    )
}

export default FileUploaderRestrictions
