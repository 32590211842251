import {store} from '@fwsrc/redux/storeConfig/store'

export const FileUrl = (url) => {
	return `${process.env.REACT_APP_FILES_BASE_URL}/${url}`
}

let isUserLoggedIn
let getUserData
// const auth_module = process.env.REACT_APP_AUTH_MODULE
if (process.env.REACT_APP_AUTH_MODULE === 'user') {
    isUserLoggedIn = () => (store.getState().user.token)
    getUserData = () => (store.getState().user.userData)
} else {
    isUserLoggedIn = () => (store.getState().artist.token)
    getUserData = () => (store.getState().artist.userData)
}
export {isUserLoggedIn, getUserData}