import { useRef, useState } from 'react'
import { useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import _ from "lodash"

import { Modal, ModalBody, ModalHeader } from "reactstrap"
import Wizard from '@components/wizard'
import { getUserData } from '@authModule'

import PersonalDetails from "./PersonalDetails"
import SongDetails from "./SongDetails"
import ExtraInfo from "./ExtraInfo"
import ArtistDetails from "./ArtistDetails"
import About from "./About"


const BasicInfoWizardModal = (props) => {
  const loggedUser = getUserData()
  const { errors, handleSubmit, control, getValues, setValue, register, watch } = useForm()
  const [stepper, setStepper] = useState(null)
  const loading = useSelector(state => state.app.loading)
  const [isEditAction, setIsEditAction] = useState(props.data.id)
  const [open, setOpen] = useState(true)
  const [valErrors, setValErrors] = useState({})
  const [formData, setFormData] = useState({
    id: _.get(props, 'data.id', null),
    track_url: _.get(props, 'data.track_url', ''),
    type: _.get(props, 'data.type', ''),
    genre_id: _.get(props, 'data.genre_id', ''),
    updated_by: _.get(props, 'data.updated_by.id', ''),
    updated_by_admin: _.get(props, 'data.updated_by_admin.id', ''),
    form_type: _.get(props, 'data.form_type', 1),
    //assigned_to: _.get(props, 'data.assigned_to') ? {label: _.get(props, 'data.assigned_to.name'), value: _.get(props, 'data.assigned_to.id')} : '',
    created_by: _.get(props, 'data.created_by.id', ''),
    created_by_admin: _.get(props, 'data.created_by_admin.id', ''),
    status_id: _.get(props, 'data.status_id.id', ''),
    track_file: _.get(props, 'data.track_file', ''),
    cover_image: _.get(props, 'data.cover_image', ''),
    track_mood: _.get(props, 'data.track_mood', ''),
    track_bio: _.get(props, 'data.track_bio', ''),
    promotion: _.get(props, 'data.promotion', ''),
    release_date: _.get(props, 'data.release_date', ''),
    previous_achievements: _.get(props, 'data.previous_achievements', ''),
    reference: _.get(props, 'data.reference', ''),
    distributor: _.get(props, 'data.distributor', ''),
    upc_code: _.get(props, 'data.upc_code', ''),
    label: _.get(props, 'data.label', ''),
    isrc_code: _.get(props, 'data.isrc_code', ''),
    user_id: _.get(props, 'data.user_id', ''),
    apple_track_id: _.get(props, 'data.apple_track_id', ''),
    extra_info: _.get(props, 'data.extra_info', ''),
    facebook_link: _.get(props, 'data.facebook_link', ''),
    instagram_link: _.get(props, 'data.instagram_link', ''),
    twitter_link: _.get(props, 'data.twitter_link', ''),
    tiktok_link: _.get(props, 'data.tiktok_link', ''),
    location_timezone: _.get(props, 'data.location_timezone', ''),
    soundcloud_link: _.get(props, 'data.soundcloud_link', ''),
    phone_number: _.get(props, 'data.phone_number', '')

  })
  // useEffect(() => {
  //   getArtistData()
  // }, [])

  const [reference, setReference] = useState([])
  const [track, setTrack] = useState()
  const [trackUrl, setTrackUrl] = useState(formData?.track_file)
  const [image, setImage] = useState()
  const [imageUrl, setImageUrl] = useState(formData?.cover_image)
  // const  [loading, setLoading] = useState(true)

  const ref = useRef(null)

  const _close = () => {
    setOpen(false)
    props.onClose()
  }

  let steps = []

  if (process.env.REACT_APP_AUTH_MODULE !== 'artist' || process.env.REACT_APP_AUTH_MODULE !== 'user') {
    steps = [
      {
        id: 'personal-details',
        title: 'Personal Details',
        subtitle: 'Add Your Personal Info',
        // icon: <FileText size={18} />,
        content: <PersonalDetails formState={{ formData, setFormData }} stepper={stepper} valErrors={valErrors} />


      },
      {
        id: 'account-details',
        title: 'Song Details',
        subtitle: 'Setup song details',
        // icon: <FileText size={18} />,
        content: <SongDetails trackState={[track, setTrack]} imageState={[image, setImage]} trackUrlState={[trackUrl, setTrackUrl]} imageUrlState={[imageUrl, setImageUrl]} isEditAction={isEditAction} formState={{ formData, setFormData }} stepper={stepper} valErrors={valErrors} />

      },
      {
        id: 'artist-info',
        title: 'Artist Details',
        subtitle: 'Add artist details',
        // icon: <FileText size={18} />
        content: <ArtistDetails isEditAction={isEditAction} form_id={props.data.id} formState={{ formData, setFormData }} refState={{ reference, setReference }} stepper={stepper} valErrors={valErrors} />

      },
      {
        id: 'extra-info',
        title: 'Extra Info',
        subtitle: 'Add extra info',
        // icon: <FileText size={18} />
        content: <ExtraInfo formState={{ formData, setFormData }} stepper={stepper} valErrors={valErrors} _close={_close} />
      },
      {
        id: 'about',
        title: 'About',
        subtitle: 'More',
        // icon: <FileText size={18} />
        content: <About trackState={[track, setTrack]} imageState={[image, setImage]} trackUrlState={[trackUrl, setTrackUrl]} imageUrlState={[imageUrl, setImageUrl]} formState={{ formData, setFormData }} refState={{ reference, setReference }} stepper={stepper} valErrors={valErrors} _close={_close} successCallback={props.successCallback} isEditAction={isEditAction} />
      }
    ]
  } else {
    steps = [
      {
        id: 'account-details',
        title: 'Song Details',
        subtitle: 'Setup song details'
        // icon: <FileText size={18} />,
      },
      {
        id: 'step-address',
        title: 'Campaign Details',
        subtitle: 'Add campaign details'
        // icon: <FileText size={18} />,
      }
    ]
  }

  return (

    <Modal
      isOpen={open}
      // toggle={_close}
      unmountOnClose={true}
      backdrop={true}
      className='modal-lg campaign-wizard'
      contentClassName='p-0'
      modalClassName=''
    >

      <ModalHeader toggle={_close} className='mb-1'>
        {isEditAction ? 'Edit Form' : 'Playlist Form Submission'}
      </ModalHeader>

      <ModalBody className='flex-grow-1 pb-sm-0 pb-3 modal-body'>

        <Wizard
          instance={el => setStepper(el)}
          ref={ref}
          steps={steps}
          type='vertical'
          contentClassName='shadow-none'
          className='bg-transparent create-app-wizard shadow-none'

        />

      </ModalBody>

      {/*<ModalFooter className='justify-content-center'>*/}

      {/*  <Button.Ripple type='submit' className='flex-grow-1' color='primary' disabled={loading}>*/}
      {/*    { loading ? <ButtonSpinner/> : null}*/}
      {/*    <span>{trans('gen.actions.save')}</span>*/}
      {/*  </Button.Ripple>*/}

      {/*  <Button.Ripple type='button' className='flex-grow-1' color='secondary' disabled={loading} onClick={_close}>*/}
      {/*    <span>{trans('gen.actions.cancel')}</span>*/}
      {/*  </Button.Ripple>*/}

      {/*</ModalFooter>*/}
    </Modal>
  )
}

export default BasicInfoWizardModal
