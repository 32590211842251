// ** React Imports
import { useState } from 'react'

// ** Third Party Components
import classnames from 'classnames'
import axios from 'axios'
import { ButtonSpinner, ErrorMessages } from '@src/components'

import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState } from 'draft-js'
import { Controller, useForm } from "react-hook-form"
import AsyncSelect from "react-select/async"
import FileUploaderMultiple from './FileUploaderMultiple'

// ** Reactstrap Imports
import { Row, Col, Card, CardBody, CardText, Form, FormFeedback, Label, Input, Button, Alert } from 'reactstrap'
import { useParams } from "react-router-dom"
import { getUserData } from "../../utility/Utils"

// ** Styles
import '@styles/react/libs/editor/editor.scss'
import '@styles/base/plugins/forms/form-quill-editor.scss'
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/base/pages/page-blog.scss'
import {
  _addTicket,
  _getAllPrioritiesWithQ,
  _getAllDepartmentsWithQ,
  _getAllCampaignsWithQ
} from "../../redux/actions"
import { InputMainLabelComponent, MainAlertComponent } from '../../../../components/CurrencyComponent'

const NewTicket = ({ data, setAddTicket }) => {
  const initialContent = `
  <p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p>
  <p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>
  `


  const _close = () => {
    setAddTicket(false)
  }
  const contentBlock = htmlToDraft(initialContent)
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  const editorState = EditorState.createWithContent(contentState)

  const { errors, handleSubmit, control, trigger, getValues, setValue, register, watch } = useForm()
  const [images, setImages] = useState(null)
  const [allow, setAllow] = useState(false)
  const [valErrors, setValErrors] = useState({})
  const [link, setLink] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userCampaigns, setUserCampaigns] = useState(null)
  const filledBy = getUserData().id
  let userId
  if (process.env.REACT_APP_AUTH_MODULE === 'user') {
    userId = useParams().userId
  } else {
    userId = filledBy
  }
  const submit = async (data, attachment) => {
    // setLoading(true)
    setAllow(!allow)
    setImages(null)
    const newAttachment = attachment.toString()
    trigger()
    if (process.env.REACT_APP_AUTH_MODULE === 'user') {
      _addTicket(
        {
          user_id: userId,
          admin_id: filledBy,
          initial_message: data.content,
          ticket_subject: data.ticket_subject,
          department_id: data.department_id.value,
          campaign_id: data?.campaign_id?.value ?? '',
          priority_id: data.priority_id.value,
          attachments: newAttachment
        },
        () => {
          //console.log(attachment)
          _close()
          setLoading(false)
          setAllow(!allow)
          successCallback()
        },
        (err) => {
        }
      )
    } else {
      _addTicket(
        {
          user_id: userId,
          admin_id: '',
          initial_message: data.content,
          ticket_subject: data.ticket_subject,
          department_id: data.department_id.value,
          campaign_id: data?.campaign_id?.value ?? '',
          priority_id: data.priority_id.value,
          attachments: newAttachment
        },
        () => {
          //console.log(attachment)
          _close()
          setLoading(false)
          setAllow(!allow)
          successCallback()
        },
        (err) => {
        }
      )
    }

  }
  const _getAllCampaigns = () => {
    return _getAllCampaignsWithQ(userId)
    //setUserCampaigns()
  }

  const uploadData = async (data) => {
    setLoading(true)
    //const [attachment, setAttachments] = useState(null)
    const attachment = []
    //console.log(images)
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}file/new-upload`
    //const url = `http://127.0.0.1:8000/api/file/new-upload`
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    if (images) {
      await Promise.all(images.map(async (file, index) => {
        const imageData = new FormData()
        imageData.append('file', file)
        imageData.append('fileName', `${file?.name}_${index}`)
        let result
        await axios.post(url, imageData, config)
          .then(function (response) {
            result = response.data.data.file
            console.log(attachment)
            attachment.push(result)
            //console.log(response.data.data.file)
          })
      }))
        .then(() => {
          submit(data, attachment)
        })
    } else {
      submit(data, attachment)
    }

  }

  return (
    <div className='blog-edit-wrapper'>
      {/* {data !== null ? ( */}
      <Row>
        <Col sm='12'>
          <Card>
            <CardBody>
              <div className='d-flex'>
                <div>
                  {/* <Avatar className='me-75' img={data?.avatar} imgWidth='38' imgHeight='38' /> */}
                </div>
                <div>
                  {/* <h6 className='mb-25'>{data?.user}</h6> */}
                  <CardText>{data?.created_at}</CardText>
                </div>
              </div>
              <Form className='mt-2' onSubmit={handleSubmit(uploadData)}>
                <Row>
                  <Col xs={12} xl={4} className='mb-2'>
                    {/* <Label className='form-label' for='ticket_subject'>
                      Ticket Subject <small className={'text-danger'}>*</small>
                    </Label> */}
                    <InputMainLabelComponent title='Ticket Subject' isRequired={true} forId='ticket_subject' />
                    <Controller
                      as={Input}
                      control={control}
                      name={'ticket_subject'}
                      id='ticket_subject'
                      invalid={errors.ticket_subject && true}
                      type='text'
                      rules={{
                        required: true
                      }}
                      className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                    />
                    {errors.ticket_subject && <FormFeedback>Please type a subject</FormFeedback>}
                  </Col>
                  {/* {!link ? (
                    <Col md='4' className='mb-0'>
                      <div className='form-check form-check-success' style={{ marginTop: '24px' }}>
                        <Input type='checkbox' id='success-checkbox' style={{ height: '15px', width: '15px' }} onClick={() => setLink(!link)} />Link Ticket to a Campaign?
                      </div>
                    </Col>
                  ) : (
                    <Col md='4' className='mb-2'>
                      <div className='form-check form-check-success'>
                        <Input type='checkbox' id='success-checkbox' defaultChecked onClick={() => setLink(!link)} />
                        Link Ticket to a Campaign?
                      </div>

                      <Controller
                        as={AsyncSelect}
                        control={control}
                        name={'campaign_id'}
                        id='campaign_id'
                        isClearable={true}
                        isMulti={false}
                        isDisabled={!link}
                        invalid={errors.campaign_id && true}
                        classNamePrefix='select'
                        defaultOptions
                        cacheOptions
                        //onChange={e => setBlogCampaign(e.target.value)}
                        loadOptions={_getAllCampaigns}
                        // className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                      />
                    </Col>

                  )} */}

                  <Col xs={12} xl={4} className='mb-2'>
                    {/* <Label className='form-label' for='department_id'>
                      Department <small className={'text-danger'}>*</small>
                    </Label> */}
                    <InputMainLabelComponent title='Department' isRequired={true} forId='department_id' />

                    <Controller
                      as={AsyncSelect}
                      control={control}
                      name={'department_id'}
                      id='department_id'
                      isClearable={false}
                      invalid={errors.department_id && true}
                      isMulti={false}
                      classNamePrefix='select'
                      defaultOptions
                      cacheOptions
                      rules={{
                        required: true
                      }}
                      //onChange={e => setDepartment(e.target.value)}
                      loadOptions={_getAllDepartmentsWithQ}
                      className={classnames('react-select', { 'is-invalid': errors['department_id'] })}                    //defaultValue={formData?.status_id ? {label: formData?.status_id.name, value: formData?.status_id.id} : ''}
                    />
                    {errors.department_id && <FormFeedback>Please select a department</FormFeedback>}
                  </Col>
                  <Col xs={12} xl={4} className='mb-2'>
                    {/* <Label className='form-label' for='priority_id'>
                      Priority <small className={'text-danger'}>*</small>
                    </Label> */}
                    <InputMainLabelComponent title='Priority' isRequired={true} forId='priority_id' />
                    <Controller
                      as={AsyncSelect}
                      control={control}
                      name={'priority_id'}
                      id='priority_id'
                      isClearable={false}
                      invalid={errors.priority_id && true}
                      isMulti={false}
                      classNamePrefix='select'
                      defaultOptions
                      //onChange={e => setPriority(e.target.value)}
                      cacheOptions
                      loadOptions={_getAllPrioritiesWithQ}
                      rules={{
                        required: true
                      }}
                      className={classnames('react-select', { 'is-invalid': errors['priority_id'] })} defaultValue={{ label: 'Low', value: 3 }}
                    />
                    {errors.priority_id && <FormFeedback>Please select a priority</FormFeedback>}
                  </Col>
                  <Col sm='12' className='mb-2' style={{ height: '20%' }} >
                    {/* <Label className='form-label' for='content'>Message <small className={'text-danger'}>*</small></Label> */}
                    <InputMainLabelComponent title='Message' isRequired={true} forId='content' />

                    <Controller
                      as={Input}
                      name='content'
                      id='content'
                      invalid={errors.content && true}
                      type="textarea"
                      control={control}
                      rules={{
                        required: true
                      }}
                      className={classnames({ 'is-invalid': errors['name'] || _.get(valErrors, 'name') })}
                    //onChange={([ event ]) => event.target.value}
                    />
                    {errors.content && <FormFeedback>Please enter a message</FormFeedback>}
                  </Col>
                  <Col className='mb-2' sm='12'>
                    <div className='border rounded p-2' style={{ cursor: 'pointer' }}>
                      <h4 className='mb-1'>Add Attachment(s)</h4>
                      <FileUploaderMultiple setImages={setImages} />
                    </div>
                  </Col>
                  {process.env.REACT_APP_AUTH_MODULE === 'user' ? (
                    <Col className='mt-50'>
                      <Button color='primary' type='submit' className='me-1' disabled={loading}>
                        {loading ? (<ButtonSpinner />) : null}
                        {loading ? 'Adding Ticket' : 'Add Message'}
                      </Button>
                      <Button color='secondary' className={'ml-1 btn-small-width-system'} onClick={_close} outline>
                        Cancel
                      </Button>
                    </Col>
                  ) : (
                    <>
                      <Col sm='12'>
                        <MainAlertComponent
                          title={`Attention!`}
                          desc={`To add a message, please ensure that you checked the checkbox located below.`}
                          type={'warning'}

                          alert={<Alert color='warning'>
                            <h4 className='alert-heading'>Attention!</h4>
                            <div className='alert-body'><span>
                              To add a message, please ensure that you checked the checkbox located below.
                            </span></div>
                          </Alert>} />

                        <div className='form-check form-check-success'>
                          <Input type='checkbox' id='agree-checkbox' onClick={() => setAllow(!allow)} />
                          <Label className='form-check-success' for='agree-checkbox'>
                            I authorize Liberty Music PR to change any passwords necessary to troubleshoot this issue
                          </Label>
                        </div>
                      </Col>
                      <Col className='mt-50'>
                        <Button color='primary' type='submit' className='me-1 btn-small-width-system' disabled={!allow}>
                          {loading ? (<ButtonSpinner />) : null}
                          {loading ? 'Adding Ticket' : 'Add Message'}
                        </Button>
                        <Button color='secondary' className={'ml-1 btn-small-width-system'} onClick={_close} outline>
                          Cancel
                        </Button>
                      </Col>
                    </>
                  )}

                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* ) : null} */}
    </div>
  )
}

export default NewTicket