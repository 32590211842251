import React from "react"
import { List, Feather, User, Users, Briefcase, Send } from "react-feather"
import { Icon } from '@iconify/react'

import { navTrans } from '@utils'


export const Navigation = [
    {
        header: 'CRM Tools',
        action: 'call',
        resource: 'general'
    },
    {
        id: 'crm',
        title: 'CRM',
        icon: <Icon icon="solar:users-group-two-rounded-linear" width={20} height={20}/>,
        children: [
            {
                id: 'crm-leads',
                title: 'Leads',
                icon: <Icon icon="tabler:user-dollar" width={20} height={20}/>,
                navLink: '/crm/artists-list',
                action: 'call',
                resource: 'view.main.crm.leads.tab.list'
            },
            {
                id: 'crm-campaign',
                title: 'Marketing Campaign',
                icon: <Icon icon="hugeicons:mail-open-love" width={20} height={20}/>,
                navLink: '/marketing-campaigns',
                action: 'call',
                resource: 'view.main.crm.marketing.tab.list'
            },
            // {
            //     id: 'crm-artists',
            //     title: 'Artists List',
            //     icon: <Users />,
            //     navLink: '/crm/artists-list'
            // },
            {
                id: 'crm-list',
                title: 'Leads Lists',
                icon: <Icon icon="solar:user-id-outline" width={20} height={20}/>,
                navLink: '/crm/leads-lists',
                action: 'call',
                resource: 'view.main.crm.leads.list.tab.list'
            },
            {
                id: 'crm-templates',
                title: 'Templates Design',
                icon: <Icon icon="solar:gallery-wide-outline" width={20} height={20}/>,
                navLink: '/crm/template-design',
                action: 'call',
                resource: 'view.main.crm.templates.tab.list'
            },
            {
                id: 'crm-senders',
                title: 'Senders ID',
                icon: <Icon icon="solar:user-speak-rounded-outline" width={20} height={20}/>,
                navLink: '/crm/senders-id',
                action: 'call',
                resource: 'view.main.crm.senders.tab.list'
            }
        ]
    },
    {
        header: 'Tickets & Support Center',
        action: 'call',
        resource: 'general'
    }

]