import {lazy} from "react"

export const Routes = [

	{
		path: "/tickets/list",
		component: lazy(() => import("../views/tickets")),
		meta: {
			action: "call",
			resource: "view.main.tickets.tab.list"
		}
	},
	{
		path: "/tickets/new-ticket/:userId",
		component: lazy(() => import("../views/tickets/NewTicket.js")),
		meta: {
			action: "call",
			resource: "ROLES_VIEW_LIST"
		}
	},
	{
		path: "/tickets/details/:ticketId",
		component: lazy(() => import("../views/tickets/TicketDetails.js")),
		meta: {
			action: "call",
			resource: "tickets.show"
		}
	},
	{
		path: "/tickets/departments",
		component: lazy(() => import("../views/departments/DepartmentsCard.js")),
		meta: {
			action: "call",
			resource: "view.main.departments.tab.list"
		}
	}
]
