import {lazy} from "react"

export const Routes = [

	{
		path: "/crm/leads-lists",
		component: lazy(() => import("../views/crm-list")),
		meta: {
			action: "call",
			resource: "view.main.crm.leads.list.tab.list"
		}
	},
	{
		path: "/crm/senders-id",
		component: lazy(() => import("../views/senders")),
		meta: {
			action: "call",
			resource: "view.main.crm.senders.tab.list"
		}
	},
	{
		path: "/crm/template-design/editor",
		component: lazy(() => import("../views/template-design/TemplateForm")),
    meta: {
		action: "call",
		resource: "crm.templates.add" 
		  }
    },
	{
		path: "/crm/template-design/editor/edit",
		component: lazy(() => import("../views/template-design/EditTemplateForm")),
    meta: {
		action: "call",
		resource: "crm.templates.edit"
		  }
    },
    {
        path: "/crm/artists-list",
        component: lazy(() => import("../views/artists-list")),
        meta: {
			action: "call",
			resource: "view.main.crm.leads.tab.list"
        }
    },
	{
		path: "/crm/senders",
		component: lazy(() => import("../views/senders")),
		meta: {
			action: "call",
			resource: "view.main.crm.tab.list"
		}
	},
	{
		path: "/crm/template-design",
		component: lazy(() => import("../views/template-design")),
    meta: {
		action: "call",
		resource: "view.main.crm.templates.tab.list"
		}
    },
    {
		path: "/marketing-campaigns",
		component: lazy(() => import("../views/marketing-campaign")),
		meta: {
			action: "call",
			resource: "view.main.crm.marketing.tab.list"
		}
	},
    {
        path: "/crm/artists-list",
        component: lazy(() => import("../views/artists-list")),
        meta: {
            action: "call",
			resource: "view.main.crm.tab.list"
        }
    },
    {
        path: "/marketing-campaigns",
        component: lazy(() => import("../views/marketing-campaign")),
        meta: {
			action: "call",
			resource: "view.main.crm.marketing.tab.list"
        }
    },
	{
        path: "/artist/profile/:artistId",
        component: lazy(() => import("../views/artist-profile")),
        meta: {
			action: "call",
			resource: "crm.leads.show"
        }
    },
	{
        path: "/marketing-campaign/:campaignId",
        component: lazy(() => import("../views/marketing-campaign/CampaignView")),
        meta: {
			action: "call",
			resource: "crm.marketing.show"
        }
    }
]
