import React, { Fragment, Component, memo } from 'react'
import { connect } from 'react-redux'
import {FileText, MoreVertical, Send, Plus, Key, User, Trash, Eye} from 'react-feather'
import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Badge
} from 'reactstrap'
import {Link} from "react-router-dom"
import CustomAvatar from "@fwsrc/components/CustomAvatar"
import { convertUnicodeToSymbols } from '../../../core/utility/Utils'

//************************************//
import Breadcrumbs from '@src/components/breadcrumbs'
import DataTable from '@src/components/datatable'
import {trans, _confirm} from '@utils'
import {AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import Avatar from '@components/avatar'
import {_getAllRolesWithQ} from '@modules/rolespermissions'
import {CanCall} from '@authModule'
//************************************//
import {_deleteGenre} from '../../redux/actions'
//************************************//
const tableColumns = (state, _editBasicInfoModal, _deleteUser, hasAction) => [
    {
      name: 'ID',
      selector: 'id',
      sortable: false,
      omit: true,
      filter: {
        enabled: false
      }
    },
    {
      name: trans('user.name'),
      selector: 'playlist_info.playlist_name',
      minWidth: '200px',
      sortable: false,
      cell: (row, index, column, id) => {
        return (
            <div className='d-flex align-items-center'>
            <CustomAvatar  content={`${convertUnicodeToSymbols(row.name)}`}/>
                  {/* <img  className="rounded-image-playlist small-image-playlist" src={row.playlist_info?.playlist_image} alt={row.playlist_info?.playlist_name} /> */}
  
              <div className='user-info text-truncate ml-1'>
                <span className='d-block font-weight-bold text-truncate text-wrap'>{convertUnicodeToSymbols(row.name)}</span>
              </div>
            </div>
        )
      }
    },
    // {
    //   name: 'Avg Popularity',
    //   selector: 'playlist_info.playlist_avg_popularity',
    //   width: '10%',
    //   sortable: false,
    //   cell: (row, index, column, id) => {
    //     return (
    //         <div className='d-flex align-items-center'>
              
    //             <span className='d-block font-weight-bold text-truncate'>{row.playlist_info?.playlist_avg_popularity}%</span>
              
    //         </div>
    //     )
    //   }
    // },
    // {
    //   name: 'Followers',
    //   selector: 'playlist_info.total_followers',
    //   width: '10%',
    //   sortable: false,
    //   cell: (row, index, column, id) => {
    //     return (
    //         <div className='d-flex align-items-center'>
              
    //             <span className='d-block font-weight-bold text-truncate'>{formatAmount(row.playlist_info?.total_followers)}</span>
              
    //         </div>
    //     )
    //   }
    // },
    {
      name: 'Tier',
      selector: 'tier.tier_name',
      minWidth: '100px',
      sortable: false
    },
    {
      name: 'Duration',
      selector: 'duration.duration',
      width: '12%',
      sortable: false,
      cell: (row) => {
        return <p>{row.duration?.duration} Days</p>
    }
    },
    {
      name: 'Created By',
      selector: 'by_admin.name',
      width: '20%',
      sortable: false,
      cell: (row) => {
        return <p>{row.by_curator === null ? row.by_admin.name : row.by_curator.name}</p>
    }
    },
    {
      name: 'Status',
      selector: 'status.status',
      width: '12%',
      sortable: false,
      cell: (row) => {
        return <Badge 
        color={
                      (() => {
                        switch (row.status_id) {
                          case 1:
                            return 'success'
                          case 2:
                            return 'warning'
                          case 3:
                            return 'danger'
                          default:
                            return 'secondary'
                        }
                      })()
                    }
        >{row.status?.status}</Badge>
    }
    },
    {
      name: trans('gen.actions.actions'),
      allowOverflow: true,
      // omit: !hasAction,
      width: '8%',
      cell: (row, index, column, id) => {
        return (
          <div className='d-flex'>
            <UncontrolledDropdown>
              <DropdownToggle className='pr-1' tag='span'>
                <MoreVertical size={15} />
              </DropdownToggle>
              <DropdownMenu right>
              <CanCall action='express.playlist.show'>
                  <Link to={`/express/playlist/view/${row.id}`} className='w-100 dropdown-item'>
                    <Eye size={15}/>
                    <span className='align-middle ml-50'>View</span>
                  </Link>
                </CanCall>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      }
    }
  ]
const tableActions = ['NoPermissionCode']
//************************************//
class ArtistLastFiveInvoicesList extends Component {
  static contextType = AbilityContext
  constructor(props) {
    super(props)
    this.state = {
      userId: props.userId,
      basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}
    }
    this._editBasicInfoModal = this._editBasicInfoModal.bind(this)
    this._deleteUser = this._deleteUser.bind(this)
  }
  //************************************//
   _closeBasicInfoModal = () => {
    this.setState({basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}})
  }
  //************************************//
  _openBasicInfoModal = () => {
    this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: {}}})
  }
  //************************************//
  _editBasicInfoModal = (data) => {
    this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: data}})
  }
  //************************************//
  _deleteUser = (id) => {
    _confirm({
      callback: (c) => {
        _deleteGenre(id, () => {
          this.dataTableRef._refresh()
          c()
        })
      }
    })
  }
  //************************************//
  render () {
    const hasAction = _hasAnyAbility(this.context, tableActions)
    return (
      <Fragment>
        <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'Express Playlists'} breadCrumbParent='' breadCrumbActive='' ></Breadcrumbs>
        <Row>
          <Col sm='12'>
            <DataTable
                ref={(ref) => { this.dataTableRef = ref }}
                uri={`express/users/all/playlists/${this.props.curator.id}`}
                columns={tableColumns(this.state, hasAction)}
                hasIndexing={false}
                hasFilter={false}
                defaultCollapse={true}
                showAll={false}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }
}
//************************************//
const mapStateToProps = store => ({
  loading: store.app.loading,
  userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`)
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(ArtistLastFiveInvoicesList)
