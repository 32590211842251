import React from "react"
import { Bell, Briefcase, Shield, Lock, Tool, Settings, Music, Users, CheckCircle, Codesandbox } from "react-feather"
import { trans } from '@utils'
import {Message} from "@material-ui/icons"
import { Icon } from '@iconify/react'

export const Navigation = [
    {
        header: 'Settings',
        action: 'call',
		resource: 'general'
    },
    {
        id: 'roles',
        title: 'Admins & Roles',
        icon: <Icon icon="fluent:lock-shield-48-regular" width={20} height={20}/>,
        children: [
            {
                id: 'adminList',
                title: 'Admins',
                icon: <Icon icon="fluent:person-lock-16-regular" width={20} height={20}/>,
                navLink: '/admin/list',
                action: 'call',
                resource: 'view.main.admins.tab.list'
            },
            {
                id: 'Roles&Permissions',
                title: 'Roles & Permissions',
                icon: <Icon icon="hugeicons:security-lock" width={20} height={20}/>,
                navLink: '/roles/list',
                action: 'call',
                resource: 'view.main.roles.tab.list'
            },
            {
                id: 'log',
                title: 'Audit Logs',
                icon: <Icon icon="fluent:document-lock-48-regular" width={20} height={20}/>,
                navLink: '/logs',
                action: 'call',
                resource: 'view.main.logs.tab.list'
            }
           
            // {
            //     id: '3',
            //     title: 'User Types',
            //     icon: <User />,
            //     navLink: '/userTypes/list'
            // },

        ]
    },
    {
    
        title: 'Settings',
        icon: <Icon icon="fluent:content-settings-32-regular" width={20} height={20}/>,
        navLink: '/system/settings',
        action: 'call',
        resource: 'view.main.genres.tab.list'
    }
    // {
    //     id: 'settings',
    //     title: 'General Settings',
    //     icon: <Settings size={20} />,
    //     children: [
    //         {
    //             id: '1',
    //             title: 'Genre List',
    //             icon: <Music />,
    //             navLink: '/genres/list',
    //             action: 'call',
    //             resource: 'view.main.genres.tab.list'
    //         },
    //         // {
    //         //     id: '3',
    //         //     title: 'User Types',
    //         //     icon: <User />,
    //         //     navLink: '/userTypes/list'
    //         // },
    //         {
    //             id: '2',
    //             title: 'Interests List',
    //             icon: <CheckCircle />,
    //             navLink: '/interests/list',
    //             action: 'call',
    //             resource: 'view.main.interests.tab.list'
    //         },
    //         {
    //             id: '4',
    //             title: 'Custom Tiers List',
    //             icon: <Codesandbox />,
    //             navLink: '/tiers/list',
    //             action: 'call',
    //             resource: 'view.main.tiers.tab.list'
    //         },
    //         {
    //             id: '5',
    //             title: 'Spotify Express Tiers',
    //             icon: <Codesandbox />,
    //             navLink: '/express/tiers/list',
    //             action: 'call',
    //             resource: 'view.main.express.tiers.tab.list'
    //         },
    //         {
    //             id: '7',
    //             title: 'TikTok Express Tiers',
    //             icon: <Codesandbox />,
    //             navLink: '/tiktok/express/tiers/list',
    //             action: 'call',
    //             resource: 'view.main.express.tiers.tab.list'
    //         },
    //         {
    //             id: '5',
    //             title: 'Spotify Express Durations',
    //             icon: <Codesandbox />,
    //             navLink: '/express/durations/list',
    //             action: 'call',
    //             resource: 'view.main.express.durations.tab.list'
    //         },
    //         {
    //             id: '6',
    //             title: 'Tiktok Express Durations',
    //             icon: <Codesandbox />,
    //             navLink: '/tiktok/express/durations/list',
    //             action: 'call',
    //             resource: 'view.main.express.durations.tab.list'
    //         }
    //         // {
    //         //     id: '7',
    //         //     title: 'Coin Details',
    //         //     icon: <Codesandbox />,
    //         //     navLink: '/wallet/coins/details/list',
    //         //     action: 'call',
    //         //     resource: 'view.main.express.durations.tab.list'
    //         // },
   // {
    //         //     id: 'horizon',
    //         //     title: 'Queue Monitor',
    //         //     icon: <Codesandbox />, // Replace FacebookIcon with the appropriate Facebook icon component
    //         //     navLink: '/horizon', // Replace 'https://www.facebook.com' with the desired Facebook URL
    //         //     external: true, // Set external property to true for external URLs
    //         //     action: 'call',
    //         //     resource: 'view.main.queue.horizon.tab.list'
    //         //   }
    //     ]
    // },
    // {
    //     id: 'scrapingJops',
    //     title: 'user.nav.scrapingJobs',
    //     icon: <Play/>,
    //     navLink: '/scraping-jobs/list',
    //     action: 'call',
    //     resource: 'SCRAPING_JOBS_VIEW_LIST'
    // }
    // {
	// 	id: '10',
	// 	title: 'Notifications',
	// 	icon: <Icon icon="hugeicons:notification-square" width={20} height={20}/>,
	// 	navLink: '/notifications',
	// 	action: 'call',
	// 	resource: 'view.main.notifications.tab.list'
	// }
]
