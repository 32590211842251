/* eslint-disable prefer-template */
import React from "react"

export default function formatSocialsNumber(number) {
    // Parse the number to ensure it's numeric
    const parsedNumber = parseFloat(number)
    console.log('parsedNumber:', parsedNumber) // Log the parsed number
    
    // Check if parsing was successful and it's a valid number
    if (!isNaN(parsedNumber) && isFinite(parsedNumber)) {
        // Use the parsedNumber for comparisons and formatting
        if (parsedNumber >= 1000000000) {
            return (parsedNumber / 1000000000).toFixed(1) + 'B'
        }
        if (parsedNumber >= 1000000) {
            return (parsedNumber / 1000000).toFixed(1) + 'M'
        }
        if (parsedNumber >= 1000) {
            return (parsedNumber / 1000).toFixed(2) + 'K' // Keep two decimal places for thousands
        }
        return parsedNumber.toFixed(2) // If it's less than 1000, return it with 2 decimal places
    } 

    // Return an empty string if the number is not valid
    return ''
}