import * as yup from 'yup'
import React, {Fragment, useEffect, useState} from 'react'
import classnames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { ArrowLeft, ArrowRight } from 'react-feather'
import {Form, Label, Input, FormGroup, Row, Col, Button, Container, CustomInput} from 'reactstrap'
import _ from "lodash"
import AsyncSelect from "react-select/async"
import Flatpickr from "react-flatpickr"
import moment from "moment/moment"
import {useSelector} from "react-redux"
import {useHistory} from "react-router-dom"

import {trans} from '@utils'
import {ButtonSpinner, ErrorMessages} from '@src/components'

import {_checkUriUrl, _getAllGenresWithQ, _getAllLanguagesWithQ, _checkTiktokMusic, _checkSpotifyUri} from "../../../redux/actions"
import SelectCardAppComponent from '../../../../../components/SelectCardAppComponent'
import { InputMainLabelComponent } from '../../../../../components/CurrencyComponent'
const SongDetails = ({ stepper, type, formState }) => {
  const { register, errors, handleSubmit, trigger, control, setValue, watch, getValues, setError, clearErrors } = useForm()
  const  {campaignType, formData, setFormData} = formState
  const loading = useSelector(state => state.app.loading)
  const [valErrors, setValErrors] = useState({})
  const [released, setReleased] = useState(formData?.is_released)
  const [linkError, setLinkError] = useState(false)
  const [scrapeMsg, setScrapeMsg] = useState('')
  const values = watch()
  const history = useHistory()

  function logErrorToConsole(errorsss, customMessage = null) {
    console.error('Error:', customMessage || errorsss.message || errorsss)
  }
  const onSubmit = (data) => {
    // console.log(`values ${JSON.stringify(values)}`)
    console.log('error')
    trigger()
    if (_.isEmpty(errors)) {
      setFormData(prevState => {
        if (data.released_date) { 
          if (typeof data.released_date === 'string') {
            data.released_date = data.released_date
          } else if (moment(data.released_date[0]).format('Y-MM-DD') !== 'Invalid date') {
            data.released_date = moment(data.released_date[0]).format('Y-MM-DD')
          } else {
            data.released_date = ''
          }
        }
       
        return {
        ...prevState,
        ...data,
            language_id: data.language_id.value,
            is_released: released,
            released_date:data.released_date,
            genre_ids: _.map(data.genre_ids, x => x.value)
        }
      })
      stepper.next()
    }
  }
  function changeState(isreleased) {

    setFormData(prevState => (
      {
        ...prevState,
        media_link:'',
        released_date:'',
        link:'',
        name:'',
        language_id: [],
        genre_ids: []
      }
    ))
    setValue('media_link', '')
    setValue('released_date', '')
    setValue('link', '')
    setValue('name', '')
    setValue('language_id', '')
    setValue('genre_ids', '')

if (isreleased === 1) {
  setReleased(1)

} else {
  setReleased(0)
}

  }

  const minDate = new Date()
// add a day
  minDate.setDate(minDate.getDate() - 1)
  const today = new Date()
    today.setHours(0, 0, 0, 0)
  return (
    <Fragment>
      <div className='content-header'>
        <h5 className='mb-0'>Song Details</h5>
        <small className='text-muted'>Enter Your Song Details.</small>
      </div>
      <Form enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
        {/* <Container> */}
          <Row>
            <Col xs={12}>
              <FormGroup>
                {/* <Label className='form-label'>
                  Song released?
                </Label> */}
                <InputMainLabelComponent title=' Song released?' isRequired={false} />

              </FormGroup>
            </Col>
          </Row>
          <Row className={'mb-1'}>
            <Col xs={campaignType === 1 ? 6 : 12}>
            <>
            <SelectCardAppComponent
             key={`${released}-tiers`}
              title= {'Yes, Released'}  
              isSelected={released}
              handleMouseDown={() => changeState(1)}
               />
            </>
              {/* <Button block outline onClick={() => changeState(1)} style={{backgroundColor: released ? 'rgba(115, 103, 240, 0.12)' : 'transparent'}}>
                Yes, Released
              </Button> */}
            </Col>
            <Col xs={6}>
            {/* <Button block outline onClick={() => changeState(0)} style={{backgroundColor: !released ? 'rgba(115, 103, 240, 0.12)' : 'transparent'}}>
                No, Not yet
              </Button> */}
            {campaignType === 1 ? 
              <SelectCardAppComponent
             key={`${released}-tiers`}
              title= {' No, Not yet'}  
              isSelected={!released}
              handleMouseDown={() => changeState(0)}
               />
              : null }
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                {/* <Label className='form-label' for={`link`}>
                  {campaignType === 1 ? 'Spotify' : 'Campaign'} Link {released ? 'URL' : 'URI'}* 
                </Label> */}
                <InputMainLabelComponent title={`${campaignType === 1 ? 'Spotify' : 'Campaign'} Link ${released ? 'URL' : 'URI'}`} isRequired={true} forId='link'  />
                <Input
                    innerRef={register({required: true, validate: val => !linkError || 'Link Is Wrong '})}
                    name={`link`}
                    id={`link`}
                    defaultValue={formData?.link}
                    // valid={!linkError}
                    // invalid={linkError}
                  onFocus={() => setLinkError(false)}
                    className={classnames({ 'is-invalid': errors[`link`] || linkError, 'is-valid': !errors[`link`] && !linkError && (getValues('link') && getValues('link')?.length > 0) })}
                    onChange={e => {
                      if (e.target.value !== null) {
                      // setValue('name', 'Test')
                      // setValue('released_date', '2023-09-23 14:32:57')
                      setLinkError(false)
                      // if (campaignType === 1) {
                      if (campaignType === 1 && released) {
                        _checkUriUrl(
                            e.target.value,
                            (data) => {
                              if (data.code === 1 && data.other && data.other?.album) {
                                setValue('name', data.other.name)
                                setValue('released_date', data.other.album.release_date)
                                const link = e.target.value
                                if (link.indexOf('?') > -1) {
                                  setValue('link', link.slice(0, link.indexOf('?')))
                                }
                                setLinkError(false)
                                setScrapeMsg("")
                                trigger()
                              } else {
                                setValue('name', '')
                                setValue('released_date', '')
                                setLinkError(true)
                                setScrapeMsg('Not A valid Track')
                                trigger()
                              }
                            }
                        )
                      } else if (campaignType === 2) {
                        _checkTiktokMusic(
                          e.target.value,
                          (data) => {
                            if (data.code === 1) {
                              if (data?.data.title === undefined || data?.data?.image_url === 0 || data.code === 2) {
                                setScrapeMsg('Invalid Music URL')
                                return
                              }
                              setValue('tiktok_img', data.data.image_url)
                              setValue('name', data.data.title)
                              console.log(`img url ${data.data.image_url}`)
                             
                                // setValue('released_date', data.other.album.release_date)
                                const link = e.target.value
                                if (link.indexOf('?') > -1) {
                                  setValue('link', link.slice(0, link.indexOf('?')))
                                }
                                setLinkError(false)
                                setScrapeMsg("")
                                trigger()
                              console.log(JSON.stringify(data))
                            } else {
                              setValue('name', '')
                                setValue('released_date', '')
                                setLinkError(true)
                                setScrapeMsg('Not a valid Track')
                                trigger()
                            }
                          },
                      (error) => {
                        setScrapeMsg('')
                      }
                      )
                        setLinkError(false)
                      } else if (campaignType === 1 && !released) {
                        
                        _checkSpotifyUri(
                          e.target.value,
                          (data) => {
                            if (data.code === 1) {
                              if (data.code === 2) {
                                setScrapeMsg('Not a valid Uri')
                                return
                              }
                              // setValue('tiktok_img', data.data.image_url)
                              setValue('name', '')
                              
                             
                                // setValue('released_date', data.other.album.release_date)
                                const link = e.target.value
                                if (link.indexOf('?') > -1) {
                                  setValue('link', link.slice(0, link.indexOf('?')))
                                }
                                setLinkError(false)
                                setScrapeMsg("")
                                trigger()
                              console.log(JSON.stringify(data))
                            } else {
                              setValue('name', '')
                                setValue('released_date', '')
                                setLinkError(true)
                                setScrapeMsg('Not a valid Uri')
                                trigger()
                            }
                          },
                      (error) => {
                        setScrapeMsg('')
                      }
                      )
                        setLinkError(false)
                      }
                    }
                    }}
                />
                <ErrorMessages valErrors={valErrors} errors={errors} name={'link'}/>
                {/* <ErrorMessages errors={errors} name={'link'} /> */}
                <small className={'text-danger'}>{scrapeMsg}</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                {/* <Label className='form-label' for={`name`}>
                  {campaignType === 1 ? <span>Song Name {!released && <small className={'text-danger'}>*</small>}</span> : <span>Song Name <small className={'text-danger'}>*</small></span>}
                </Label> */}
                <InputMainLabelComponent title='Song Name' isRequired={!released} forId='name'  />
                <Controller
                    control={control}
                    readOnly={(campaignType === 1  && released) || (campaignType === 2  && released)}
                    as={Input}
                    name={`name`}
                    id={`name`}
                    defaultValue={formData?.name}
                    //rules={{ required: !released }}
                    rules={{ required: true }}
                    className={classnames({ 'is-invalid': errors[`name`] })}
                />
                 <ErrorMessages valErrors={valErrors} errors={errors} name={'name'}/>
                 <Controller
                    control={control}
                    readOnly={(campaignType === 1  && released) || (campaignType === 2  && released)}
                    type={'hidden'}
                    as={Input}
                    name={`tiktok_img`}
                    id={`tiktok_img`}
                    defaultValue={formData?.tiktok_img}
                    rules={{ required: !released && campaignType === 2 }}
                    className={classnames({ 'is-invalid': errors[`tiktok_img`] })}
                />
              </FormGroup>
            </Col>
            <Col xs={6} style={{zIndex:99}}>
              <FormGroup>
                {/* <Label className='form-label' for={`language_id`}>
                  Song Language*
                </Label> */}
                <InputMainLabelComponent title='Song Language' isRequired={true} forId='language_id'  />
                <Controller
                    as={AsyncSelect}
                    control={control}
                    name={'language_id'}
                    isClearable={false}
                    isMulti={false}
                    classNamePrefix='select'
                    defaultOptions
                    cacheOptions
                    loadOptions={_getAllLanguagesWithQ}
                    className={classnames('react-select', { 'is-invalid': errors['language_id']})}
                    defaultValue={formData?.language_id}
                    rules={{
                      required: trans('user.validation.required')
                    }}
                />
              </FormGroup>
            </Col>
          </Row>
          {
              !released && (
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        {/* <Label className='form-label' for={`media_link`}>
                          Upload your song file*
                        </Label> */}
                        <InputMainLabelComponent title=' Upload your song file*' isRequired={true} forId='media_link'  />
                        <CustomInput
                            type='file'
                            id='media_link'
                            name='media_link'
                            accept=".mp3,.mp4"
                            innerRef={register({ required: !released && !formData.media_link })}
                        />
                        <Label className={classnames('form-label', {'text-danger': errors[`media_link`]})} for={`media_link`}>
                          Please upload an mp3/mp4 file with 50Mb maximum {formData.media_link && `(If you don't want to change file then leave it)`}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
              )
          }
          <Row>
            <Col xs={12}>
             {/* <Label className='form-label' for={`released_date`}>
                  Released Date <small className={'text-danger'}>*</small>
                </Label> */}
              <FormGroup>
              {campaignType === 1 ? 
                <InputMainLabelComponent title=' Released Date' isRequired={true} forId='released_date'  />
                    
               : null}
                {
                    campaignType === 1 ? (
                        released ? (
                            <Controller
                                key={`released_date${released}`}
                                id={'released_date'}
                                as={Input}
                                control={control}
                                readOnly={true}
                                name={'released_date'}
                                className={classnames('form-control', { 'is-invalid': errors[`released_date`] })}
                                defaultValue={formData.released_date}
                                rules={{ required: true }}
                            />
                        ) : (
                            <Controller
                                key={`released_date${released}`}
                                id={'released_date'}
                                as={Flatpickr}
                                control={control}
                                name={'released_date'}
                                className={classnames('form-control', { 'is-invalid': errors[`released_date`] })}
                                defaultValue={formData.released_date}
                                options={{
                                  dateFormat: 'Y-m-d',
                                    minDate: today
                                }}
                                rules={{ required: true }}
                            />
                        )
                    ) : null
                }
              </FormGroup>
            </Col>
          </Row>
          {campaignType === 1 && (
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    {/* <Label className='form-label' for={`genre_ids`}>
                      Genres <small className={'text-danger'}>*</small>
                    </Label> */}
                    <InputMainLabelComponent title='Genre(s)' isRequired={true} forId='genre_ids'  />
                    <Controller
                        as={AsyncSelect}
                        control={control}
                        name={'genre_ids'}
                        isClearable={false}
                        isMulti={true}
                        defaultOptions
                        cacheOptions
                        loadOptions={_getAllGenresWithQ}
                        classNamePrefix='select'
                        className={classnames('react-select', { 'is-invalid': errors['genre_ids']})}
                        defaultValue={formData.genre_ids}
                        rules={{
                          required: campaignType === 1
                        }}
                        // styles={{
                        //   zIndex:1,
                        //   option: (styles, { isFocused, isSelected }) => ({
                        //     ...styles,
                        //     backgroundColor: isSelected ? 'primary' : null,
                        //     color: isSelected ? 'white' : null
                        //   }),
                        //   multiValue: (styles, { data }) => ({
                        //     ...styles,
                        //     backgroundColor: '#006dbd',
                        //     color: 'white'
                        //   }),
                        //   multiValueLabel: (styles, { data }) => ({
                        //     ...styles,
                        //     backgroundColor: '#006dbd',
                        //     color: 'white'
                        //   }),
                        //   multiValueRemove: (styles, { data }) => ({
                        //     ...styles,
                        //     backgroundColor: '#006dbd'
                        //   })
                        // }}
                        noOptionsMessage={({ inputValue }) => {
                        console.log(inputValue) // Debugging: Check the structure of inputValue
                        return inputValue
                          ? `No genres found for "${inputValue}", please try another search term`
                          : "Please type here to start search"
                      }} // Correct use of the arrow function and ternary operator
                    />
                  </FormGroup>
                </Col>
              </Row>
          )}
        {/* </Container> */}
        <div className='d-flex justify-content-between'>
        {process.env.REACT_APP_AUTH_MODULE === 'artist' || process.env.REACT_APP_AUTH_MODULE === 'user' ? <Button.Ripple outline color='primary' className='btn-next btn-small-width-system' outlined onClick={() => {
          if (process.env.REACT_APP_AUTH_MODULE === 'artist') {
            history.push('/spotify/platform')
          } else {
            stepper.previous()
          }
         
          }}>
            <ArrowLeft size={14} className='align-middle mr-sm-25 mr-0'></ArrowLeft>
            <span className='align-middle d-sm-inline-block d-none'>Previous</span>
          </Button.Ripple> : <div></div>}
          
          <Button.Ripple type='submit' color='primary' className='btn-next align-content-end btn-small-width-system' disabled={loading}>
            { loading ? <ButtonSpinner/> : null}
            <span className='align-middle d-sm-inline-block d-none'>Next</span>
            <ArrowRight size={14} className='align-middle ml-sm-25 ml-0'></ArrowRight>
          </Button.Ripple>
        </div>
      </Form>
    </Fragment>
  )
}

export default SongDetails
