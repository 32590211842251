import React from 'react'
import { Card, CardBody, CustomInput } from 'reactstrap'
import themeConfig from '@configs/themeConfig'
import { useSkin } from '@hooks/useSkin'
import { Icon } from '@iconify/react'
import StatsAppCardCampaignComponent from './StatsAppCardCampaignComponent'
import formatSocialsNumber from './SocialFormatter'
import numberFormatIntegerComponent from './NumberFormatIntegerComponent'
const SelectCardAppComponent = ({ title, name, desc = null, desc1 = null, isSelected = 0, isDisabled = 0, handleMouseDown, stats = false, followers, duration, totalCoins }) => {
  const [skin, setSkin] = useSkin()
  return (
    <div
      onMouseDown={handleMouseDown}
      className={`custom-card ${isSelected ? 'selected' : ''}`}
      style={{
        marginBottom: '15px',
        cursor: isDisabled === 1 ? 'not-allowed' : 'pointer',
        opacity: isDisabled === 1 ? 0.8 : 1,
        pointerEvents: isDisabled === 1 ? 'none' : 'auto',
        boxShadow: '0 0px 0px 0 rgba(34, 41, 47, 0.1)',
        border: isSelected ? '1px solid #fff' : '0px solid #ccc', // Border for the card
        borderRadius: '8px', // Rounded corners
        padding: '16px', // Padding inside the card
        backgroundColor: skin === 'dark' ? isDisabled ? '#201f1f' : '#383838' : 'white',
        transition: 'box-shadow 0.3s, transform 0.3s'
        // Light background for selected state
      }}
    >
      <div style={{ cursor: isDisabled === 1 ? 'not-allowed' : 'pointer' }}>
        <div style={{ width: '100%' }}>
          <label style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
                <CustomInput
                  type='radio'
                  name={name}
                  id='payment-card'
                  checked={isSelected}
                  disabled={isDisabled === 1}
                  style={{ cursor: isDisabled === 1 ? 'not-allowed' : 'pointer' }}
                />

                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                  <p className='inter-bold-font' style={{ margin: 0, fontSize: '14px' }} >{title}</p>
                  {desc && <p style={{ margin: 0, fontSize: '12px' }} >{desc}</p>}
                  {desc1 && <p style={{ margin: 0, fontSize: '12px', color: isDisabled ? '#ff6b6b' : 'white' }} >{isDisabled ? <Icon icon="jam:alert" color='#ff6b6b' width={18} /> : null} {desc1}</p>}
                </div>
                
              </div>
               
            </div>
           
          </label>
         {stats ? <div className='d-flex justify-content-around'>
         
                  <StatsAppCardCampaignComponent 
                title="Min Followers"
                isDisabled={isDisabled}
                  value={numberFormatIntegerComponent(followers)}
                  icon='mynaui:heart-square' />
                  <StatsAppCardCampaignComponent 
                title="Total"
                  value={formatSocialsNumber(totalCoins)}
                  icon='mynaui:heart-square' coin={true} />
                  <StatsAppCardCampaignComponent 
                title="Duration"
                value={duration}
                 icon='hugeicons:time-schedule'
                 symbol='Days'

                  />
         </div> : null} 
        </div>
      </div>
    </div>
  )
}

export default SelectCardAppComponent
