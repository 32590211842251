const PluggedInModules = {}

// page not found without it
// try {
    PluggedInModules['influencers'] = {path: require('@devModules/influencers'), enabled: true, enableRoutes: true, enableNavigations: true, moduleGroups: ['user']} // Admin Influencers Module
// } catch (e) {
    // console.error('no Admin Influencers Module', e)
// }

// 6 modules visible
try {
    PluggedInModules['scraping'] = {path: require('@devModules/scraping'), enabled: true, enableRoutes: true, enableNavigations: true, moduleGroups: ['user']} // Scraping Module
} catch (e) {
    // console.error('no Scraping Module', e)
}

try {
    PluggedInModules['crm'] = {path: require('@devModules/crm'), enabled: true, enableRoutes: true, enableNavigations: true, moduleGroups: ['user']} // Crm Module
} catch (e) {
    // console.error('no Scraping Module', e)
}

try {
    PluggedInModules['form'] = {path: require('@devModules/form'), enabled: true, enableRoutes: true, enableNavigations: true, moduleGroups: ['user']} // User Module
} catch (e) {
    // console.error('no User Module', e)
}

// only ticket module visible
try {
    PluggedInModules['tickets'] = {path: require('@devModules/tickets'), enabled: true, enableRoutes: true, enableNavigations: true, moduleGroups: ['user', 'artist']} // ticketing Module ()
} catch (e) {
    // console.error('no Artist/Curator Module', e)
}

// app crashes without it
try {
    PluggedInModules['user'] = {path: require('@devModules/user'), enabled: true, enableRoutes: true, enableNavigations: true, moduleGroups: ['user']} // User Module
} catch (e) {
    // console.error('no User Module', e)
}

// only roles and permissions visible
try {
    PluggedInModules['rolespermissions'] = {path: require('@devModules/rolespermissions'), enabled: true, enableRoutes: true, enableNavigations: true, moduleGroups: ['user']} // Roles & Permissions Module
} catch (e) {
    // console.error('no Roles & Permissions Module', e)
}

// not visible in the admin page
try {
    PluggedInModules['artist'] = {path: require('@devModules/artist'), enabled: true, enableRoutes: true, enableNavigations: true, moduleGroups: ['artist']} // Artist/Curator Module ()
} catch (e) {
    // console.error('no Artist/Curator Module', e)
}

export default PluggedInModules
