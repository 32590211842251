import { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { MoreVertical, Edit, Trash, Eye, CheckCircle } from 'react-feather'
import {
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Badge
} from 'reactstrap'
import { Link } from "react-router-dom"
import Breadcrumbs from '@src/components/breadcrumbs'
import DataTable from '@src/components/datatable'
import { trans, _confirm } from '@utils'
import { AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import { CanCall } from '@authModule'
//************************************//
import { _deleteTicket, _changeTicketStatus, _getAllPrioritiesWithQ, _getAllStatusesWithQ, _getAllDepartmentsWithQ } from '@fwsrc/modules/tickets/redux/actions'
// import BasicInfoModal from './BasicInfoModal'
import EditTicket from '@fwsrc/modules/tickets/views/tickets/EditTicket'
import { statusesColors } from "../../../../utility/Constants"
import _ from "lodash"
//************************************//
const tableColumns = (state, _editBasicInfoModal, deleteUser, closeTicket, hasAction) => [
    {
        name: 'ID',
        selector: 'id',
        sortable: false,
        maxWidth: '50px',
        cell: (row) => {
            return (
                <span>{row.id}</span>
            )
        }
    },
    {
        name: 'Ticket Subject',
        selector: 'ticket_subject',
        sortable: false,
        cell: (row) => {
            return (
                <span>{row.ticket_subject}</span>
            )
        }
    },
    {
        name: 'Department',
        selector: 'department_id',
        sortable: false,
        minWidth: '200px',
        filter: {
            enabled: true,
            type: 'asyncSelect',
            loadOptions: _getAllDepartmentsWithQ
        },
        cell: (row) => {
            return <Badge color={'secondary'}>{row.tickets_department?.department_name}</Badge>
        }
    },
    {
        name: 'Priority',
        selector: 'priority_id',
        minWidth: '200px',
        sortable: false,
        filter: {
            enabled: true,
            type: 'asyncSelect',
            loadOptions: _getAllPrioritiesWithQ
        },
        omit: state.user.user_type_id > 1,
        cell: (row) => {
            return <Badge color={row.tickets_priority.color}>{row.tickets_priority.name}</Badge>
        }
    },
    {
        name: 'Status',
        selector: 'status_id',
        minWidth: '200px',
        sortable: false,
        filter: {
            enabled: true,
            type: 'asyncSelect',
            loadOptions: _getAllStatusesWithQ
        },
        omit: state.user.user_type_id > 1,
        cell: (row) => {
            return <Badge color={row.tickets_status.color}>{row.tickets_status.name}</Badge>
        }
    },
    {
        name: trans('gen.actions.actions'),
        allowOverflow: true,
        // omit: !hasAction,
        cell: (row, index, column, id) => {
            return (
                <div className='d-flex'>
                    <UncontrolledDropdown>
                        <DropdownToggle className='pr-1' tag='span'>
                            <MoreVertical size={15} />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <>
                                <CanCall action='tickets.show' id={`updateForm_${row.id}`}>
                                    <Link to={`/tickets/details/${row.id}`} className='w-100 dropdown-item'>
                                        <Eye size={15} />
                                        <span className='align-middle ml-50'>View</span>
                                    </Link>
                                </CanCall>

                                <CanCall action='tickets.edit' id={`updateForm_${row.id}`}>
                                    <DropdownItem className='w-100' onClick={e => _editBasicInfoModal(row)}>
                                        <Edit size={15} />
                                        <span className='align-middle ml-50'>{trans('gen.actions.edit')}</span>
                                    </DropdownItem>
                                </CanCall>
                                <CanCall action='tickets.status.edit' id={`updateForm_${row.id}`}>
                                    <DropdownItem className='w-100' onClick={e => closeTicket(row.id, 3)}>
                                        <CheckCircle size={15} />
                                        <span className='align-middle ml-50'>Mark Resolved</span>
                                    </DropdownItem>
                                </CanCall>
                                <CanCall action='tickets.delete' id={`deleteForm_${row.id}`}>
                                    <DropdownItem className='w-100 btn-flat-danger' onClick={e => deleteUser(row.id)}
                                        disabled={statusesColors[row.status_id] > 1}
                                    >
                                        <Trash size={15} />
                                        <span className='align-middle ml-50'>{trans('gen.actions.delete')}</span>
                                    </DropdownItem>
                                </CanCall>
                            </>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )
        }
    }
]
const tableActions = ['VIEW_TICKET', 'EDIT_TICKET', 'CHANGE_STATUS', 'DELETE_TICKET']
//************************************//
class TicketList extends Component {
    static contextType = AbilityContext
    constructor(props) {
        super(props)
        this.state = {
            userId: props.userId,
            user: props.user,
            basicInfoModal: { basicInfoModalShow: false, basicInfoModalData: {} },
            isArtist: this.props.isArtist
        }
        // this._editBasicInfoWizardModal = this._editBasicInfoWizardModal.bind(this)
        // this.deleteUser = this.deleteUser.bind(this)
    }

    _closeBasicInfoModal = () => {
        this.setState({ basicInfoModal: { basicInfoModalShow: false, basicInfoModalData: {} } })
    }
    //************************************//
    _openBasicInfoModal = () => {
        this.setState({ basicInfoModal: { basicInfoModalShow: true, basicInfoModalData: {} } })
    }
    //************************************//
    _editBasicInfoModal = (data) => {
        this.setState({ basicInfoModal: { basicInfoModalShow: true, basicInfoModalData: data } })
    }
    //************************************//
    deleteUser = (id) => {
        _confirm({
            callback: (c) => {
                _deleteTicket(id, () => {
                    this.dataTableRef._refresh()
                    c()
                })
            }
        })
    }
    //************************************//
    closeTicket = (id, status) => {
        _confirm({
            callback: (c) => {
                _changeTicketStatus(id, status, () => {
                    this.dataTableRef._refresh()
                    c()
                })
            }
        })
    }
    //************************************//
    render() {
        const { basicInfoModalShow, basicInfoModalData } = this.state.basicInfoModal
        const hasAction = _hasAnyAbility(this.context, tableActions)
        return (
            <Fragment>
                <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'Tickets List'} breadCrumbParent='' breadCrumbActive=''>
                    <CanCall action='tickets.add' id='addUserBtn'>
                        <Button.Ripple className='btn-icon btn-small-width-system' onClick={() => this.props.setAddTicket(true)} color='primary'>
                            Add Message
                        </Button.Ripple>
                    </CanCall>
                </Breadcrumbs>
                <Row>
                    <Col sm='12'>
                        <DataTable ref={(ref) => { this.dataTableRef = ref }} uri={`${process.env.REACT_APP_BACKEND_BASE_URL}user_tickets/${this.props.artist?.id}`} columns={tableColumns(this.state, this._editBasicInfoModal, this.deleteUser, this.closeTicket, hasAction)} hasFilter={true} defaultCollapse={true} />
                    </Col>
                </Row>
                {basicInfoModalShow && <EditTicket userId={this.state.userId} successCallback={this.dataTableRef._refresh} data={basicInfoModalData} onClose={this._closeBasicInfoModal} />}
            </Fragment>
        )
    }
}
//************************************//
const mapStateToProps = store => ({
    loading: store.app.loading,
    userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`),
    user: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData`)
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(TicketList)