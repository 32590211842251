import React, { Fragment, useEffect, useState, Component, memo } from 'react'
import { connect } from 'react-redux'
import { Check, X, ExternalLink, Music, Plus, File, BarChart2, Calendar } from 'react-feather'
import { ShimmerThumbnail } from "react-shimmer-effects"
import Styles from '../../assets/scss/style.scss'
import { ButtonSpinner, ErrorMessages } from '@src/components'
import { Icon } from '@iconify/react'
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Badge,
    CustomInput
} from 'reactstrap'
import { Link, withRouter } from "react-router-dom"
//************************************//
import { getUserData } from "../../utility/Utils"
import Breadcrumbs from '@src/components/breadcrumbs'
import { trans, _confirm, convertUnicodeToSymbols, _successSwal, _getActiveTab } from '@utils'

import { AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import { _getAllRolesWithQ } from '@modules/rolespermissions'
import _ from "lodash"
//************************************//
import { _getUserConnectedAccounts } from '../../redux/actions'
import axios from 'axios'

const formatAmount = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

//************************************//

const tableActions = ['NoPermissionCode']
//************************************//
class UserList extends Component {
    static contextType = AbilityContext

    constructor(props) {
        super(props)
        this.state = {
            userId: props.userId,
            spotifyData: [],
            tiktokData: [],
            instagramData: [],
            platformLimits: {},
            loading: true

        }
    }


    //************************************//
    componentDidMount() {
        _getUserConnectedAccounts(this.props.user.id,
            (data) => {
                console.log(data?.spotify?.length)
                this.setState({
                    spotifyData: data?.spotify || [],
                    tiktokData: data?.tiktok || [],
                    instagramData: data?.instagram || [],
                    platformLimits: data?.platforms_limit || [],
                    loading: false
                })
            }, (error) => {
                this.setState({
                    loading: false
                })
            })
    }

    //************************************//
    render() {
        const { loading, spotifyData, instagramData, tiktokData, platformLimits } = this.state
        const hasAction = _hasAnyAbility(this.context, tableActions)
        return (
            <Fragment>
                <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'Connections'} breadCrumbParent='' breadCrumbActive='' >

                </Breadcrumbs>


                <Row>

                    <Col xs='12' xl='12'>
                        <Card style={{ minHeight: '300px' }}>
                            <CardHeader>
                                <CardTitle tag='h4' className='mb-0'>
                                Connected Accounts
                                    {/* {this.props.user.user_type_id === 1 ? <Icon icon="logos:spotify" width={150} /> : this.props.user.user_type_id === 3 ? 'Connected TikTok Accounts' : 'Connected Spotify Accounts'} */}

                                    {/*  */}

                                </CardTitle>

                            </CardHeader>
                            <CardBody>
                                {/* <CardText className='d-flex align-items-start me-2 mb-1'>{this.props.user.user_type_id === 1 ? 'Display content from your connected accounts on Spotify for Artists' : this.props.user.user_type_id === 3 ? ' Display content from your connected accounts on TikTok' : ' Display content from your connected accounts on Spotify'}</CardText> */}
                                {loading &&
                                    <div>
                                        <ShimmerThumbnail width={'100%'} height={100} rounded className='dark-shimmer-thumbnail' />
                                        <ShimmerThumbnail width={'100%'} height={100} rounded className='dark-shimmer-thumbnail' />
                                    </div>}

                                {/* {!loading && spotifyData.length === 0 && <div>No connected accounts</div>} */}
                                {this.props.user.user_type_id === 1 ? (
                                 <>
                                 {!loading && spotifyData.length !== 0 ? <CardText className='d-flex align-items-start me-2 mb-1'>Display content from your connected accounts on Spotify</CardText> : null}

                                 {
                                    spotifyData.map((account, index) => (
                                       <Card className='create-card-background-color'>
                                        <CardBody>
                                        <div key={index} className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center me-2'>
                                            <img width={35} src={require('@fwsrc/assets/images/spotify.png').default} alt="Spotify Logo" />

                                                {/* <img src={account?.image} className='rounded avatar' height='50' width='50' /> */}

                                                <div className='ms-75 pl-1'>
                                                    <h5 className='mb-0'>{account?.name}</h5>
                                                    {/* <small>{account?.user_email}</small> */}
                                                    {/* You can add the "View on Spotify" link here */}
                                                </div>
                                            </div>
                                            <div>
                                                {account?.is_active === 2 ? (
                                                    <Badge className='justify-content-end' id='plan-expiry-date' color='danger'>
                                                        {account?.status?.status}
                                                    </Badge>
                                                ) : account?.is_active === 1 ? (
                                                    <Badge id='plan-expiry-date' color='success'>
                                                        {account?.status?.status}
                                                    </Badge>
                                                ) : (
                                                    <Badge id='plan-expiry-date' color='secondary'>
                                                        {account?.status?.status}
                                                    </Badge>
                                                )}
                                            </div>
                                        </div>
                                        </CardBody>
                                       </Card>
                                    ))
                                 }
                                 {!loading && tiktokData.length !== 0 ? <CardText className='d-flex align-items-start me-2 mb-1'>Display content from your connected accounts on TikTok</CardText> : null}
                                        {tiktokData.map((account, index) => (
                                            <Card key={`tiktok-${index}`} className='create-card-background-color'>
                                                <CardBody>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex align-items-center me-2'>
                                                            <img src={require('@fwsrc/assets/images/Tiktok.svg').default} width="40" height="40" />
                                                            <div className='ms-75 pl-1'>
                                                                <h5 className='mb-0'>{account?.display_name}</h5>
                                                                <small>{account?.user_name}</small>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {account?.connected_status === 0 ? (
                                                                <div className='justify-content-end'>
                                                                    <span className='text-danger'>Not Connected</span>
                                                                </div>
                                                            ) : (
                                                                <span className='text-success'>Connected</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                        {!loading && instagramData.length !== 0 ? <CardText className='d-flex align-items-start me-2 mb-1'>Display content from your connected accounts on Instagram</CardText> : null}

                                        {instagramData.map((account, index) => (
                                            <Card key={`instagram-${index}`} className='create-card-background-color'>
                                                <CardBody>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex align-items-center me-2'>
                                                        <Icon icon="bi:instagram" color='white' width={30} />
                                                            <div className='ms-75 pl-1'>
                                                                <h5 className='mb-0'>{account?.display_name}</h5>
                                                                <small>{account?.user_name}</small>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {account?.connected_status === 0 ? (
                                                                <div className='justify-content-end'>
                                                                    <span className='text-danger'>Not Connected</span>
                                                                </div>
                                                            ) : (
                                                                <span className='text-success'>Connected</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                 </>
                                   
                                ) : this.props.user.user_type_id === 3 ? (
                                    <>
                                    {!loading && tiktokData.length !== 0 ? <CardText className='d-flex align-items-start me-2 mb-1'>Display content from your connected accounts on TikTok</CardText> : null}
                                        {tiktokData.map((account, index) => (
                                            <Card key={`tiktok-${index}`} className='create-card-background-color'>
                                                <CardBody>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex align-items-center me-2'>
                                                            <img src={require('@fwsrc/assets/images/Tiktok.svg').default} width="40" height="40" />
                                                            <div className='ms-75 pl-1'>
                                                                <h5 className='mb-0'>{account?.display_name}</h5>
                                                                <small>{account?.user_name}</small>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {account?.connected_status === 0 ? (
                                                                <div className='justify-content-end'>
                                                                    <span className='text-danger'>Not Connected</span>
                                                                </div>
                                                            ) : (
                                                                <span className='text-success'>Connected</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                        {!loading && instagramData.length !== 0 ? <CardText className='d-flex align-items-start me-2 mb-1'>Display content from your connected accounts on Instagram</CardText> : null}

                                        {instagramData.map((account, index) => (
                                            <Card key={`instagram-${index}`} className='create-card-background-color'>
                                                <CardBody>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex align-items-center me-2'>
                                                        <Icon icon="bi:instagram" color='white' width={30} />
                                                            <div className='ms-75 pl-1'>
                                                                <h5 className='mb-0'>{account?.display_name}</h5>
                                                                <small>{account?.user_name}</small>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {account?.connected_status === 0 ? (
                                                                <div className='justify-content-end'>
                                                                    <span className='text-danger'>Not Connected</span>
                                                                </div>
                                                            ) : (
                                                                <span className='text-success'>Connected</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </>
                                ) : (
                                   
                                    <>
                                    {!loading && tiktokData.length !== 0 ? <CardText className='d-flex align-items-start me-2 mb-1'>Display content from your connected accounts on Spotify</CardText> : null}

                                    {
                                        spotifyData.map((account, index) => (
                                        <Card className='create-card-background-color'>
                                            <CardBody>
                                            <div key={index} className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center me-2'>
                                                <img width={35} src={require('@fwsrc/assets/images/spotify.png').default} alt="Spotify Logo" />
                                                <div className='ms-75 pl-1'>
                                                    <h5 className='mb-0'>{account?.user_name}</h5>
                                                    <small>{account?.user_email}</small>
                                                    {/* You can add the "View on Spotify" link here */}
                                                </div>
                                            </div>
                                            <div>
                                                {/* {account?.connected_status === 0 ? (
                                                    <div className='justify-content-end'>
                                                        <span className='text-danger'> Not Connected</span>
                                                    </div>
                                                ) : (
                                                    <span className='text-success'>Connected</span>
                                                )} */}
                                            </div>
                                        </div>
                                            </CardBody>
                                        </Card>
                                    ))
                                    }
                                    {!loading && tiktokData.length !== 0 ? <CardText className='d-flex align-items-start me-2 mb-1'>Display content from your connected accounts on TikTok</CardText> : null}
                                        {tiktokData.map((account, index) => (
                                            <Card key={`tiktok-${index}`} className='create-card-background-color'>
                                                <CardBody>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex align-items-center me-2'>
                                                            <img src={require('@fwsrc/assets/images/Tiktok.svg').default} width="40" height="40" />
                                                            <div className='ms-75 pl-1'>
                                                                <h5 className='mb-0'>{account?.display_name}</h5>
                                                                <small>{account?.user_name}</small>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {account?.connected_status === 0 ? (
                                                                <div className='justify-content-end'>
                                                                    <span className='text-danger'>Not Connected</span>
                                                                </div>
                                                            ) : (
                                                                <span className='text-success'>Connected</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                        {!loading && instagramData.length !== 0 ? <CardText className='d-flex align-items-start me-2 mb-1'>Display content from your connected accounts on Instagram</CardText> : null}

                                        {instagramData.map((account, index) => (
                                            <Card key={`instagram-${index}`} className='create-card-background-color'>
                                                <CardBody>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex align-items-center me-2'>
                                                        <Icon icon="bi:instagram" color='white' width={30} />
                                                            <div className='ms-75 pl-1'>
                                                                <h5 className='mb-0'>{account?.display_name}</h5>
                                                                <small>{account?.user_name}</small>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {account?.connected_status === 0 ? (
                                                                <div className='justify-content-end'>
                                                                    <span className='text-danger'>Not Connected</span>
                                                                </div>
                                                            ) : (
                                                                <span className='text-success'>Connected</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                   
                                    </>
                                    
                                )}


                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
//************************************//
const mapStateToProps = store => ({
    loading: store.app.loading,
    userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`)
})
export default withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(UserList))