import React from "react"
import {File} from "react-feather"

import {navTrans} from '@utils'

export const Navigation = []
// 	{
// 		header: 'Forms'
// 	},
// 	{
// 	  id: 'Forms',
// 	  title: navTrans('form.nav.forms'),
// 	  icon: <File />,
// 	  navLink: '/forms/list',
// 	  action: 'call',
// 	  resource: 'FORMS_VIEW_LIST'
// 	}
// ]
