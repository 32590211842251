import React, { Fragment, Component, memo } from 'react'
import { connect } from 'react-redux'
import {FileText, MoreVertical, Send, Plus, Key, User, Trash, Eye} from 'react-feather'
import { Icon } from '@iconify/react'

import {
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Badge
} from 'reactstrap'
import {Link} from "react-router-dom"
//************************************//
import Breadcrumbs from '@src/components/breadcrumbs'
import DataTable from '@src/components/datatable'
import {trans, _confirm, convertUnicodeToSymbols} from '@utils'
import {AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import Avatar from '@components/avatar'
import {_getAllRolesWithQ} from '@modules/rolespermissions'
import {CanCall} from '@authModule'
//************************************//
import {_deleteGenre} from '../../redux/actions'
import {statusesColors} from "../../../../utility/Constants"
import _ from "lodash"
//************************************//
const tableColumns = (state, hasAction) => [
    {
        name: 'ID',
        selector: 'id',
        sortable: false,
        minWidth: '225px',
        omit: true,
        filter: {
            enabled: false
        }
    },
    {
        name: 'Platform',
        selector: 'name',
        cell: (row) => {
            return (
                row.campaign_type_id === 1 ? <Icon icon="iconoir:spotify" width={35} height={35} color='white' /> : row.campaign_type_id === 3 ? <Icon icon="bi:instagram" color='white' width={30} /> : <img src={require('@fwsrc/assets/images/Tiktok.svg').default} width="30" height="30" /> 
            )
        }
    },
    {
        name: trans('user.name'),
        selector: 'name',
        sortable: true,
        minWidth: '200px',
        cell: (row, index, column, id) => {
            return (

                    <div className='user-info text-truncate'>
                        <span className='d-block font-weight-bold text-truncate'>{convertUnicodeToSymbols(row.name)}</span>
                        {/*<span className='d-block font-weight-bold text-muted'>{row.email}</span>*/}
                    </div>

            )
        }
    },
    {
        name: 'Type',
        selector: 'custom_campaign_type',
        sortable: false,
        minWidth: '100px',
        cell: (row) => {
            return <Badge color={row.custom_campaign_type === 'Spotify' ? 'success' : row.custom_campaign_type === 'TikTok' ? 'secondary' : 'primary'}>{convertUnicodeToSymbols(row.custom_campaign_type)}</Badge>
        }
    },
    // {
    //     name: trans('user.name'),
    //     selector: 'name',
    //     sortable: true,
    //     minWidth: '200px',
    //     omit:true,
    //     filter: {
    //         enabled: true,
    //         type: 'text'
    //     }
    // },
    // {
    //     name: 'Artist Name',
    //     selector: 'artist_name',
    //     sortable: false,
    //     filter: {
    //         enabled: true,
    //         type: 'text'
    //     },
    //     cell: (row) => {
    //         return (
    //             <span>{row?.artist?.name}</span>
    //         )
    //     }
    // },
    {
        name: 'Tier',
        selector: 'tier',
        sortable: false,
        minWidth: '100px',
        cell: (row) => {
            return <Badge color={'info'}>{row.campaign_type === 'Express' ? convertUnicodeToSymbols(row.tier?.tier_name) : convertUnicodeToSymbols(row.tier?.name)}</Badge>
        }
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: false,
        minWidth: '200px',
        cell: (row) => {
            return <>
                {row.campaign_type === 'Express' ? <Badge
                                    className={'mx-25'}
                                    color={
                                      (() => {
                                        switch (row.status?.id) {
                                          case 1:
                                            return 'success'
                                          case 2:
                                            return 'warning'
                                          case 3:
                                            return 'danger'
                                          case 5:
                                            return 'danger'
                                            case 6:
                                            return 'primary'
                                          default:
                                            return 'secondary'
                                        }
                                      })()
                                    }
                                  >
                                    {row.status?.status}
                                  </Badge> : <Badge color={statusesColors[row.status].color}>{row.status}</Badge>}
            </>
        }
    }
]
const tableActions = ['NoPermissionCode']
//************************************//
class ArtistLastFIveCampaignsList extends Component {
    static contextType = AbilityContext
    constructor(props) {
        super(props)
        this.state = {
            userId: props.userId,
            user: props.user,
            basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}
        }
        this._editBasicInfoModal = this._editBasicInfoModal.bind(this)
        this._deleteUser = this._deleteUser.bind(this)
    }
    //************************************//
    _closeBasicInfoModal = () => {
        this.setState({basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}})
    }
    //************************************//
    _openBasicInfoModal = () => {
        this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: {}}})
    }
    //************************************//
    _editBasicInfoModal = (data) => {
        this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: data}})
    }
    //************************************//
    _deleteUser = (id) => {
        _confirm({
            callback: (c) => {
                // _deleteGenre(id, () => {
                //   this.dataTableRef._refresh()
                //   c()
                // })
            }
        })
    }
    //************************************//
    render () {
        const hasAction = _hasAnyAbility(this.context, tableActions)
        return (
            <Fragment>
                <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'Campaigns'} breadCrumbParent='' breadCrumbActive='' >
                </Breadcrumbs>
                <Row>
                    <Col sm='12'>
                        <DataTable
                            ref={(ref) => { this.dataTableRef = ref }}
                            uri={`curator/campaigns/all/${this.props.curator.id}`}
                            columns={tableColumns(this.state, hasAction)}
                            hasIndexing={false}
                            hasFilter={false}
                            defaultCollapse={true}
                            showAll={false}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
//************************************//
const mapStateToProps = store => ({
    loading: store.app.loading,
    userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`),
    user: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData`)
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(ArtistLastFIveCampaignsList)
