import {lazy} from "react"

export const Routes = [

	{
		path: "/forms/list",
		component: lazy(() => import("../views/forms")),
		meta: {
			action: "call",
			resource: "ROLES_VIEW_LIST"
		}
	},
	{
		path: "/forms/:formId",
		component: lazy(() => import("../views/form-view")),
		meta: {
			action: "call",
			resource: "ROLES_VIEW_LIST"
		}
	}
]
