import { store } from '../../../redux/storeConfig/store'

export const checkPassReset = (store) => (nextState, replace, next) => {
    // const state = store.getState()
  
    // // Check if the user's "pass_reset_required" column is set to 1
    // if (state.userData.pass_reset_required === 1) {
    //   // If the column is set to 1, redirect the user to the password reset page
    //   replace('/reset-password')
    // } else {
    //   // If the column is not set to 1, allow the user to access the route
    //   next()
    // }
    console.log(store.getState())
  }