import { Fragment } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Controller, useForm } from "react-hook-form"
import _ from "lodash"
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from "reactstrap"
import { Icon } from "@iconify/react"

import { useParams } from 'react-router-dom'

import { trans } from '@utils'

import { ErrorMessages } from '@src/components'

import classnames from "classnames"

const ExtraInfo = (props) => {
    const { errors, handleSubmit, control, trigger, getValues, setValue, register, watch } = useForm()
    const { formData, setFormData } = props.formState
    const { userId } = useParams()

    const onSubmit = (data) => {
        trigger()
        if (_.isEmpty(errors)) {
            setFormData(prevState => ({
                ...prevState,
                ...data,
                soundcloud_link: data.soundcloud_link,
                apple_track_id: data.apple_track_id,
                label: data.label,
                extra_info: data.extra_info
            }))
            props.stepper.next()

        }
    }

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <Label className='form-label' for='soundcloud_link'>
                        SoundCloud Private Link <small className={'text-danger'}>*</small>
                    </Label>

                    <InputGroup className='input-group-merge mb-2'>
                        <InputGroupAddon addonType='prepend'>
                            <InputGroupText><Icon icon="mdi:soundcloud" width={'18'} height={'18'} />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                            as={Input}
                            control={control}
                            autoFocus
                            type='url'
                            invalid={errors.soundcloud_link && true}
                            id='soundcloud_link'
                            name='soundcloud_link'
                            rules={{
                                required: false
                            }} defaultValue={formData?.soundcloud_link ?? ''}
                            className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                        />
                    </InputGroup>
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'soundcloud_link'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='apple_track_id'>
                        Apple track ID (You can email your distributor. If Distrokid, on website under Goodies, Helpful When Needed, Apple ID Looker-Upper, Apple Track ID) <small className={'text-danger'}>*</small>
                    </Label>
                    <InputGroup className='input-group-merge mb-2'>
                        <InputGroupAddon addonType='prepend'>
                            <InputGroupText><Icon icon="ic:outline-apple" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Controller
                            as={Input}
                            control={control}
                            autoFocus
                            type='number'
                            id='apple_track_id'
                            name='apple_track_id'
                            invalid={errors.apple_track_id && true}
                            rules={{
                                required: trans('user.validation.required')
                            }}
                            defaultValue={formData?.apple_track_id ?? ''}
                            className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                        />
                    </InputGroup>
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'apple_track_id'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='label'>
                        Label (if applicable)
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='text'
                        id='label'
                        name='label'
                        rules={{
                            required: false
                        }}
                        defaultValue={formData.label ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'label'} />
                </FormGroup>
                <FormGroup>
                    <Label className='form-label' for='extra_info'>
                        Any extra Information about artist / management
                    </Label>
                    <Controller
                        as={Input}
                        control={control}
                        autoFocus
                        type='textarea'
                        id='extra_info'
                        name='extra_info'
                        rules={{
                            required: false
                        }}
                        defaultValue={formData?.extra_info ?? ''}
                        className={classnames({ 'is-invalid': errors['name'] || _.get(props.valErrors, 'name') })}
                    />
                    <ErrorMessages valErrors={props.valErrors} errors={errors} name={'extra_info'} />
                </FormGroup>


                <div className='d-flex justify-content-between mt-2'>
                    <Button color='primary' onClick={() => props.stepper.previous()}>
                        <ArrowLeft size={14} className='rotate-rtl align-middle me-sm-50 me-0' />
                        <div className='align-middle d-sm-inline-block d-none'>Previous</div>
                    </Button>
                    <Button color='primary' type='submit'>
                        <div className='align-middle d-sm-inline-block d-none'>Next</div>
                        <ArrowRight size={14} className='rotate-rtl align-middle ms-sm-50 ms-0' />
                    </Button>
                </div>
            </Form>
        </Fragment>
    )
}

export default ExtraInfo