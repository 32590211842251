import {lazy} from "react"

export const Routes = [
    {
        path: "/genres/list",
        component: lazy(() => import("../views/genres-list")),
        meta: {
            action: "call",
            resource: "view.main.genres.tab.list"
        }
    },
    {
        path: "/interests/list",
        component: lazy(() => import("../views/interests-list")),
        meta: {
            action: "call",
            resource: "view.main.interests.tab.list"
        }
    },
    {
        path: "/userTypes/list",
        component: lazy(() => import("../views/user-types-list")),
        meta: {
            action: "call",
            resource: "INFLUENCER_VIEW_USER_TYPES"
        }
    },
    {
        path: "/users/list",
        component: lazy(() => import("../views/users-list")),
        meta: {
            action: "call",
            resource: "view.main.users.tab.list"
        }
    },
    {
        path: "/users/:userId",
        component: lazy(() => import("../views/user-profile")),
        meta: {
            action: "call",
            resource: "users.show"
        }
    },
    {
        path: "/tiers/list",
        component: lazy(() => import("../views/tiers-list")),
        meta: {
            action: "call",
            resource: "view.main.tiers.tab.list"
        }
    },
    {
        path: "/express/tiers/list",
        component: lazy(() => import("../views/express-tiers-list")),
        meta: {
            action: "call",
            resource: "view.main.express.tiers.tab.list"
        }
    },
    {
        path: "/express/durations/list",
        component: lazy(() => import("../views/express-durations-list")),
        meta: {
            action: "call",
            resource: "view.main.express.tiers.tab.list"
        }
    },
    {
        path: "/express/playlists/list",
        component: lazy(() => import("../views/express-playlists-list")),
        meta: {
            action: "call",
            resource: "view.main.express.playlist.tab.list"
        }
    },
    {
        path: "/express/campaigns/list",
        component: lazy(() => import("../views/express-campaigns-list")),
        meta: {
            action: "call",
            resource: "view.main.express.campaigns.tab.list"
        }
    },
    {
        path: "/express/tiktok/campaigns/list",
        component: lazy(() => import("../views/express-tiktok-campaigns-list")),
        meta: {
            action: "call",
            resource: "view.main.tiktok.express.campaign.tab.list"
        }
    },
    {
        path: "/express/instagram/campaigns/list",
        component: lazy(() => import("../views/express-instagram-campaigns-list")),
        meta: {
            action: "call",
            resource: "view.main.instagram.express.campaign.tab.list"
        }
    },
    {
        path: "/express/playlist/view/:id",
        component: lazy(() => import("../views/express-playlist-view")),
        meta: {
            action: "call",
            resource: "express.playlist.show"
        }
    },
    {
        path: "/express/playlist/create",
        component: lazy(() => import("../views/express-playlists-list/components/create-playlist/CreatePlayList")),
        meta: {
            action: "call",
            resource: "express.playlist.add"
        }
    },
    {
        path: "/express/checkout",
        component: lazy(() => import("../views/checkout")),
        meta: {
            action: "call",
            resource: "INFLUENCER_VIEW_TIERS"
        }
    },
    {
        path: "/campaigns/list",
        component: lazy(() => import("../views/campaigns-list")),
        meta: {
            action: "call",
            resource: "view.main.custom.campaigns.tab.list"
        }
    },
    {
        path: "/express-campaigns/:campaignId",
        component: lazy(() => import("../views/express-campaigns-view")),
        meta: {
            action: "call",
            resource: "express.campaigns.show"
        }
    },
    {
        path: "/express-tiktok-campaigns/:campaignId",
        component: lazy(() => import("../views/express-tiktok-campaigns-view")),
        meta: {
            action: "call",
            resource: "express.campaigns.show"
        }
    },
    {
        path: "/express-instagram-campaigns/:campaignId",
        component: lazy(() => import("../views/express-instagram-campaigns-view")),
        meta: {
            action: "call",
            resource: "express.campaigns.show"
        }
    },
    {
        path: "/campaigns/:campaignId",
        component: lazy(() => import("../views/campaign-view")),
        meta: {
            action: "call",
            resource: "custom.campaigns.show"
        }
    },
    {
        path: "/invoices/list",
        component: lazy(() => import("../views/invoices-list")),
        meta: {
            action: "call",
            resource: "view.main.invoices.tab.list"
        }
    },
    {
        path: "/invoices/:id",
        component: lazy(() => import("../views/invoices-view")),
        meta: {
            action: "call",
            resource: "invoices.show"
        }
    },
    {
        path: "/payouts/list",
        component: lazy(() => import("../views/payouts-list")),
        meta: {
            action: "call",
            resource: "view.main.payouts.tab.list"
        }
    },
    {
        path: "/requests/list",
        component: lazy(() => import("../views/transfers")),
        meta: {
            action: "call",
            resource: "view.main.transfers.tab.list"
        }
    },
    {
        path: "/transfers/:quote",
        component: lazy(() => import("../views/transfers-view")),
        meta: {
            action: "call",
            resource: "transfers.show"
        }
    },
    {
        path: "/payouts/:id",
        component: lazy(() => import("../views/payouts-view")),
        meta: {
            action: "call",
            resource: "INFLUENCER_VIEW_PAYOUT_PROFILE"
        }
    },
    {
        path: "/spotify/callback/:accessToken",
        component: lazy(() => import("../views/spotify-callback")),
        meta: {
            publicRoute: true
        }
    },
    {
        path: "/reports",
        component: lazy(() => import("../views/reports")),
        meta: {
            action: "call",
            resource: "view.main.reports.tab.list"
        }
    },    
    {
        path: "/notifications",
        component: lazy(() => import("../views/notifications")),
        meta: {
            action: 'call',
            resource: 'general',
            general: true
        }
    },
    {
        path: "/tiktok/express/durations/list",
        component: lazy(() => import("../views/tiktok-express-durations-list")),
        meta: {
            action: "call",
            resource: "view.main.express.tiers.tab.list"
        }
    },
    {
        path: "/tiktok/express/tiers/list",
        component: lazy(() => import("../views/tiktok-express-tiers-list")),
        meta: {
            action: "call",
            resource: "view.main.express.tiers.tab.list"
        }
    },
    {
        path: "/wallet/transaction/list",
        component: lazy(() => import("../views/wallet-transactions")),
        meta: {
            action: "call",
            resource: "main.wallet.show.all.transactions"
        }
    },
    {
        path: "/wallet/deposit/list",
        component: lazy(() => import("../views/wallet-deposit")),
        meta: {
            action: "call",
            resource: "view.main.express.tiers.tab.list"
        }
    },
    {
        path: "/wallet/withdrawals/list",
        component: lazy(() => import("../views/wallet-withdrawals")),
        meta: {
            action: "call",
            resource: "view.main.express.tiers.tab.list"
        }
    },
    {
        path: "/wallet/transaction/view",
        component: lazy(() => import("../views/wallet-transactions-view")),
        meta: {
            action: "call",
            resource: "view.main.express.tiers.tab.list"
        }
    },
    {
        path: "/wallet/coins/details/list",
        component: lazy(() => import("../views/wallet-coins")),
        meta: {
            action: "call",
            resource: "view.main.express.tiers.tab.list"
        }
    },
    {
        path: "/wallet/main/list",
        component: lazy(() => import("../views/main-wallet")),
        meta: {
            action: "call",
            resource: "view.main.wallet.tab.list"
        }
    },
    // {
    //     path: "/view/all-transactions",
    //     component: lazy(() => import("../views/all-transactions")),
    //     meta: {
    //         action: "call",
    //         resource: "view.main.express.tiers.tab.list"
    //     }
    // },
    {
        path: "/wallet/packages/list",
        component: lazy(() => import("../views/coin-packages")),
        meta: {
            action: "call",
            resource: "view.main.wallet.packages.tab.list"
        }
    }
]
