import {API, phpToMomentFormat} from '@utils'
import {store} from '@fwsrc/redux/storeConfig/store'
//************************************//
export const _getAppSettings = (callback) => {
    return (dispatch) => {
        API.get('setting/all')
            .then(function ({data}) {
              // data.app.date_format = phpToMomentFormat(data.app.date_format)
              // data.app.time_format = phpToMomentFormat(data.app.time_format)
              // data.app.datetime_format = phpToMomentFormat(data.app.datetime_format)
              dispatch({type: "APP_SETTINGS", data})
              callback()
            })
            .catch(function (res) {

            })
    }
}
export const _getMaintenanceMode = (callback) => {
  API.get(`maintenance-mode-check`)
    .then((data) => {
      if (data.check === true) {
        store.dispatch({ type: "APP_MAINTENANCE_START", payload: {} })
      } else {
        store.dispatch({ type: "APP_MAINTENANCE_END", payload: {} })
      }
      // The application is in maintenance mode. //APP_MAINTENANCE_START
      // callback({ maintenanceMode: true })
    })
    .catch((error) => {
      // console.log(error.status)
      if (error?.status === 503) {
        //Handle 503 error (Service Unavailable)
        store.dispatch({ type: "APP_MAINTENANCE_START", payload: {} })
      } else {
        // Handle other errors here, if needed.
        store.dispatch({ type: "APP_MAINTENANCE_END", payload: {} })
      }
    })
}

// export const _getMaintenanceMode = (callback) => {
//   API.get(`maintenance/mode/check`)
//     .then((data) => {
//       callback({ data })
//     })
//     .catch((error) => {
//       // Handle errors here
      
//     })
// }
 

// export const _getMaintenanceMode = (callback) => {
//   return (dispatch) => {
//       API.get('setting/all')
//           .then(function ({data}) {
//             // data.app.date_format = phpToMomentFormat(data.app.date_format)
//             // data.app.time_format = phpToMomentFormat(data.app.time_format)
//             // data.app.datetime_format = phpToMomentFormat(data.app.datetime_format)
//             dispatch({type: "APP_SETTINGS", data})
//             callback()
//           })
//           .catch(function (res) {

//           })
//   }
// }
//************************************//
export const _getList = ({limit, page, start, length, order, filter, columns, uri}, callback) => {
  API.get(uri, {
    params: {
      start,
      length,
      limit,
      page,
      order,
      filter,
      columns
    }
  })
    .then(function ({data}) {
      callback(data)
    })
    .catch(function (res) {})
}
