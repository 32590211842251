// ** Reactstrap Imports
import { Fragment, useState } from "react"
import { Col, Row } from 'reactstrap'
import TicketsList from "./TicketsList"
import NewTicket from '@fwsrc/modules/tickets/views/tickets/NewTicket'


const TicketsTab = ({ user, userId }) => {
    const [addTicket, setAddTicket] = useState(false)
    return (
        <Fragment>
            {addTicket ? (
                <Row>
                    <Col>
                        <NewTicket setAddTicket={setAddTicket} user={user} userId={userId} />
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col>
                        <TicketsList setAddTicket={setAddTicket} artist={user} userId={userId} />
                    </Col>
                </Row>
            )}

        </Fragment>
    )
}

export default TicketsTab
