import React, { Fragment, Component, memo } from 'react'
import { connect } from 'react-redux'
import {FileText, MoreVertical, Send, Plus, Key, User, Trash, Eye} from 'react-feather'
import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Badge
} from 'reactstrap'
import {Link} from "react-router-dom"
import CustomAvatar from "@fwsrc/components/CustomAvatar"
import { convertUnicodeToSymbols } from '../../../core/utility/Utils'

//************************************//
import Breadcrumbs from '@src/components/breadcrumbs'
import DataTable from '@src/components/datatable'
import {trans, _confirm} from '@utils'
import {AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import Avatar from '@components/avatar'
import {_getAllRolesWithQ} from '@modules/rolespermissions'
import {CanCall} from '@authModule'
//************************************//
import {_deleteGenre} from '../../redux/actions'
//************************************//
const tableColumns = (state, _editBasicInfoModal, _deleteUser, hasAction) => [
    {
      name: 'ID',
      selector: 'id',
      sortable: false,
      omit: true,
      filter: {
        enabled: false
      }
    },
    {
      name: 'Device',
      selector: 'platform',
      minWidth: '150px',
      sortable: false,
      cell: (row, index, column, id) => {
        return (
            <div className='d-flex align-items-center'>
            {/* <CustomAvatar  content={`${convertUnicodeToSymbols(row.platform)}`}/> */}
                  {/* <img  className="rounded-image-playlist small-image-playlist" src={row.playlist_info?.playlist_image} alt={row.playlist_info?.playlist_name} /> */}
  
              <div className='user-info text-truncate ml-1'>
                <span className='d-block font-weight-bold text-truncate text-wrap'>{convertUnicodeToSymbols(row.platform)}</span>
              </div>
            </div>
        )
      }
    },
    
    {
      name: 'Action',
      selector: 'action',
      minWidth: '100px',
      sortable: false
    },
    {
      name: 'Location',
      selector: 'country',
      minWidth: '150px',
      sortable: false,
      cell: (row) => {
        return <p>{row.country}</p>
    }
    },
    {
      name: 'IP Address',
      selector: 'ip',
      width: '20%',
      sortable: false,
      cell: (row) => {
        return <p>{row.ip}</p>
    }
    },
    {
      name: 'Date',
      selector: 'transaction_date',
      filter: {
        enabled: true,
        type: 'date'
      },
      sortable: false,
      cell: (row) => {
        return (
          <span>{new Date(row.created_at).toLocaleDateString()} {new Date(row.created_at).toLocaleTimeString()}</span>
        )
      }
    }
  ]
const tableActions = ['NoPermissionCode']
//************************************//
class ArtistLastFiveInvoicesList extends Component {
  static contextType = AbilityContext
  constructor(props) {
    super(props)
    this.state = {
      userId: props.userId,
      basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}
    }
    this._editBasicInfoModal = this._editBasicInfoModal.bind(this)
    this._deleteUser = this._deleteUser.bind(this)
  }
  //************************************//
   _closeBasicInfoModal = () => {
    this.setState({basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}})
  }
  //************************************//
  _openBasicInfoModal = () => {
    this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: {}}})
  }
  //************************************//
  _editBasicInfoModal = (data) => {
    this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: data}})
  }
  //************************************//
  _deleteUser = (id) => {
    _confirm({
      callback: (c) => {
        _deleteGenre(id, () => {
          this.dataTableRef._refresh()
          c()
        })
      }
    })
  }
  //************************************//
  render () {
    const hasAction = _hasAnyAbility(this.context, tableActions)
    return (
      <Fragment>
        <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'User Activity'} breadCrumbParent='' breadCrumbActive='' ></Breadcrumbs>
        <Row>
          <Col sm='12'>
            <DataTable
                ref={(ref) => { this.dataTableRef = ref }}
                uri={`users/activities/${this.props.user.id}`}
                columns={tableColumns(this.state, hasAction)}
                hasIndexing={false}
                hasFilter={false}
                defaultCollapse={true}
                showAll={false}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }
}
//************************************//
const mapStateToProps = store => ({
  loading: store.app.loading,
  userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`)
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(ArtistLastFiveInvoicesList)
