// ** React Imports
import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'

// ** Reactstrap Imports
import { Row, Col, Alert, Button, ButtonGroup } from 'reactstrap'
import { DownloadCloud, Plus, Edit, Edit2 } from "react-feather"
import { ButtonSpinner, ErrorMessages } from '@src/components'

import { _confirm, _toast } from '@utils'

// ** Styles
import '@styles/react/apps/app-users.scss'

import { _getUserInfo, _inviteUser, _resetPassword, _getPayoutsSum } from "../../redux/actions"
import BasicInfoModal from "../users-list/BasicInfoModal"
// ** User View Components
import UserTabs from './Tabs'
import UserInfoCard from './UserInfoCard'

import { CanCall } from '@authModule'
import BasicInfoWizardModal from "@fwsrc/modules/form/views/forms/BasicInfoWizardModal"
import Breadcrumbs from '@src/components/breadcrumbs'

const UserProfile = (props) => {
  const loading = useSelector(store => store.app.loading)
  // ** Store Vars
  const [user, setUser] = useState({})
  // const [form, setForm] = useState({})
  const [usercampaigndone, setUsercampaigndone] = useState(0)
  const [budgetmake, setBudgetmake] = useState(0)

  //const [user, setUser] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [formModal, setFormModal] = useState(false)
  const [sumData, setSumData] = useState('')

  // ** Hooks
  const { userId } = useParams()

  const [active, setActive] = useState('1')

  const toggleTab = tab => {
    if (active !== tab) {
      setActive(tab)
    }
  }

  const getArtistData = (data = {}) => {
    _getUserInfo(userId, (data) => {
      setUser(data.user)
      setUsercampaigndone(data.campaindonecount)
      setBudgetmake(data.budgetmake)
    })
  }

  // ({user}) => setUser(user),
  // setUsercampaigndone(data.campaindonecount)
  useEffect(() => {
    _getPayoutsSum(userId,
      (results) => {

        console.log(results.sum)
        setSumData(results.sum)
      }
    )
    getArtistData()
  }, [])

  const resetPassword = () => {
    _confirm({
      callback: (c) => {
        _resetPassword(
          user.id,
          () => {
          },
          () => { }
        )
      }
    })
  }

  const inviteUser = () => {
    _confirm({
      callback: (c) => {
        _inviteUser(
          user.id,
          () => {
            getArtistData()
          },
          () => { }
        )
      }
    })
  }

  return !_.isEmpty(user) ? (
    <div className='app-user-view'>
    <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'User Account Overview'} breadCrumbParent='' breadCrumbActive='' >
    <Row>
        <Col xs={12} className={'d-flex justify-content-end'}>
          <ButtonGroup className='mb-1'>

            {
              (user.status === 'Not Registered' || user.status === 'Invited') && (
                <CanCall action='users.show.invite'>
                  <Button.Ripple color={'primary'} onClick={inviteUser} disabled={loading}>
                    <span className='ml-25'>Invite</span>
                  </Button.Ripple>
                </CanCall>
              )
            }

            {/* {user.user_type_id === 1 ? (
              <CanCall action='INFLUENCER_ADD_FORM'>
                  <Button.Ripple color={'primary'} onClick={() => (user.status === 'Approved' ? setFormModal(true) : toast.error('Artist Should Be Approved')) } disabled={loading}>
                      <span className='ml-25'>New Playlist Form</span>
                  </Button.Ripple>
              </CanCall>
                  ) : ""} */}

            <CanCall action='users.show.reset.password'>
              <Button.Ripple color={'primary'} onClick={resetPassword} disabled={loading}>
              <Edit2 size={14} />
                <span className='ml-25'>Reset Password</span>
              </Button.Ripple>
            </CanCall>

            {/* <CanCall action='users.edit'>
              <Button.Ripple color={'success'} onClick={() => setEditModal(true)} disabled={loading}>
                <Edit size={14}/>
                <span className='ml-25'>Edit</span>
              </Button.Ripple>
            </CanCall> */}

          </ButtonGroup>
        </Col>
      </Row>
        </Breadcrumbs>
      
      <Row>
        <Col xl='4' lg='4' xs={{ order: 1 }} md={{ order: 0, size: 4 }}>
          <UserInfoCard getArtistData={getArtistData} selectedUser={user} usercampaigndone={usercampaigndone} budgetmake={budgetmake} sum={sumData} setEditModal={setEditModal} />
        </Col>
        <Col xl='8' lg='8' xs={{ order: 0 }} md={{ order: 1, size: 8 }}>
          <UserTabs userId={userId} user={user} status={user.status} active={active} toggleTab={toggleTab} sum={sumData} />
        </Col>
      </Row>
      {editModal && <BasicInfoModal successCallback={getArtistData} data={user} onClose={() => setEditModal(false)} />}
      {formModal && <BasicInfoWizardModal successCallback={getArtistData} data={{}} onClick={() => setFormModal(false)} onClose={() => setFormModal(false)} />}
    </div>
  ) : (
    <Alert color='danger'>
      {/*<h4 className='alert-heading'>User not found</h4>*/}
      {/*<div className='alert-body'>*/}
      {/*  User with id: {id} doesn't exist. Check list of all Users: <Link to='/apps/user/list'>Users List</Link>*/}
      {/*</div>*/}
    </Alert>
  )
}
export default UserProfile
