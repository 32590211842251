import React, { Fragment, Component, memo } from 'react'
import { connect } from 'react-redux'
import {FileText, MoreVertical, Send, Plus, Key, User, Trash, Eye} from 'react-feather'
import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Badge
} from 'reactstrap'
import {Link} from "react-router-dom"
import { _confirm, _toast, _url, trans, convertUnicodeToSymbols } from "@utils"
//************************************//
import Breadcrumbs from '@src/components/breadcrumbs'
import DataTable from '@src/components/datatable'

import {AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import Avatar from '@components/avatar'
import {_getAllRolesWithQ} from '@modules/rolespermissions'
import {CanCall} from '@authModule'
//************************************//
import {_deleteGenre, _walletGetAllUserAvailableBalance} from '../../redux/actions'
import formatAmountComponent from '../../../../components/AmountFormatComponent'
import WalletUserBalance from '../../components/WalletUserBalance'
import { ShimmerPostItem, ShimmerThumbnail } from "react-shimmer-effects"
import { CoinIcon, CurrencySymbol, capitalizeFirstLetter } from '../../../../components/CurrencyComponent'

import TransactionWalletViewModal from '../../components/TransactionWalletViewModal'

//************************************//
// const tableColumns = (state, hasAction) => [
//   {
//     name: 'ID',
//     selector: 'id',
//     sortable: false,
//     minWidth: '50px',
//     omit: true,
//     filter: {
//       enabled: false
//     }
//   },
//   {
//     name: 'Invoice #',
//     selector: 'num',
//     sortable: false,
//     minWidth: '150px',
//     filter: {
//       enabled: true,
//       type:'text'
//     },
//     cell: (row) => {
//       return (
//           <span className={'text-primary'}>#{row.num}</span>
//       )
//     }
//   },
//   {
//     name: 'Campaign name',
//     selector: 'num',
//     sortable: false,
//     minWidth: '250px',
//     cell: (row) => {
//       return (
//           <span>{row.campaign === null ? row.express_campaign?.name : row.campaign?.name}</span>
//       )
//     }
//   },
//   {
//     name: 'Invoice Date',
//     selector: 'created_at',
//     sortable: false,
//     cell: (row) => {
//       return (
//           <span>{new Date(row.created_at).toLocaleDateString()}</span>
//       )
//     }
//   },
//   {
//     name: 'Budget',
//     selector: 'budget',
//     sortable: false,
//     cell: (row) => {
//       return (
//           <span>${row.amount}</span>
//       )
//     }
//   },
//   {
//     name: 'Paid',
//     selector: 'is_paid',
//     sortable: false,
//     cell: (row) => {
//       return (
//         <Badge id='plan-expiry-date' color={
//           (() => {
//             switch (row.is_paid) {
//               case 1:
//                 return 'success'
//               case 0:
//                 return 'warning'
//               case 2:
//                 return 'danger'
//             }
//           })()
//         }>
//         {(() => {
//         switch (row.is_paid) {
//           case 0:
//             return 'Pending'
//           case 1:
//             return 'Paid'
         
//           case 2:
//             return 'Refunded'
//         }
//       })()}
//       </Badge>
//           // <Badge color={row.is_paid ? 'success' : 'warning'}>{row.is_paid ? 'Paid' : 'Pending'}</Badge>
//       )
//     }
//   }
// ]
const tableColumns = (state, _editTransactionModal, _deleteUser, hasAction, history) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: false,
    minWidth: '225px',
    omit: true,
    filter: {
      enabled: false
    }
  },
  {
    name: 'Date',
    selector: 'transaction_date',
    sortable: false,
    cell: (row) => {
      return (
          <span>{new Date(row.transaction_date).toLocaleDateString()}</span>
      )
    }
  },
  // {
  //   name: 'Type',
  //   selector: 'transaction_type',
  //   sortable: false,
  //   cell: (row) => {
  //     return (
  //         <Badge tag='div' color={row.transaction_type === 'credit' ? 'primary' : 'danger'} pill >{capitalizeFirstLetter(row.transaction_type)}</Badge>
  //     )
  //   }
  // },
  // {
  //   name: 'User',
  //   selector: 'wallet.user.name',
  //   sortable: false,
  //   cell: (row) => {
  //     return (
  //         <span tag='div'  >{row.wallet.user.name}</span>
  //     )
  //   }
  // },
  {
    name: 'Transaction #',
    selector: 'transactions_id',
    minWidth: '170px',
    sortable: false,
    filter: {
      enabled: true,
      type:'text'
    },
    cell: (row) => {
      return (
          <span className={'text-primary'}>{row.transactions_id}</span>
      )
    }
  },
  {
    name: 'Type',
    selector: 'desc.desc',
    sortable: false,
    minWidth: '200px',
    cell: (row) => {
      return (
        <h5 tag='h5' style={{ fontSize: '12px' }} className="inter-bold-font">{convertUnicodeToSymbols(row.desc?.desc ?? '')}</h5>
      )
    }
  },
  // {
  //   name: 'Description',
  //   selector: 'description',
  //   minWidth: '300px',
  //   sortable: false,
  //   cell: (row) => {
  //     return (
  //         <span tag='div' >{convertUnicodeToSymbols(row.description)}</span>
  //     )
  //   }
  // },
  {
    name: 'Total',
    selector: 'amount',
    minWidth: '100px',
    sortable: false,
    cell: (row) => {
      return (
        <>
        { row.transaction_type === 'credit' ?
         <span className={'text-success inter-bold-font'}>+{formatAmountComponent(row.amount)}<CurrencySymbol /></span> :  <span  className={'text-danger inter-bold-font'}>-{formatAmountComponent(row.amount)}<CurrencySymbol/></span> }

     </>
      )
    }
  },
  {
    name: 'Coins',
     selector: 'coins',
    sortable: false,
    cell: (row) => {
        return (
          <>
          {row.transaction_type === 'credit' ?
            <span className={'text-success inter-bold-font'} >+{formatAmountComponent(row.coins)} <CoinIcon width={15} /></span> : <span className={'text-danger inter-bold-font'} >-{formatAmountComponent(row.coins)} <CoinIcon width={15} /></span>}

        </>
        )
    }
  },
  {
    name: trans('gen.actions.actions'),
    allowOverflow: true,
    // omit: !hasAction,
    // omit: true,
    cell: (row, index, column, id) => {
      return (
        // row.transaction_type === 'credit' && row.invoice ? (
            <div className='d-flex'>
                <UncontrolledDropdown>
                    <DropdownToggle className='pr-1' tag='span' style={{ cursor: 'pointer' }}>
                        <MoreVertical size={15} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {/*<CanCall action='NoPermissionCode' id={`updateUser_${row.id}`}>*/}
                        {/* <Link to={`/invoices/${row.id}`} className='w-100 dropdown-item'> */}
                        <a className='w-100 dropdown-item' onClick={(e) => _editTransactionModal(row)}>
                        <Eye size={15}/>
                        <span className='align-middle ml-50'>View</span>
                        </a>
                           
                        {/* </Link> */}
                        {/*</CanCall>*/}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        // ) : '-'
    )
    }
  } 
  // {
  //   name: trans('gen.actions.actions'),
  //   allowOverflow: true,

  //   cell: (row, index, column, id) => {
  //     return (
  //       <div className='d-flex'>
  //         <UncontrolledDropdown>
  //           <DropdownToggle className='pr-1' tag='span' style={{ cursor: 'pointer' }}>
  //             <MoreVertical size={15} />
  //           </DropdownToggle>
  //           <DropdownMenu right>
  //             {/* <CanCall action='invoices.show' id={`updateUser_${row.id}`}> */}
  //             {row.payout !== null ?  <div onClick={() => this.props.history.push(`/transfers/${row.payout?.transfer?.wise_quote_id}`)} className='w-100 dropdown-item'>
  //                 <Eye size={15}/>
  //                 <span className='align-middle ml-50'>View </span>
  //               </div> : 
  //               row.transaction_type !== 'debit' ? <div onClick={() => history.push(`/invoices/${row.id}`)} className='w-100 dropdown-item'>
  //                 <Eye size={15}/>
  //                 <span className='align-middle ml-50'>View</span>
  //               </div> : <div  className='w-100 dropdown-item'>
  //                 <Eye size={15}/>
  //                 <span className='align-middle ml-50'>View</span>
  //               </div>}
  //             {/* </CanCall> */}
             
  //           </DropdownMenu>
  //         </UncontrolledDropdown>
  //       </div>
  //     )
  //   }
  // }
]
const tableActions = ['NoPermissionCode']
//************************************//
class ArtistLastFiveInvoicesList extends Component {
  static contextType = AbilityContext
  constructor(props) {
    super(props)
    this.state = {
      userId: props.userId,
      userBalance: {},
      loadPayoutSum: true,
      basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}},
      basicTransactionViewModal: { basicTransactionViewModalShow: false, basicTransactionViewModalData: {} }

    }
    this._editBasicInfoModal = this._editBasicInfoModal.bind(this)
    this._deleteUser = this._deleteUser.bind(this)
  }
  //************************************//
   _closeBasicInfoModal = () => {
    this.setState({basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}})
  }
  //************************************//
  _openBasicInfoModal = () => {
    this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: {}}})
  }
  //************************************//
  _editBasicInfoModal = (data) => {
    this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: data}})
  }

     /////////////////////////////

     _closeTransactionModal = () => {
      this.setState({ basicTransactionViewModal: { basicTransactionViewModalShow: false, basicTransactionViewModalData: {} } })
    }
    //************************************//
    _openTransactionModal = () => {
      this.setState({ basicTransactionViewModal: { basicTransactionViewModalShow: true, basicTransactionViewModalData: {} } })
    }
    //************************************//
    _editTransactionModal = (data) => {
      console.log(data)
      this.setState({ basicTransactionViewModal: { basicTransactionViewModalShow: true, basicTransactionViewModalData: data } })
    }
  //************************************//
  _deleteUser = (id) => {
    _confirm({
      callback: (c) => {
        _deleteGenre(id, () => {
          this.dataTableRef._refresh()
          c()
        })
      }
    })
  }
  refreshData = () => {
    _walletGetAllUserAvailableBalance(this.props.artist?.id,
      (data) => {
        this.setState({ loadPayoutSum: false })
        console.log(data.balance)
               this.setState({
                userBalance:data.balance
        })
      }
    )
  }
  componentDidMount() {
    this.refreshData()
    //this.getSumPayout()
    
  }
  //************************************//
  render () {
    const hasAction = _hasAnyAbility(this.context, tableActions)
    const { basicTransactionViewModalShow, basicTransactionViewModalData } = this.state.basicTransactionViewModal

    return (
      <Fragment>
        <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'Wallet'} breadCrumbParent='' breadCrumbActive='' ></Breadcrumbs>
        <Row>
        <Col xs={12} md={12} className='mb-1'>
     {this.state.loadPayoutSum ? <ShimmerThumbnail  height={200} rounded className='dark-shimmer-thumbnail' /> :  <WalletUserBalance enabled='disabled' userBalance={this.state.userBalance} />}
                    {/* <StatsVertical1
                              icon={<Icon width={30} icon='flat-color-icons:money-transfer' />}
                              color='secondary'
                              stats={`${this.props.sum ?? 0}`}
                              statTitle='Available Balance'
                            /> */}
                    </Col> 
          <Col sm='12'>
            <DataTable
                ref={(ref) => { this.dataTableRef = ref }}
                uri={`wallet/admin/transaction/user/${this.props.artist?.id}`}
              // uri={`artist/invoices/indexAllListByAdminForArtist/${this.props.artist?.id}`}
                columns={tableColumns(this.state, this._editTransactionModal, hasAction)}
                hasIndexing={false}
                hasFilter={false}
                defaultCollapse={true}
            />
          </Col>
        </Row>
        {basicTransactionViewModalShow && <TransactionWalletViewModal  successCallback={''} data={basicTransactionViewModalData} onClose={this._closeTransactionModal} />}

      </Fragment>
    )
  }
}
//************************************//
const mapStateToProps = store => ({
  loading: store.app.loading,
  userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`)
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(ArtistLastFiveInvoicesList)
