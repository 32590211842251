import React from "react"
import { Shield, Settings, Music, CheckCircle, Codesandbox } from "react-feather"

import { navTrans } from '@utils'

export const Navigation = []
// 	{
// 		header: 'Admins'
// 	},
//     {
//         id: 'roles',
//         title: 'Admins & Roles',
//         icon: <Lock size={20} />,
//         children: [
//             {
//                 id: 'adminList',
//                 title: 'Admins',
//                 icon: <Lock/>,
//                 navLink: '/admin/list',
//                 action: 'call',
//                 resource: 'ADMINS_VIEW_LIST'
//             },
//             {
//                 id: 'Roles&Permissions',
//                 title: 'Roles & Permissions',
//                 icon: <Shield />,
//                 navLink: '/roles/list',
//                 action: 'call',
//                 resource: 'ROLES_VIEW_LIST'
//             },
//             {
//                 id: 'log',
//                 title: 'Audit Logs',
//                 icon: <Tool />,
//                 navLink: '/logs',
//                 action: 'call',
//                 resource: 'LOG_LIST'
//             }
//             // {
//             //     id: '3',
//             //     title: 'User Types',
//             //     icon: <User />,
//             //     navLink: '/userTypes/list'
//             // },
         
//         ]
//       }
// ]
