import React, { Fragment, Component, memo } from 'react'
import { connect } from 'react-redux'
import {FileText, MoreVertical, Send, Plus, Key, User, Trash, Eye} from 'react-feather'
import {
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Badge
} from 'reactstrap'
import {Link} from "react-router-dom"
//************************************//
import Breadcrumbs from '@src/components/breadcrumbs'
import DataTable from '@src/components/datatable'
import { Icon } from '@iconify/react'

import {trans, _confirm} from '@utils'
import {AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import Avatar from '@components/avatar'
import {_getAllRolesWithQ} from '@modules/rolespermissions'
import {CanCall} from '@authModule'
//************************************//
import {_deleteGenre} from '../../redux/actions'
import {statusesColors} from "../../../../utility/Constants"
import _ from "lodash"
import { convertUnicodeToSymbols } from '../../../core/utility/Utils'
//************************************//
// const tableColumns = (state, hasAction) => [
//     {
//         name: 'ID',
//         selector: 'id',
//         sortable: false,
//         minWidth: '225px',
//         omit: true,
//         filter: {
//             enabled: false
//         }
//     },
//     {
//         name: 'Type',
//         selector: 'name',
//         cell: (row) => {
//             return (
//                 row.campaign_type_id === 1 ? <img width={22} src={require('@fwsrc/assets/images/spotify.png').default} /> : <img width={22} src={require('@fwsrc/assets/images/tiktok.png').default} />
//             )
//         }
//     },
//     {
//         name: trans('user.name'),
//         selector: 'name',
//         sortable: true,
//         minWidth: '350px',
//         cell: (row, index, column, id) => {
//             return (

//                     <div className='user-info text-truncate'>
//                         <span className='d-block font-weight-bold text-truncate'>{row.name}</span>
//                         {/*<span className='d-block font-weight-bold text-muted'>{row.email}</span>*/}
//                     </div>
//             )
//         }
//     },
//     {
//         name: trans('user.name'),
//         selector: 'name',
//         sortable: true,
//         minWidth: '225px',
//         omit:true,
//         filter: {
//             enabled: true,
//             type: 'text'
//         }
//     },
//     // {
//     //     name: 'Artist Name',
//     //     selector: 'artist_name',
//     //     sortable: false,
//     //     filter: {
//     //         enabled: true,
//     //         type: 'text'
//     //     },
//     //     cell: (row) => {
//     //         return (
//     //             <span>{row.artist.name}</span>
//     //         )
//     //     }
//     // },
//     {
//         name: 'Tier',
//         selector: 'tier',
//         sortable: false,
//         minWidth: '200px',
//         cell: (row) => {
//             return <Badge color={'info'}>{row?.tier?.name}</Badge>
//         }
//     },
//     // {
//     //     name: 'Genre',
//     //     selector: 'genre',
//     //     sortable: false,
//     //     filter: {
//     //         enabled: true,
//     //         type: 'text'
//     //     },
//     //     cell: (row) => {
//     //         // return (
//     //         //     <span>{row.genres[0]?.name}</span>
//     //         // )
//     //         return _.map(row.genres, (v, k) => {
//     //             return <Badge color='success'> {v.name} </Badge>
//     //         })
//     //     }
//     // },
//     {
//         name: 'Status',
//         selector: 'status',
//         sortable: false,
//         minWidth: '200px',
//         cell: (row) => {
//             return <Badge color={statusesColors[row.status].color}>{row.status}</Badge>
//         }
//     },
//     {
//         name: 'Invoice Status',
//         selector: 'status',
//         minWidth: '200px',
//         sortable: false,
//         omit: state.user.user_type_id > 1,
//         cell: (row) => {
//             return <Badge color={row.invoice ? row.invoice.is_paid ? 'success' : 'warning' : 'warning'}>{row.invoice ? row.invoice.is_paid ? 'Paid' : 'Pending' : 'Waiting Admin Approval'}</Badge>
//         }
//     }
// ]

const tableColumns = (state, hasAction) => [
    // {
        // name: 'ID',
        // selector: 'id',
        // sortable: false,
        // minWidth: '225px',
        // omit: true,
        // filter: {
        //     enabled: false
        // }
    // },
    {
        name: 'Platform',
        selector: 'name',
        maxWidth: '13.5px',
        cell: (row) => {
            return (
                row.campaign_type_id === 1 ? <Icon icon="iconoir:spotify" width={35} height={35} color='white' /> : row.campaign_type_id === 3 ? <Icon icon="bi:instagram" color='white' width={30} /> : <img src={require('@fwsrc/assets/images/Tiktok.svg').default} width="30" height="30" /> 
            )
        }
    },
    {
        name: trans('Campaign name'),
        selector: 'name',
        sortable: false,
        minWidth: '200px',
        // minWidth: '300px',
        cell: (row, index, column, id) => {
            return (

                    <div className='user-info text-truncate'>
                        <span className='d-block font-weight-bold text-truncate'>
                            {convertUnicodeToSymbols(row.campaign_name)}
                            {

                                (row.form === null && row.invoice?.is_paid && !row.is_released) ? 
                                (<AlertCircle color='orange' size={18} style={{marginBottom: 2, marginLeft:2}}/>) : null
                            }
                        </span>
                        {/*<span className='d-block font-weight-bold text-muted'>{row.email}</span>*/}
                    </div>
            )
        }
    },
    
    // {
    //     name: trans('user.name'),
    //     selector: 'name',
    //     sortable: true,
    //     // minWidth: '225px',
    //     omit:true,
    //     filter: {
    //         enabled: true,
    //         type: 'text'
    //     }
    // },
    // {
    //     name: 'Artist Name',
    //     selector: 'artist_name',
    //     sortable: false,
    //     filter: {
    //         enabled: true,
    //         type: 'text'
    //     },
    //     cell: (row) => {
    //         return (
    //             <span>{row.artist.name}</span>
    //         )
    //     }
    // },
    // {
    //     name: 'Tier',
    //     selector: 'tier',
    //     sortable: false,
    //     minWidth: '200px',
    //     cell: (row) => {
    //         return <Badge color={'info'}>{row?.tier?.name}</Badge>
    //     }
    // },
    // {
    //     name: 'Genre',
    //     selector: 'genre',
    //     sortable: false,
    //     filter: {
    //         enabled: true,
    //         type: 'text'
    //     },
    //     cell: (row) => {
    //         // return (
    //         //     <span>{row.genres[0]?.name}</span>
    //         // )
    //         return _.map(row.genres, (v, k) => {
    //             return <Badge color='success'> {v.name} </Badge>
    //         })
    //     }
    // },
    {
        name: 'Type',
        selector: 'type',
        sortable: false,
        minWidth: '150px',
        maxWidth: '20px',
        cell: (row) => {
            return <Badge color={row.type === 'Custom' ? 'success' : 'primary'}>{row.type}</Badge>
        }
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: false,
        minWidth: '150px',
        maxWidth: '20px',
        cell: (row) => {
            return <>
                {row.type === 'Custom' ? <Badge color={statusesColors[row.status].color}>{row.status}</Badge>  :  <Badge
                                    className={'mx-25'}
                                    color={
                                      (() => {
                                        switch (row.status?.id) {
                                          case 1:
                                            return 'success'
                                          case 2:
                                            return 'warning'
                                          case 3:
                                            return 'danger'
                                          case 5:
                                            return 'danger'
                                            case 6:
                                            return 'primary'
                                          default:
                                            return 'secondary'
                                        }
                                      })()
                                    }
                                  >
                                    {row.status?.status}
                                  </Badge>}
            </>
        }
    }
        // {
        //     name: 'Invoice Status',
        //     selector: 'status',
        //     minWidth: '200px',
        //     sortable: false,
        //     omit: state.user.user_type_id > 1,
        //     cell: (row) => {
        //         return <Badge color={row.invoice ? row.invoice.is_paid ? 'success' : 'warning' : 'warning'}>{row.invoice ? row.invoice.is_paid ? 'Paid' : 'Pending' : 'Waiting Admin Approval'}</Badge>
        //     }
        // }
    
    // {
    //     name: 'Invoice Status',
    //     selector: 'status',
    //     minWidth: '200px',
    //     sortable: false,
    //     omit: state.user.user_type_id > 1,
    //     cell: (row) => {
    //         return <Badge color={row.invoice ? row.invoice?.is_paid ? 'success' : 'warning' : 'warning'}>{row.invoice ? row.invoice?.is_paid ? 'Paid' : 'Pending' : 'Waiting Admin Approval'}</Badge>
    //     }
    // }
   
] 
const tableActions = ['NoPermissionCode']
//************************************//
class ArtistLastFIveCampaignsList extends Component {
    static contextType = AbilityContext
    constructor(props) {
        super(props)
        this.state = {
            userId: props.userId,
            user: props.user,
            basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}
        }
        this._editBasicInfoModal = this._editBasicInfoModal.bind(this)
        this._deleteUser = this._deleteUser.bind(this)
    }
    //************************************//
    _closeBasicInfoModal = () => {
        this.setState({basicInfoModal: {basicInfoModalShow: false, basicInfoModalData: {}}})
    }
    //************************************//
    _openBasicInfoModal = () => {
        this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: {}}})
    }
    //************************************//
    _editBasicInfoModal = (data) => {
        this.setState({basicInfoModal: {basicInfoModalShow: true, basicInfoModalData: data}})
    }
    //************************************//
    _deleteUser = (id) => {
        _confirm({
            callback: (c) => {
                // _deleteGenre(id, () => {
                //   this.dataTableRef._refresh()
                //   c()
                // })
            }
        })
    }
    //************************************//
    render () {
        const hasAction = _hasAnyAbility(this.context, tableActions)
        return (
            <Fragment>
                <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'Recent Campaigns'} breadCrumbParent='' breadCrumbActive='' >
                </Breadcrumbs>
                <Row>
                    <Col sm='12'>
                        <DataTable
                            ref={(ref) => { this.dataTableRef = ref }}
                            uri={`artist/campaigns/indexLastFiveForArtist/${this.props.artist?.id}`}
                            columns={tableColumns(this.state, hasAction)}
                            hasIndexing={false}
                            hasFilter={false}
                            defaultCollapse={true}
                            showAll={true}
                        />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
//************************************//
const mapStateToProps = store => ({
    loading: store.app.loading,
    userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`),
    user: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData`)
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(ArtistLastFIveCampaignsList)
