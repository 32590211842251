import { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { FileText, MoreVertical, Edit, FilePlus, Trash, Eye } from 'react-feather'
import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Badge
} from 'reactstrap'
import { Link } from "react-router-dom"
//************************************//
import Breadcrumbs from '@src/components/breadcrumbs'
import DataTable from '@src/components/datatable'
import { trans, _confirm } from '@utils'
import { AbilityContext, _hasAnyAbility } from '@src/utility/context/Can'
import { CanCall } from '@authModule'
//************************************//
import { _deleteForm } from '@fwsrc/modules/form/redux/actions'
import BasicInfoModal from '@fwsrc/modules/form/views/forms/BasicInfoModal'
import { statusesColors } from "../../../../utility/Constants"
import BasicInfoWizardModal from "@fwsrc/modules/form/views/forms/BasicInfoWizardModal"
import _ from "lodash"
import { toast } from "react-toastify"

//************************************//
const tableColumns = (state, _editBasicInfoWizardModal, _editBasicInfoModal, deleteUser, hasAction) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: false,
    minWidth: '225px',
    omit: true,
    filter: {
      enabled: false
    }
  },
  // {
  //     name: trans('user.name'),
  //     selector: 'name',
  //     sortable: true,
  //     minWidth: '350px',
  //     cell: (row, index, column, id) => {
  //         return (
  //             <div className='d-flex align-items-center'>
  //             <Avatar content={row.user_id?.name} initials />

  //         <div className='user-info text-truncate ml-1'>
  //           <span className='d-block font-weight-bold text-truncate'>{row.user_id?.name}</span>
  //           <span className='d-block font-weight-bold text-muted'>{row.user_id?.email}</span>
  //         </div>
  //       </div>
  //         )
  //     }
  // },
  {
    name: 'Form Type',
    selector: 'form_type',
    sortable: false,
    filter: {
      enabled: true,
      type: 'text'
    },
    cell: (row) => {
      return (
        <span>{row.form_type?.name}</span>
      )
    }
  },
  {
    name: 'Created By',
    selector: 'created_by',
    sortable: false,
    minWidth: '200px',
    cell: (row) => {
      return <Badge color={'secondary'}>{row.created_by ? row.created_by?.name : row.created_by_admin?.name}</Badge>
    }
  },
  {
    name: 'Status',
    selector: 'status',
    minWidth: '200px',
    sortable: false,
    omit: state.user.user_type_id > 1,
    cell: (row) => {
      return <Badge color={row.status_id.id === 1 ? 'warning' : 'success'}>{row.status_id.name}</Badge>
    }
  },
  {
    name: trans('gen.actions.actions'),
    allowOverflow: true,
    // omit: !hasAction,
    cell: (row, index, column, id) => {
      return (
        <div className='d-flex'>
          <UncontrolledDropdown>
            <DropdownToggle className='pr-1' tag='span'>
              <MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu right>
              <>
                <CanCall action='VIEW_FORM' id={`updateForm_${row.id}`}>
                  <Link to={`/forms/${row.id}`} className='w-100 dropdown-item'>
                    <Eye size={15} />
                    <span className='align-middle ml-50'>View</span>
                  </Link>
                </CanCall>

                <CanCall action='EDIT_FORM' id={`updateForm_${row.id}`}>
                  <DropdownItem className='w-100' onClick={e => _editBasicInfoWizardModal(row)}>
                    <FileText size={15} />
                    <span className='align-middle ml-50'>{trans('gen.actions.edit')}</span>
                  </DropdownItem>
                </CanCall>
                <CanCall action='CHANGE_STATUS' id={`updateForm_${row.id}`}>
                  <DropdownItem className='w-100' onClick={e => _editBasicInfoModal(row)}>
                    <Edit size={15} />
                    <span className='align-middle ml-50'>Change Status</span>
                  </DropdownItem>
                </CanCall>
                <CanCall action='DELETE_FORM' id={`deleteForm_${row.id}`}>
                  <DropdownItem className='w-100 btn-flat-danger' onClick={e => deleteUser(row.id)}
                    disabled={statusesColors[row.status_id] > 1}
                  >
                    <Trash size={15} />
                    <span className='align-middle ml-50'>{trans('gen.actions.delete')}</span>
                  </DropdownItem>
                </CanCall>
              </>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )
    }
  }
]
const tableActions = ['EDIT_FORM', 'CHANGE_STATUS', 'DELETE_FORM']
//************************************//
class FormList extends Component {
  static contextType = AbilityContext
  constructor(props) {
    super(props)
    this.state = {
      userId: props.userId,
      user: props.user,
      status: props.status,
      basicInfoWizardModal: { basicInfoWizardModalShow: false, basicInfoWizardModalData: {} },
      basicInfoModal: { basicInfoModalShow: false, basicInfoModalData: {} },
      isArtist: this.props.isArtist
    }
    //console.log(this.props.match.params.userid)
    // this._editBasicInfoWizardModal = this._editBasicInfoWizardModal.bind(this)
    // this.deleteUser = this.deleteUser.bind(this)
  }
  //************************************//
  _closeBasicInfoWizardModal = () => {
    this.setState({ basicInfoWizardModal: { basicInfoWizardModalShow: false, basicInfoWizardModalData: {} } })
  }
  //************************************//
  _openBasicInfoWizardModal = () => {
    this.setState({ basicInfoWizardModal: { basicInfoWizardModalShow: true, basicInfoWizardModalData: {} } })
  }
  //************************************//
  _editBasicInfoWizardModal = (data) => {
    this.setState({ basicInfoWizardModal: { basicInfoWizardModalShow: true, basicInfoWizardModalData: data } })
  }

  _closeBasicInfoModal = () => {
    this.setState({ basicInfoModal: { basicInfoModalShow: false, basicInfoModalData: {} } })
  }
  //************************************//
  _openBasicInfoModal = () => {
    this.setState({ basicInfoModal: { basicInfoModalShow: true, basicInfoModalData: {} } })
  }
  //************************************//
  _editBasicInfoModal = (data) => {
    this.setState({ basicInfoModal: { basicInfoModalShow: true, basicInfoModalData: data } })
  }
  //************************************//
  deleteUser = (id) => {
    _confirm({
      callback: (c) => {
        _deleteForm(id, () => {
          this.dataTableRef._refresh()
          c()
        })
      }
    })
  }
  //************************************//
  render() {
    const { basicInfoWizardModalShow, basicInfoWizardModalData } = this.state.basicInfoWizardModal
    const { basicInfoModalShow, basicInfoModalData } = this.state.basicInfoModal
    const hasAction = _hasAnyAbility(this.context, tableActions)
    return (
      <Fragment>
        <Breadcrumbs breadCrumbMainTitle={''} breadCrumbTitle={'Forms List'} breadCrumbParent='' breadCrumbActive='' >
          {this.props.artist?.user_type_id === 1 ? (

            <CanCall action='INFLUENCER_ADD_FORM'>
              {this.props.artist?.status === 'Approved' ? (
                <Button.Ripple color={'primary'} onClick={this._openBasicInfoWizardModal}>
                  <FilePlus size={16} />
                  <span className='ml-25'>New Playlist Form</span>
                </Button.Ripple>
              ) : (
                <Button.Ripple color={'primary'} onClick={e => {
                  e.preventDefault()
                  toast.error('Artist Should Be Approved') 
                  }}>
                  <FilePlus size={16} />
                  <span className='ml-25'>New Playlist Form</span>
                </Button.Ripple>
              )
              }
            </CanCall>
          ) : ""}
        </Breadcrumbs>
        <Row>
          <Col sm='12'>
            <DataTable ref={(ref) => { this.dataTableRef = ref }} uri={`${process.env.REACT_APP_BACKEND_BASE_URL}indexAllForms/${this.props.artist?.id}`} columns={tableColumns(this.state, this._editBasicInfoWizardModal, this._editBasicInfoModal, this.deleteUser, hasAction)} hasIndexing={true} hasFilter={true} defaultCollapse={true} />
          </Col>
        </Row>
        {basicInfoWizardModalShow && <BasicInfoWizardModal successCallback={this.dataTableRef._refresh} data={basicInfoWizardModalData} onClose={this._closeBasicInfoWizardModal} />}
        {basicInfoModalShow && <BasicInfoModal successCallback={this.dataTableRef._refresh} data={basicInfoModalData} onClose={this._closeBasicInfoModal} />}
      </Fragment>
    )
  }
}
//************************************//
const mapStateToProps = store => ({
  loading: store.app.loading,
  userId: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData.id`),
  user: _.get(store, `${process.env.REACT_APP_AUTH_MODULE}.userData`)
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(FormList)