import { Fragment, useState } from 'react'
import { ButtonSpinner } from '@src/components'

import { useForm } from "react-hook-form"
import { Button, CustomInput, Form } from "reactstrap"
import { ArrowLeft } from "react-feather"
import _ from "lodash"
import { _addForm, _editForm } from "../../redux/actions"
import { getUserData } from "../../utility/Utils"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import axios from "axios"

const About = ({ trackState, imageState, trackUrlState, imageUrlState, stepper, refState, valErrors, formState, successCallback, _close, isEditAction }) => {
    const loading = useSelector(state => state.app.loading)
    const { errors, handleSubmit, control, trigger, getValues, setValue, register, watch } = useForm()
    const { formData, setFormData } = formState
    const { reference, setReference } = refState
    const [submit, setSubmit] = useState(false)
    const filledBy = getUserData().id

    const [track, setTrack] = trackState
    const [trackUrl, setTrackUrl] = trackUrlState
    const [image, setImage] = imageState
    const [imageUrl, setImageUrl] = imageUrlState
    const [Uploading, setUploading] = useState(false)

    console.log(filledBy)
    console.log(useParams())
    let userId

    if (process.env.REACT_APP_AUTH_MODULE === 'user') {
        userId = formData.user_id.id ?? useParams().userId
    } else {
        userId = filledBy
    }
    const handleChange = () => {
        setSubmit(!submit)
    }

    const onSubmit = (data, track, image) => {
        trigger()
        setUploading(false)
        setSubmit(true)
        if (_.isEmpty(errors)) {
            setFormData(prevState => {
                console.log(reference)
                if (!formData.id) {
                    if (process.env.REACT_APP_AUTH_MODULE === 'user') {
                        _addForm(
                            {
                                ...prevState,
                                ...data,
                                created_by_admin: filledBy,
                                updated_by_admin: filledBy,
                                user_id: userId,
                                status_id: 1,
                                form_type: 1,
                                track_file: track,
                                cover_image: image,
                                reference
                            },
                            () => {
                                _close()
                                successCallback()
                            },
                            (err) => {
                            }
                        )
                    } else {
                        _addForm(
                            {
                                ...prevState,
                                ...data,
                                created_by: filledBy,
                                updated_by: filledBy,
                                user_id: userId,
                                status_id: 1,
                                form_type: 1,
                                track_file: track,
                                cover_image: image,
                                reference
                            },
                            () => {
                                _close()
                                successCallback()
                            },
                            (err) => {
                            }
                        )
                    }

                } else {
                    if (process.env.REACT_APP_AUTH_MODULE === 'user') {
                        _editForm(
                            {
                                ...prevState,
                                ...data,
                                user_id: userId,
                                status_id: 1,
                                form_type: 1,
                                //created_by_admin: formData.created_by_admin,
                                updated_by_admin: filledBy,
                                track_file: track,
                                cover_image: image,
                                reference
                            },
                            () => {
                                _close()
                                successCallback()
                            },
                            (err) => {
                            }
                        )
                    } else {
                        _editForm(
                            {
                                ...prevState,
                                ...data,
                                user_id: userId,
                                status_id: 1,
                                form_type: 1,
                                //created_by: formData.created_by,
                                updated_by: filledBy,
                                track_file: track,
                                cover_image: image,
                                reference
                            },
                            () => {
                                _close()
                                successCallback()
                            },
                            (err) => {
                            }
                        )
                    }
                }
                return {
                    ...prevState,
                    ...data,
                    user_id: userId,
                    status_id: 1,
                    // created_by: filledBy,
                    // updated_by: filledBy,
                    track_file: trackUrl,
                    cover_image: imageUrl,
                    form_type: 1
                }
            })
        }
    }

    const uploadData = async (data) => {
        const url = `${process.env.REACT_APP_BACKEND_BASE_URL}file/upload`
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        setSubmit(false)
        if (isEditAction) {
            if (image && track) {
                setUploading(true)

                const trackData = new FormData()
                trackData.append('file', track)
                trackData.append('fileName', track?.name)

                const imageData = new FormData()
                imageData.append('file', image)
                imageData.append('fileName', image?.name)

                axios.post(url, trackData, config).then((response1) => {
                    setTrackUrl(response1.data.data.file)
                    const track = response1.data.data.file
                    axios.post(url, imageData, config).then((response2) => {
                        setImageUrl(response2.data.data.file)
                        const image = response2.data.data.file
                        onSubmit(data, track, image)
                    })
                })
            } else if (track) {
                setUploading(true)

                const trackData = new FormData()
                trackData.append('file', track)
                trackData.append('fileName', track?.name)

                axios.post(url, trackData, config).then((response1) => {
                    setTrackUrl(response1.data.data.file)
                    const track = response1.data.data.file
                    onSubmit(data, track, imageUrl)
                })
            } else if (image) {
                setUploading(true)

                const imageData = new FormData()
                imageData.append('file', image)
                imageData.append('fileName', image?.name)

                axios.post(url, imageData, config).then((response2) => {
                    setImageUrl(response2.data.data.file)
                    const image = response2.data.data.file
                    onSubmit(data, trackUrl, image)
                })
            } else {
                onSubmit(data, trackUrl, imageUrl)
            }
        } else {
            setUploading(true)
            const trackData = new FormData()
            trackData.append('file', track)
            trackData.append('fileName', track?.name)

            const imageData = new FormData()
            imageData.append('file', image)
            imageData.append('fileName', image?.name)

            axios.post(url, trackData, config).then((response1) => {
                setTrackUrl(response1.data.data.file)
                const track = response1.data.data.file
                axios.post(url, imageData, config).then((response2) => {
                    setImageUrl(response2.data.data.file)
                    const image = response2.data.data.file
                    onSubmit(data, track, image)
                })
            })
        }

    }

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(uploadData)}>
                <h4>Log in to the <a href="https://artists.spotify.com/c/team/access/artist">Spotify for Artists web page.</a></h4>
                <br />
                <h5>Access to your Spotify for Artists would be great so we can pitch for you and also track metrics throughout the course of the campaign. We've put some instructions to add us below:</h5>
                <br />
                <ol>
                    <li>Log in to the <a href="https://artists.spotify.com/c/team/access/artist">Spotify for Artists web page.</a>.</li>
                    <li>Open the side menu by clicking the three dots in the top-left. / add team</li>
                    <li>Click <strong>YOUR TEAMS</strong></li>
                    <li>Click <strong>ADD TEAM</strong> in the top-right.</li>
                    <ul>
                        <li>To invite a team member, add their name (Liberty Music PR) , business email address (<a>curators@libertymusicpr.com</a>), role (PR), company (Liberty Music PR), and choose their access level (‘Editor’ is ideal but ‘Viewer’ is okay too).</li>
                    </ul>
                </ol>
                <br />
                <CustomInput inline type='checkbox' id='exampleCustomCheckbox2' label='I agree' rules={{ required: true }} onChange={handleChange} />
                <div className='d-flex justify-content-between mt-2'>
                    <Button.Ripple color='primary' onClick={() => stepper.previous()}>
                        <ArrowLeft size={14} className='rotate-rtl align-middle me-sm-50 me-0' />
                        <div className='align-middle d-sm-inline-block d-none'>Previous</div>
                    </Button.Ripple>
                    <Button.Ripple color='primary' type='submit' disabled={!submit}>
                        {Uploading ? <ButtonSpinner /> : null}
                        <div className='align-middle d-sm-inline-block d-none btn-submit'>{Uploading ? "Uploading Data" : "Submit"}</div>
                    </Button.Ripple>
                </div>
            </Form>
        </Fragment>
    )
}

export default About